import React from 'react';
import {Cropper, ImageRestriction, RectangleStencil, CropperRef} from "react-advanced-cropper";
import FileUploader from "devextreme-react/file-uploader";

export interface Image {
    type?: string;
    src: string;
}

interface CropperDiazarProps {
    cropperRef:  React.MutableRefObject<CropperRef | null>;
    withPreview: boolean;
}

export const CropperDiazar = (props: CropperDiazarProps) => {
    const [imageBlob, setImageBlob] = React.useState<Image | null>(null)

    const onLoadImage = (files) => {
        // Ensure that you have a file before attempting to read it
        if (files && files[0]) {
            // Create the blob link to the file to optimize performance:
            const blob = URL.createObjectURL(files[0]);

            // Get the image type from the extension. It's the simplest way, though be careful it can lead to an incorrect result:
            setImageBlob({
                src: blob,
                type: files[0].type
            })
        }
    };

    return (
        <>
            <FileUploader
                accept="image/*"
                onValueChange={onLoadImage}
                uploadMode="useForm"
            />
            <Cropper
                ref={props.cropperRef}
                src={imageBlob && imageBlob?.src}
                className={'cropper'}
                stencilComponent={RectangleStencil}
                stencilProps={{
                    aspectRatio: 1/1,
                }}
                imageRestriction={ImageRestriction.fitArea}
            />
        </>
    )
}