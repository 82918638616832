import React from "react";
import {
    BackToTop,
    Dropdown, DropdownItem,
    DropdownList,
    EmptyState,
    EmptyStateBody,
    EmptyStateHeader,
    EmptyStateIcon,
    EmptyStateVariant,
    MenuToggle,
    Modal,
    ModalVariant, NotificationDrawer,
    NotificationDrawerBody,
    NotificationDrawerGroup,
    NotificationDrawerGroupList,
    NotificationDrawerHeader,
    NotificationDrawerList,
    NotificationDrawerListItem,
    NotificationDrawerListItemBody,
    NotificationDrawerListItemHeader,
    Page,
    PageSection,
    PageSectionVariants,
    SkipToContent,
    TextContent
} from "@patternfly/react-core";
import {DiazarPageHeader} from "../Components/PageHeader/DiazarPageHeader";
import {DiazarAlerts} from "../Components/DiazarAlerts";
import {useAuthDispatch, useAuthState} from "../Context/AuthProvider/AuthProvider";
import {useAlertDispatcherState} from "../Context/AlertProvider/AlertProvider";
import {logout} from "../Context/AuthProvider/AuthProviderActions";
import {addAlert} from "../Context/AlertProvider/AlertProviderActions";
import {BreadcrumbsType, DiazarPageBreadcrumb} from "../Components/PageHeader/DiazarPageBreadcrumb";
import {DiazarSideBar} from "../Components/PageHeader/DiazarSideBar";
import {FeedbackForm} from "../Components/FeedbackForm";
import {SpeedDialAction} from "devextreme-react/speed-dial-action";
import {LoadPanel} from "devextreme-react/load-panel";
import EllipsisVIcon from "@patternfly/react-icons/dist/esm/icons/ellipsis-v-icon";
import {AttentionBellIcon} from "@patternfly/react-icons";
import {appUseClient, Client, Notifications} from "../Services/ClientsServices";
import {useNavigate} from "react-router-dom";
import notify from "devextreme/ui/notify";
import notificationsServices from "../Services/NotificationsServices";
import moment from "moment";

interface ViewProps{
    title: string | React.ReactNode;
    children?: React.ReactNode;
    description?:React.ReactNode;
    showAll?: boolean
    breadcrumbs?: BreadcrumbsType[]
}

interface ActionsMenu {
    [toggleId: string]: boolean;
}
const pageId = 'main-content-page-layout-tertiary-nav';

export const PageLayout: React.FunctionComponent<ViewProps> = (props) => {
    const userDetails = useAuthState();
    const [isOpenModal, setIsOpenModal] = React.useState(false)
    const PageSkipToContent = <SkipToContent href={`#${pageId}`}>Skip to content</SkipToContent>;
    const [isBackToTopVisible, setIsBackToTopVisible] = React.useState<boolean>(false);
    const [sendingFeedback, setSendingFeedback] = React.useState<boolean>(false);
    const [isDrawerExpanded, setIsDrawerExpanded] = React.useState<boolean>(false);
    const [notificationGroup, setNotificationGroup] = React.useState<number>( 0);
    const drawerRef = React.useRef(null);
    const navigate = useNavigate();

    const [shouldShowNotifications, setShouldShowNotifications] = React.useState(true);
    const [isActionsMenuOpen, setIsActionsMenuOpen] = React.useState<ActionsMenu | {}>({});

    const onScroll = React.useCallback( (e:any) => {
        setIsBackToTopVisible((e.target as HTMLDivElement).scrollTop > 100 )
    }, [] )

    const dispatch = useAuthDispatch()
    const alertDispatch = useAlertDispatcherState()
    const { errorMessage } = useAuthState()

    const handleModalToggle = () => {
        setIsOpenModal(false);
    }

    const onCloseNotificationDrawer = (_event: any) => setIsDrawerExpanded((prevState) => !prevState);

    const onToggle = React.useCallback((id: string) => {
        setIsActionsMenuOpen({ [id]: !isActionsMenuOpen[id] });
    }, [isActionsMenuOpen]);

    const closeActionsMenu = () => setIsActionsMenuOpen({});

    const showNotifications = (showNotifications: boolean) => {
       /* setIsUnreadMap(null);*/
        setShouldShowNotifications(showNotifications);
    };

    const notificationDrawerActions: JSX.Element = React.useMemo( () =>
        <>
            {shouldShowNotifications &&
                <DropdownItem key="markAllRead" onClick={() => notificationsServices.markAsRead(userDetails.token+'')}>
                    Marcar todas como leídas
                </DropdownItem>
            }
            <DropdownItem key="clearAll" onClick={() => showNotifications(!shouldShowNotifications)}>
                {shouldShowNotifications ? 'Desactivar notificaciones' : 'Activar notificaciones'}
            </DropdownItem>
        </>
    , [shouldShowNotifications, userDetails.token]);

    const focusDrawer = (_event) => {
        if (!drawerRef.current) {
            return;
        }
        // Prevent the NotificationDrawer from receiving focus if a drawer group item is opened
        // @ts-ignore
        if (!document.activeElement?.closest(`.${drawerRef.current.className}`)) {
            // @ts-ignore
            const firstTabbableItem = drawerRef.current.querySelector('a, button') as
                | HTMLAnchorElement
                | HTMLButtonElement
                | null;
            firstTabbableItem?.focus();
        }
    };

    const clickOnNotification = React.useCallback((event , id: number, clientID: number) => {
        if (userDetails.cliente_seleccionado?.ID === clientID) {
            navigate(`/quotes/${id}`)
        } else {
            appUseClient(clientID+'', dispatch, userDetails.token + '').then((data) => {
                if (data.status === "SUCCESS") {
                    notify({
                            message: "Cliente seleccionado",
                            height: 50,
                            width: 150,
                            type: "success",
                            displayTime: 3500,
                            animation: {
                                show: {
                                    type: 'fade', duration: 400, from: 0, to: 1,
                                },
                                hide: {type: 'fade', duration: 40, to: 0},
                            },
                        },
                        {
                            position: "top right",
                            direction: "down-push"
                        });
                    navigate(`/quotes/${id}`)
                } else {
                    notify({
                            message: data.error.error,
                            height: 50,
                            width: 150,
                            type: "error",
                            displayTime: 3500,
                            animation: {
                                show: {
                                    type: 'fade', duration: 400, from: 0, to: 1,
                                },
                                hide: {type: 'fade', duration: 40, to: 0},
                            },
                        },
                        {
                            position: "top right",
                            direction: "down-push"
                        });
                }
            })
        }
        setIsDrawerExpanded(false);
    }, [dispatch, navigate, userDetails.cliente_seleccionado?.ID, userDetails.token]);

    const notificationDrawer = React.useMemo(() =>
        <NotificationDrawer ref={drawerRef}>
            <NotificationDrawerHeader count={userDetails.totalNotificacionesSinLeer} onClose={onCloseNotificationDrawer} title = 'Notificaciones' unreadText = 'sin leer'>
                <Dropdown
                    onSelect={closeActionsMenu}
                    isOpen={isActionsMenuOpen['toggle-id-0'] || false}
                    id="notification-0"
                    onOpenChange={(isOpen: boolean) => !isOpen && closeActionsMenu()}
                    popperProps={{ position: 'right' }}
                    toggle={(toggleRef: React.RefObject<any>) => (
                        <MenuToggle
                            ref={toggleRef}
                            id="toggle-id-0"
                            aria-label="Notification drawer actions"
                            variant="plain"
                            onClick={() => onToggle('toggle-id-0')}
                            isExpanded={isActionsMenuOpen['toggle-id-0'] || false}
                        >
                            <EllipsisVIcon aria-hidden="true" />
                        </MenuToggle>
                    )}
                >
                    <DropdownList>{notificationDrawerActions}</DropdownList>
                </Dropdown>
            </NotificationDrawerHeader>
            <NotificationDrawerBody>
                {shouldShowNotifications && (
                    <NotificationDrawerGroupList>
                        { userDetails.clientes !== undefined ? userDetails.clientes.map((client: Client, index: number)=> {
                            return <NotificationDrawerGroup
                                key={`client-notifications-${client.ID}`}
                                title={client.cliente_nombre_comercial ? client.cliente_nombre_comercial : client.cliente}
                                isExpanded={notificationGroup === client.ID}
                                count={client.cotizaciones_sin_leer}
                                onExpand={() => notificationGroup=== client.ID ? setNotificationGroup(0) : setNotificationGroup(client.ID)}
                            >
                                <NotificationDrawerList isHidden={notificationGroup !== client.ID}>
                                    {userDetails.clientes !== undefined ? userDetails.clientes[index].notificaciones?.map((notification: Notifications, index:number)=> {
                                        const message = notification.mensaje.split(',')

                                        return <NotificationDrawerListItem
                                            key={`notification-${notification.ID}`}
                                            variant="info"
                                            onClick={(event) => clickOnNotification(event, notification.contexto_id, client.ID)}
                                            isRead={notification.esta_leida}
                                        >
                                            <NotificationDrawerListItemHeader
                                                variant="custom"
                                                title={message[0]}
                                                srTitle="Info notification:"
                                            >
                                                {/* !notification.esta_leida ? null :
                                                    <Dropdown
                                                        onSelect={closeActionsMenu}
                                                        isOpen={isActionsMenuOpen[`toggle-id-${client.ID}-${notification.ID}`] || false}
                                                        id={'submenu-'+notification.ID}
                                                        onOpenChange={(isOpen: boolean) => !isOpen && closeActionsMenu()}
                                                        popperProps={{ position: 'right' }}
                                                        toggle={(toggleRef: React.RefObject<any>) => (
                                                            <MenuToggle
                                                                ref={toggleRef}
                                                                id={`toggle-id-${client.ID}-${notification.ID}`}
                                                                aria-label="Notification drawer actions"
                                                                variant="plain"
                                                                onClick={() => onToggle(`toggle-id-${client.ID}-${notification.ID}`)}
                                                                isExpanded={isActionsMenuOpen[`toggle-id-${client.ID}-${notification.ID}`] || false}
                                                            >
                                                                <EllipsisVIcon aria-hidden="true" />
                                                            </MenuToggle>
                                                        )}
                                                    >
                                                        <DropdownList>
                                                            <DropdownItem
                                                                key={`options-${notification.ID}`}
                                                                // Prevent default onClick behavior for demo purposes
                                                                onClick={() => notificationsServices.markAsRead(userDetails.token+'', client.ID, notification.ID)}
                                                            >
                                                                Marcar como leida
                                                            </DropdownItem>
                                                        </DropdownList>
                                                    </Dropdown>
                                                */}
                                            </NotificationDrawerListItemHeader>
                                            <NotificationDrawerListItemBody timestamp={moment(notification.CreatedAt).startOf('second').fromNow()}>
                                                {message[1]}
                                            </NotificationDrawerListItemBody>
                                        </NotificationDrawerListItem>
                                    }) : null}
                                </NotificationDrawerList>
                            </NotificationDrawerGroup>
                        }) : null}
                    </NotificationDrawerGroupList>
                )}
                {!shouldShowNotifications && (
                    <EmptyState variant={EmptyStateVariant.full}>
                        <EmptyStateHeader
                            headingLevel="h2"
                            titleText="Notificaciones silenciadas"
                            icon={<EmptyStateIcon icon={AttentionBellIcon} />}
                        />
                        <EmptyStateBody>
                            Actualmente las notificaciones están silenciadas.
                        </EmptyStateBody>
                        {/*<EmptyStateFooter>
                            <EmptyStateActions>
                                <Button variant="link">Action</Button>
                            </EmptyStateActions>
                        </EmptyStateFooter>*/}
                    </EmptyState>
                )}
            </NotificationDrawerBody>
        </NotificationDrawer>,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [clickOnNotification, isActionsMenuOpen, notificationDrawerActions, notificationGroup, onToggle, shouldShowNotifications, userDetails.clientes, userDetails.totalNotificacionesSinLeer, userDetails.token]);

    const feedback = React.useMemo(() =>
        <SpeedDialAction
            label="¡Ayúdanos a mejorar!"
            index={4}
            onClick={() => setIsOpenModal(true)}
        />, [])

    React.useEffect( () => {
        if( errorMessage !== undefined && errorMessage !== '' ){
            addAlert( 'Error', errorMessage, "danger", alertDispatch);

            setTimeout( ()=>{
                logout( dispatch ).then();
            }, 500 )
        }
    }, [errorMessage, alertDispatch, dispatch] );

    return (
        <React.Fragment>
            <DiazarAlerts />
            <Page
                header={<DiazarPageHeader notification={isDrawerExpanded} actionDrawer={setIsDrawerExpanded}/>}
                breadcrumb={ <DiazarPageBreadcrumb breadcrumbs={ (props.breadcrumbs) ? props.breadcrumbs : [] } /> }
                isBreadcrumbGrouped
                isTertiaryNavGrouped
                sidebar={ <DiazarSideBar /> }
                isManagedSidebar
                isTertiaryNavWidthLimited
                notificationDrawer={notificationDrawer}
                isNotificationDrawerExpanded={isDrawerExpanded}
                onNotificationDrawerExpand={(
                    event: React.MouseEvent<Element, MouseEvent> | KeyboardEvent | React.TransitionEvent<Element>
                ) => focusDrawer(event)}
                skipToContent={PageSkipToContent}
                mainContainerId={pageId}
                additionalGroupedContent={
                    ( props.showAll ) ? null :
                    <PageSection variant={PageSectionVariants.light}>
                        <TextContent>
                            <h1>{ props.title }</h1>
                            { props.description }
                        </TextContent>
                    </PageSection>
                }
                groupProps={{
                stickyOnBreakpoint: {default: 'top'}
                }}
            >
                <PageSection hasOverflowScroll
                             name="scrolling-section"
                             tabIndex={0}
                             onScroll={ onScroll }
                             aria-label="scrolling-section"
                >
                    <div className="scrolling-container" style={{ padding: 0 }}>
                        { props.children }
                    </div>
                </PageSection>
                <BackToTop title="Volver arriba" scrollableSelector='[name="scrolling-section"]' isAlwaysVisible={isBackToTopVisible} style={{zIndex: 5000, marginBottom: '20px'}} />
                {feedback}
            </Page>
            <Modal
                variant={ModalVariant.small}
                title="¡Nos encantaría escuchar tu idea!"
                description="Por favor llena el siguiente formulario y platicanos tu idea."
                isOpen={isOpenModal}
                onClose={handleModalToggle}
                id="feedbackApp"
            >
                <FeedbackForm sendingAction={setSendingFeedback} isOpenModalAction={setIsOpenModal}/>
            </Modal>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: '#feedbackApp'}}
                visible={sendingFeedback}
                showIndicator={false}
                message="Enviando..."
            />
        </React.Fragment>
    )
}