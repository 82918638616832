import React from "react";
import {Modal, ModalVariant} from "@patternfly/react-core";
import {CatProductsDataGrid} from "./CatProductsDataGrid"
import QuotesServices, {ItemQuote} from "../Services/QuotesServices";
import {Button} from "devextreme-react/button";
import {Product} from "../Services/ProductsServices";
import notify from "devextreme/ui/notify";
import {useAuthState} from "../Context/AuthProvider/AuthProvider";

interface CatProductsModalProps {
    isModalOpen: boolean;
    handleModalToggle: React.Dispatch<React.SetStateAction<boolean>>;
    selectedItem: ItemQuote | undefined;
}

export const CatProductsModal = (props: CatProductsModalProps) => {
    const [selectedProduct, setSelectedProduct] = React.useState<Product | undefined>(undefined);
    const {token} = useAuthState();

    const rowUpdated = React.useCallback((e) => {
        QuotesServices.putItemByIdRequest(e['solicitud_cotizacion_id'], e['item_sol_cotizacion_id'], e, token+'')
            .then((resp) => {
                !resp && notify({
                        message: 'No hacer la vinculación',
                        height: 50,
                        width: 150,
                        type: "error",
                        displayTime: 3500,
                        animation: {
                            show: {
                                type: 'fade', duration: 400, from: 0, to: 1,
                            },
                            hide: { type: 'fade', duration: 40, to: 0 },
                        },
                    } ,
                    {
                        position : "top right",
                        direction: "down-push"
                    });
                handleModalToggle();
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const vincular = () => {

        let newItem = {
            ...props.selectedItem,
            "precio_sugerido": (props.selectedItem && props.selectedItem.pu_prov === 0) ? selectedProduct?.precio_por_unidad: props.selectedItem?.pu_prov,
            "pu_prov": (props.selectedItem && props.selectedItem.pu_prov === 0) ? selectedProduct?.precio_por_unidad: props.selectedItem?.pu_prov,
            "recurso_catalogo_id": selectedProduct?.ID
        }

        rowUpdated(newItem);

    }

    const handleModalToggle = React.useCallback(() => {
        setSelectedProduct(undefined);
        props.handleModalToggle(false);
    }, [props]);

    return (
        <Modal
            variant={ModalVariant.medium}
            title="Buscador de recursos"
            isOpen={props.isModalOpen}
            onClose={handleModalToggle}
            id="modalCatRec"
        >
            <CatProductsDataGrid isComponent={false} selectedProduct={setSelectedProduct}/>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button
                    text="Vincular"
                    icon="check"
                    type="success"
                    style={{marginRight: '5px'}}
                    disabled={!selectedProduct}
                    onClick={vincular}
                />
                {/*<Button
                    text="Cancelar"
                    stylingMode="text"
                    style={{marginLeft: '5px'}}
                    onClick={handleModalToggle}
                />*/}
            </div>
        </Modal>
    )
}