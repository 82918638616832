import React from "react";
import {
    Flex,
    FlexItem,
    Grid,
    GridItem,
    Stack,
    StackItem,
    Title
} from '@patternfly/react-core';
import { big_reverse_logo } from "../Config/Images";
import {ListItemsInfoLinks} from "../Components/ListItemsInfoLinks";
import styles from '../Pages/InfoPages/infopages.module.css'
import {DiazarAlerts} from "../Components/DiazarAlerts";
import {DiazarBackgroundImage} from "../Components/DiazarBackgroundImage";

interface ViewProps {
    children?: React.ReactNode;
    title?: string
}

export const InfoLayout: React.FunctionComponent<ViewProps> = (props) => {
    return (
        <React.Fragment>
            <DiazarBackgroundImage />
            <DiazarAlerts />
            <Grid className={styles.container}>
                <GridItem span={12}>
                    <Stack hasGutter className={ styles.fullHeight }>
                        <StackItem>
                            <Flex fullWidth={{default:'fullWidth'}} className={styles.marginTop}>
                                <FlexItem>
                                    <img src={big_reverse_logo} alt="Diazar Logo" className={styles.logo}/>
                                </FlexItem>
                                <FlexItem>&nbsp;</FlexItem>
                                <FlexItem>
                                    <Title headingLevel="h2" className={ styles.colorWite } >
                                        { props.title }
                                    </Title>
                                </FlexItem>
                                <FlexItem align={{ default: 'alignRight' }} >
                                    <ListItemsInfoLinks className={ styles.colorWite }/>
                                </FlexItem>
                            </Flex>

                        </StackItem>

                        <StackItem isFilled className="pf-v5-u-box-shadow-sm" >
                            <div className={ styles.infoContainer }>
                                { props.children }
                            </div>

                        </StackItem>
                        <StackItem>
                            &nbsp;
                        </StackItem>
                    </Stack>
                </GridItem>
            </Grid>

        </React.Fragment>

    )
}