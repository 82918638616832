import React from 'react';
import notify from 'devextreme/ui/notify';
import { useNavigate } from "react-router-dom";
import {useAuthDispatch, useAuthState} from "../../Context/AuthProvider/AuthProvider";
import {loginUser} from "../../Context/AuthProvider/AuthProviderActions";
import {LoginPayloadRequest} from "../../Context/AuthProvider/AuthDomain";
import {FormHelperText, Form, Button, ActionGroup} from "@patternfly/react-core";
import {ExclamationCircleIcon} from "@patternfly/react-icons";
import {DiazarFormInput} from "../Form/DiazarFormInput";

interface LoginFormProps {
    actions?: {
        addClient: string | null
    }
}

export const LoginForm = (props: LoginFormProps) => {
    const [user, setUser] = React.useState<string>('');
    const [isValidUser, setIsValidUser] = React.useState<boolean>(false);
    const [password, setPassword] = React.useState<string>('');
    const [isValidPassword, setIsValidPassword] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useAuthDispatch()
    const { loading, errorMessage } = useAuthState()

    const login = async () => {
        let payload:LoginPayloadRequest = {username:user, password:password}
        try {
            let responseLogin = await loginUser(payload, dispatch)
            if (typeof responseLogin != 'string' && responseLogin.status === "ERROR" && responseLogin.error.error === 'No existe usuario con las credenciales presentadas') {
                notify({
                        message: responseLogin.error.error,
                        type: "error",
                        displayTime: 3500,
                        animation: {
                            show: {
                                type: 'fade', duration: 400, from: 0, to: 1,
                            },
                            hide: { type: 'fade', duration: 40, to: 0 },
                        },
                    } ,
                    {
                        position : "top right",
                        direction: "down-push"
                    });
            } else if (typeof responseLogin != 'string' && !responseLogin.response.token) {
                notify({
                        message: 'Necesitas validar tu correo electrónico antes de continuar.',
                        type: "warning",
                        displayTime: 3500,
                        animation: {
                            show: {
                                type: 'fade', duration: 400, from: 0, to: 1,
                            },
                            hide: { type: 'fade', duration: 40, to: 0 },
                        },
                    } ,
                    {
                        position : "top right",
                        direction: "down-push"
                    });
                navigate(`/validate-email?email=${user}`);
            } else {
                const clientDate = localStorage.getItem('client.date');
                const clientToken = localStorage.getItem('client.token');

                if (clientDate && clientToken) {
                    const date = new Date(clientDate);
                    const now = new Date();
                    const diff = Math.abs(now.getTime() - date.getTime());
                    const minutes = Math.floor((diff/1000)/60);
                    localStorage.removeItem('client.date');
                    localStorage.removeItem('client.token')

                    if (minutes > 30) {
                        navigate('/');
                    }else{
                        navigate(`/add-diazar-client/${ clientToken }`);
                    }
                }else{
                    navigate('/');
                }
            }

        } catch (error) {
            notify({
                    message: `${error}`,
                    height: 50,
                    width: 150,
                    type: "error",
                    displayTime: 3500,
                    animation: {
                        show: {
                            type: 'fade', duration: 400, from: 0, to: 1,
                        },
                        hide: { type: 'fade', duration: 40, to: 0 },
                    },
                } ,
                {
                    position : "top right",
                    direction: "down-push"
                });
            navigate('/register');
        }
    }

    const isValidForm = React.useMemo<boolean>( () => {
        const isLoading = ( loading !== undefined ) ? loading : false
        return !isLoading && isValidUser && isValidPassword

    }, [ isValidUser, isValidPassword, loading] )

    const helperText = React.useMemo( () => {
        if ( errorMessage === undefined ){
            return null
        }else{
            return (
                <FormHelperText  >
                    <ExclamationCircleIcon />
                    &nbsp;{errorMessage}.
                </FormHelperText>
            )
        }
    }, [errorMessage] )

    return(
        <div className="auth-wrapper">
            <div className="auth-inner">
                    <Form>
                        {helperText}
                        <DiazarFormInput id="login-clave" label="Correo electrónico" isRequired
                                         type="text"
                                         placeholder="Ingresa el correo electrónico registrado"
                                         getState={ (isValid, value) => {
                                             setIsValidUser(isValid)
                                             setUser(value)
                                         } }
                        />
                        <DiazarFormInput id="login-password" label="Contraseña" isRequired
                                         type="password"
                                         placeholder="Ingresa tu contraseña"
                                         getState={ (isValid, value) => {
                                             setIsValidPassword(isValid)
                                             setPassword(value)
                                         } }
                        />
                        <ActionGroup>
                            <Button type="submit"
                                    onClick={ e => { e.preventDefault(); login() }}
                                    isBlock
                                    isDisabled={ !isValidForm }
                                    isLoading={ ( loading !== undefined ) ? loading : false }
                            >
                                Iniciar Sesión
                            </Button>
                            <div style={{width: '100%', textAlign:'center', marginBottom: '15px'}}>
                                <span style={{backgroundColor:'white', padding:'0 15px'}}>Si aún no tienes cuenta</span>
                                <hr style={{marginTop:'-10px'}}/>
                            </div>
                            <Button type="submit"
                                    onClick={ e => { e.preventDefault(); navigate("/register") }}
                                    isBlock
                                    variant="secondary"
                            >
                                Regístrate
                            </Button>
                        </ActionGroup>
                    </Form>
            </div>
        </div>
    )
}
