import React, {useMemo} from "react";
import {
    Card,
    CardTitle,
    CardBody,
    CardFooter,
    Grid,
    GridItem,
    CardHeader,
    Skeleton
} from '@patternfly/react-core';
import {useNavigate} from "react-router-dom";
import {useAuthDispatch, useAuthState} from "../Context/AuthProvider/AuthProvider";
import {appUseClient} from "../Services/ClientsServices";
import notify from "devextreme/ui/notify";

export const QuotesCardPerClients = () => {
    const dispatch = useAuthDispatch();
    const {token, clientes, cliente_seleccionado} = useAuthState();
    const navigate = useNavigate();

    const clientClickHandler = React.useCallback( (clienteID) => {
        if (clienteID !== cliente_seleccionado?.ID) {
            appUseClient(clienteID, dispatch, token + '').then((data) => {
                if (data.status === "SUCCESS") {
                    notify({
                            message: "Cliente seleccionado",
                            type: "success",
                            displayTime: 3500,
                            animation: {
                                show: {
                                    type: 'fade', duration: 400, from: 0, to: 1,
                                },
                                hide: {type: 'fade', duration: 40, to: 0},
                            },
                        },
                        {
                            position: "top right",
                            direction: "down-push"
                        });
                    navigate('/quotes');
                } else {
                    notify({
                            message: data.error.error,
                            type: "error",
                            displayTime: 3500,
                            animation: {
                                show: {
                                    type: 'fade', duration: 400, from: 0, to: 1,
                                },
                                hide: {type: 'fade', duration: 40, to: 0},
                            },
                        },
                        {
                            position: "top right",
                            direction: "down-push"
                        });
                }
            })
        } else {
            navigate('/quotes');
        }
    }, [cliente_seleccionado?.ID, dispatch, navigate, token] )

    const Cards = useMemo( () => {
        if (clientes === undefined || clientes.length === 0) {
            return <h3>No hay clientes aún</h3>
        } else {
            return (
                clientes.map((cliente) => {
                    return(
                        <GridItem span={4} key={cliente.ID}>
                            <Card onClick={(e) => clientClickHandler(cliente.ID)} style={{cursor: 'pointer'}}>
                                <CardTitle>{cliente.cliente_nombre_comercial}</CardTitle>
                                <CardBody>
                                    <p>Sin leer: {cliente.cotizaciones_sin_leer}</p>
                                    <p>Sin responder: {cliente.cotizaciones_pendientes}</p>
                                </CardBody>
                            </Card>
                        </GridItem>
                    )
                })
            )
        }
    }, [clientes, clientClickHandler])

    React.useEffect(() => {}, [clientes])

    return (
        <Grid style={{paddingTop: "10px", paddingBottom: "2.5rem"}}>
            {Cards}
        </Grid>
    )
}