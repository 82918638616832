import React from 'react';
import {PageLayout} from "../../Layouts/PageLayout";
import {DiazarCardsLists} from "../../Components/DiazarCardsLists";
import {DiazarToolbar} from "../../Components/DiazarToolbar";
import Quotes, {Response} from "../../Services/QuotesServices";
import notify from "devextreme/ui/notify";
import {useAuthState} from "../../Context/AuthProvider/AuthProvider";
import {QuotesListPerClients} from "../../Components/QuotesListPerClients";
import {QuotesList} from "../../Components/QuotesList";

/**
 * Loads and displays a page containing a list of quotes.
 *
 * @param {object} _props - The properties passed to the component.
 *
 * @return {React.Element} The rendered page with the list of quotes.
 */
export const QuotesPage: React.FunctionComponent = (_props) => {
    const [mode, setMode] = React.useState<'grid' | 'list'>('list');
    const [viewArchived, setViewArchived] = React.useState<boolean>(true);
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [items, setItems] = React.useState<any[]>([]);
    const [refresh, setRefresh] = React.useState<boolean>(true);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const {token} = useAuthState()
    const description = "";

    const loadItems = React.useCallback( async () => {
        try {
            const ITEMS: Response | string = await Quotes.getAllQuotes(currentPage, token+'');

            if (typeof ITEMS === 'string') {
                notify({
                        message: ITEMS,
                        height: 50,
                        width: 150,
                        type: "error",
                        displayTime: 5000,
                        animation: {
                            show: {
                                type: 'fade', duration: 400, from: 0, to: 1,
                            },
                            hide: {type: 'fade', duration: 40, to: 0},
                        },
                    },
                    {
                        position: "top right",
                        direction: "down-push"
                    });
            } else {
                setItems(prevItems => [...prevItems, ...ITEMS.rows]);

                const offset = (ITEMS.page -1) * ITEMS.limit  + ITEMS.rows.length;

                if (offset < ITEMS.total) {
                    setCurrentPage(currentPage + 1);
                    setRefresh(true)
                }
            }
        } catch (error) {
            notify({
                    message: "No has seleccionado un cliente",
                    height: 50,
                    width: 150,
                    type: "error",
                    displayTime: 5000,
                    animation: {
                        show: {
                            type: 'fade', duration: 400, from: 0, to: 1,
                        },
                        hide: {type: 'fade', duration: 40, to: 0},
                    },
                },
                {
                    position: "top right",
                    direction: "down-push"
                });
            console.error(error);
        }
    }, [currentPage, token]);

    React.useEffect(() => {
        if( refresh ) {
            setRefresh(false);
            setIsLoading(true);

            loadItems()
                .then(() => {
                    setIsLoading(false);
                });

        }
    }, [refresh, loadItems]);

    React.useEffect(() => {
        setCurrentPage(1);
        setItems([]);
        setRefresh(true);
    }, [token]);

    return (
        <PageLayout title="Cotizaciones"
                    description={description}
                    breadcrumbs={ [{title:'Inicio', to: '/'}, {title:'Clientes', to: '/quotes-clients'},{title:'Cotizaciones', to:'/quotes', isActive: true}] }
        >
            <DiazarToolbar actions={{
                mode: setMode,
                initialMode: 'list',
                viewArchived: setViewArchived,
                back: '/quotes-clients',
            }}/>
            <br />
            {mode === 'grid' ? <DiazarCardsLists datas={items} ignore={viewArchived} isLoading={isLoading}/> : <QuotesList datas={items} ignore={viewArchived} isLoading={true}/>}
        </PageLayout>
    )
}