import {ServicesUrl} from "../Config/UrlRuotes";
import {Notifications} from "./ClientsServices";

interface Error {
    "has-error": boolean;
    error?: string
}

interface responseGetAllNotifications {
    ID: number;
    CreatedAt: string;
    UpdatedAt: string;
    cliente: string;
    cotizaciones_pendientes: number;
    cotizaciones_sin_leer: number;
    notificaciones: Notifications[]
}

interface ResponseDefault {
    status: "SUCCESS" | "ERROR",
    error: Error,
}

interface ResponseGetAllNotification extends ResponseDefault {
    response: responseGetAllNotifications[];
}

interface ResponseMarkAsRead extends ResponseDefault {
    response: string;
}

interface ReturnDefault {
    ok: boolean;
}

interface ReturnGetAllNotifications extends ReturnDefault {
    data: responseGetAllNotifications[] | string;
}

interface ReturnMarkAsRead extends ReturnDefault {
    data: string;
}

const getAll = async (token: string): Promise<ReturnGetAllNotifications | string> => {
    const response = await fetch(ServicesUrl.notifications, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });
    const data: ResponseGetAllNotification = await response.json();

    if (data.status === "SUCCESS") {
        return {
            ok: true,
            data: data.response
        }
    } else {
        return {
            ok: false,
            data: data.error.error ? data.error.error : ''
        }
    }
}

const markAsRead = async (token: string, clientID?: number, notificationID?: number): Promise<ReturnMarkAsRead> => {

    let URL = `${ServicesUrl.notifications}`

    clientID ?
        URL += `/${clientID}/mark-as-read`:
        URL += '/0/mark-as-read';

    notificationID && clientID ?
        URL += `/${notificationID}`:
        URL += '';


    const response = await fetch(URL, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });

    const data: ResponseMarkAsRead = await response.json();

    if (data.status === "SUCCESS") {
        return {
            ok: true,
            data: data.response
        }
    } else {
        return {
            ok: false,
            data: data.error.error ? data.error.error : ''
        }
    }
}

const objectExported = {getAll, markAsRead}
export default objectExported;