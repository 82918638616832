import React from 'react';
import {
    Toolbar,
    ToolbarItem,
    ToolbarContent,
    Button,
    Tooltip, TextContent, TextVariants, Text
} from '@patternfly/react-core';
import { ListIcon, ThIcon, BoxOpenIcon, AngleDoubleLeftIcon } from '@patternfly/react-icons/dist/esm/icons';
import {useNavigate} from "react-router-dom";
import {useAuthState} from "../Context/AuthProvider/AuthProvider";


interface DiazarToolbarProps {
    actions: {
        mode?: React.Dispatch<React.SetStateAction<'grid' | 'list'>>;
        initialMode?: 'grid' | 'list';
        viewArchived?: React.Dispatch<React.SetStateAction<boolean>>;
        back?: string;
    }
}

export const DiazarToolbar: React.FunctionComponent<DiazarToolbarProps> = (props: DiazarToolbarProps) => {
    const [isGrid, setIsGrid] = React.useState<boolean>(props.actions.initialMode === undefined ? true : props.actions.initialMode !== 'list');
    const [viewArchived, setViewArchived] = React.useState<boolean>(false);
    const { cliente_seleccionado } = useAuthState();
    const navigate = useNavigate();


    const ChangeMode = (mode: 'grid' | 'list') => {
        if (mode === 'grid') {
            setIsGrid(true);
        }
        else {
            setIsGrid(false);
        }
        props.actions.mode && props.actions.mode(mode);
    }

    const ChangeViewArchived = () => {
        setViewArchived(!viewArchived);
        props.actions.viewArchived && props.actions.viewArchived(viewArchived);
    }

    /* redacta esta función */
    React.useEffect(() => {

    }, [isGrid, /*viewArchived*/]);

    const items = (
        <React.Fragment>
            {
                props.actions.back &&
                <ToolbarItem align={{default: "alignLeft"}}>
                    <div style={{ padding: "6px 0 0 6px"}}>
                        <Button variant="secondary" isInline onClick={() => navigate(`${props.actions.back}`)} icon={<AngleDoubleLeftIcon />}>
                            Regresar
                        </Button>{' '}
                    </div>
                </ToolbarItem>
            }
            {
                cliente_seleccionado?.nombre_en_diazar && props.actions.back &&
                <>
                <ToolbarItem variant={'separator'} />
                <ToolbarItem align={{default: "alignLeft"}}>
                    <TextContent>
                        <Text component={TextVariants.small}>Registro en DiazarControl</Text>
                        <Text component={TextVariants.pre}>{cliente_seleccionado.nombre_en_diazar}</Text>
                    </TextContent>
                </ToolbarItem>
                </>
            }
            {
                props.actions.mode &&
                <ToolbarItem align={{default: "alignRight" }}>
                    <div style={{ padding: "6px 10px"}}>
                        <Tooltip aria="none" aria-live="polite" content={isGrid ? 'Estás viendo en cuadrícula' : 'Ver en cuadrícula'} position="bottom" >
                            <Button variant={isGrid ? "primary" : "tertiary"} aria-label="ThIcon" onClick={() => ChangeMode('grid')} isActive={isGrid}>
                                <ThIcon />
                            </Button>
                        </Tooltip>
                    </div>
                    <div style={{ padding: "6px 0 6px 0"}}>
                        <Tooltip aria="none" aria-live="polite" content={!isGrid ? 'Estás viendo en lista' : 'Ver en lista'} position="bottom">
                            <Button variant={!isGrid ? "primary" : "tertiary"} aria-label="ListIcon" onClick={() => ChangeMode('list')} isActive={!isGrid}>
                                <ListIcon />
                            </Button>
                        </Tooltip>
                    </div>
                </ToolbarItem>
            }
            {/*<ToolbarItem align={{default: "alignRight"}}>
                <Badge key={1}  style={viewArchived ? {backgroundColor: '#001d44'} : {backgroundColor: '#ff8d0a'}}>
                    <TextContent>
                        <Text component={TextVariants.h3} style={{color: '#fff'}}>{viewArchived ? "Terminados" : "Pendientes"}</Text>
                    </TextContent>
                </Badge>
            </ToolbarItem>*/}
            {props.actions.viewArchived &&
                <ToolbarItem variant={'separator'} />
            }
            {props.actions.viewArchived &&
                <ToolbarItem>
                    <div style={{padding: "6px 10px"}}>
                        <Tooltip aria="none" aria-live="polite"
                                 content={viewArchived ? 'Ver pendientes' : 'Ver terminadas'} position="auto">
                            <Button variant={viewArchived ? "primary" : "tertiary"} aria-label="archived"
                                    isActive={viewArchived} onClick={() => ChangeViewArchived()}>
                                <BoxOpenIcon/>
                            </Button>
                        </Tooltip>
                    </div>
                </ToolbarItem>
            }
            {/*
            <ToolbarItem variant="search-filter" >
                <SearchInput arial-label="Search Input" placeholder="Buscar"/>
            </ToolbarItem>*/}
        </React.Fragment>
    )

    return (
        <Toolbar id="toolbar" >
            <ToolbarContent>{items}</ToolbarContent>
        </Toolbar>
    )
}