import { createFetch } from "simple-fetch";
import {ServicesUrl} from "../Config/UrlRuotes";

export interface Error {
    "has-error": boolean;
    "error"?: string;
}

export interface UserLogin {
    "ID": number;
    "CreatedAt": string;
    "UpdatedAt": string;
    "DeletedAt": string;
    "nombres": string;
    "apellidos": string;
    "email": string;
    "password": string;
    "imagen": string;
    "empresa_id": number;
}

export interface CompanyLogin {
    "ID": number;
    "CreatedAt": string;
    "UpdatedAt": string;
    "DeletedAt": string;
    "nombre": string;
    "razon_social": string;
    "rfc": string;
}

export interface ResponseLogin {
    token: string;
    usuario: UserLogin;
    empresa: CompanyLogin
}

export interface Response {
    status: string;
    error: Error
    response: ResponseLogin
}

export interface ResponseResendEmail {
    status: string;
    error: Error;
    response: string;
}

const {getJson, postJson} = createFetch();

const login = async (user: string, password: string): Promise<Response> => {
    const response: Response = await postJson(ServicesUrl.login, { username: user, password: password });

    return response;
}

const resendEmail = async (email: string): Promise<ResponseResendEmail> => {
    return await postJson(`${ServicesUrl.users}/validate`, {email: email})
}

const validateEmail = async (token) => {
    return await getJson(`${ServicesUrl.users}/validate/${token}`);
}

const exportedObjects = { login, resendEmail, validateEmail }
export default exportedObjects;