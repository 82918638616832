import {ServicesUrl} from "../Config/UrlRuotes";

interface Error {
    "has-error": boolean;
    error?: string;
}

interface ResponseStandard {
    status: "SUCCESS" | "ERROR";
    error: Error;
    response: any;
}

export interface Product {
    ID?: number;
    "clave": string;
    "descripcion"?: string;
    "unidad": string;
    "imagen"?: string;
    "precio_por_unidad": number;
}

export interface GAProductsResponse {
    limit: number;
    page: number;
    rows: Product[];
    total: number;
}

interface GAProducts extends ResponseStandard{
    response: GAProductsResponse;
}

interface BatchResponse extends ResponseStandard{
    response: Product[];
}

export interface GetAllProductsResponse {
    status: boolean,
    response: GAProductsResponse | string | undefined;
}

const getAllProducts = async (page: number, token: string): Promise<GetAllProductsResponse> => {
    const response = await fetch(`${ServicesUrl.products}?limit=3000&page=${page}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    const data: GAProducts = await response.json();

    if (data.status === 'SUCCESS') {
        return {
            status: true,
            response: data.response
        }
    } else {
        return {
            status: false,
            response: data.error.error
        }
    }
}

const updateBathProduct = async (products:any[], token: string) : Promise<BatchResponse> => {
    const response = await fetch(ServicesUrl.products + '/batch-items', {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(products)
    })

    const data = await response.json();
    return data;
}

const addProduct = async (product:any, token: string) => {
    product["precio_por_unidad"] = Number(product["precio_por_unidad"]);
    const response = await fetch(ServicesUrl.products, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(product)
    });

    const data = await response.json();

    if (data.status === 'SUCCESS') {
        return {
            status: true,
            response: data.response
        }
    } else {
        return {
            status: false,
            response: data.error.error
        }
    }
}

const updateProduct = async (id, product, token) => {
    product["precio_por_unidad"] = Number(product["precio_por_unidad"]);
    const response = await fetch(`${ServicesUrl.products}/${id}`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(product)
    });
    const data = await response.json();

    if (data.status === 'SUCCESS') {
        return {
            status: true,
            response: data.response
        }
    } else {
        return {
            status: false,
            response: data.error.error
        }
    }
}

const deleteProduct = async (id, token: string) => {
    const response = await fetch(`${ServicesUrl.products}/${id}`, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`
        },
    });

    const data = await  response.json();

    if (data.status === 'SUCCESS') {
        return {
            status: true,
            response: data.response
        }
    } else {
        return {
            status: false,
            response: data.error.error
        }
    }
}

const uploadImage = async (image, id, token) => {
    const response = await fetch(`${ServicesUrl.products}/${id}/image`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`
        },
        body: image
    });

    const data = await response.json();

    if (data.status === 'SUCCESS') {
        return {
            status: true,
            response: data.response
        }
    } else {
        return {
            status: false,
            response: data.error.error
        }
    }
}

const exportedObjects = { getAllProducts, addProduct, updateProduct, deleteProduct, uploadImage, updateBathProduct }
export default exportedObjects;
