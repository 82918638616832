import React from 'react';
import DataGrid, {
    Column, DataGridTypes,
    HeaderFilter,
    Pager,
    SearchPanel,
    Sorting,
    Summary, TotalItem,
    Selection
} from "devextreme-react/data-grid";
import {useAuthDispatch, useAuthState} from "../Context/AuthProvider/AuthProvider";
import {useNavigate} from "react-router-dom";
import {appUseClient} from "../Services/ClientsServices";
import notify from "devextreme/ui/notify";

export const ClientsList = (_props) => {
    const [updateFlag, setUpdateFlag] = React.useState<boolean>(true);
    const dispatch = useAuthDispatch();
    const {token, clientes, cliente_seleccionado} = useAuthState();
    const [oldClientes, setOldClientes] = React.useState<number | undefined>(clientes?.length);
    const navigate = useNavigate();

    const clietClickHandler = React.useCallback( (clienteID) => {
        if (clienteID !== cliente_seleccionado?.ID) {
            appUseClient(clienteID, dispatch, token + '').then((data) => {
                if (data.status === "SUCCESS") {
                    notify({
                            message: "Cliente seleccionado",
                            type: "success",
                            displayTime: 3500,
                            animation: {
                                show: {
                                    type: 'fade', duration: 400, from: 0, to: 1,
                                },
                                hide: {type: 'fade', duration: 40, to: 0},
                            },
                        },
                        {
                            position: "top right",
                            direction: "down-push"
                        });
                    navigate('/quotes');
                } else {
                    notify({
                            message: data.error.error,
                            type: "error",
                            displayTime: 3500,
                            animation: {
                                show: {
                                    type: 'fade', duration: 400, from: 0, to: 1,
                                },
                                hide: {type: 'fade', duration: 40, to: 0},
                            },
                        },
                        {
                            position: "top right",
                            direction: "down-push"
                        });
                }
            })
        }
    }, [cliente_seleccionado?.ID, dispatch, token] )

    const selectedChanged = React.useCallback((e: DataGridTypes.SelectionChangedEvent) => {
        if (e.selectedRowsData[0].ID === cliente_seleccionado?.ID) {
            navigate("/quotes");
        } else {
            clietClickHandler(e.selectedRowsData[0].ID)
        }
    }, [clietClickHandler]);

    const datagridFn = React.useMemo(() => {
            return (<DataGrid
                dataSource={clientes}
                columnAutoWidth={true}
                onSelectionChanged={selectedChanged}
            >
                <Sorting mode="multiple"/>
                <HeaderFilter visible={true}/>
                <SearchPanel visible={true}/>
                <Selection mode="single"/>
                <Pager
                    visible={true}
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50, 100]}
                    showNavigationButtons={true}
                    showInfo={true}
                    infoText="Página {0} de {1} ({2} clientes)"
                />
                <Summary>
                    <TotalItem
                        column="cliente_nombre_comercial"
                        summaryType="count"
                        customizeText={(data) => `Total de clientes: ${data.value}`}
                    />
                    <TotalItem
                        column="cotizaciones_en_diazar"
                        summaryType="sum"
                        customizeText={(data) => `Total de cotizaciones: ${data.value}`}
                    />
                    <TotalItem
                        column="cotizaciones_pendientes"
                        summaryType="sum"
                        customizeText={(data) => `Total de pendientes: ${data.value}`}
                    />
                    <TotalItem
                        column="cotizaciones_sin_leer"
                        summaryType="sum"
                        customizeText={(data) => `Total sin leer: ${data.value}`}
                    />
                </Summary>
                <Column dataField="cliente_nombre_comercial" caption="Cliente" sortOrder="desc"/>
                <Column dataField="cotizaciones_en_diazar" caption="Cotizaciones en Diazar" dataType="number" />
                <Column dataField="cotizaciones_pendientes" caption="Cotizaciones Pendientes" dataType="number"/>
                <Column dataField="cotizaciones_sin_leer" caption="Cotizaciones Sin Leer" dataType="number"/>
            </DataGrid>)
        }
    , [updateFlag])

    React.useEffect(() => {
        if (clientes !== undefined) {
            if (clientes.length === oldClientes) {
                return;
            } else {
                setOldClientes(clientes.length);
                setUpdateFlag(!updateFlag);
            }
        }
    }, [clientes]);

    return (
        <div>
            {datagridFn}
        </div>
    )
};