import React from 'react';
import DataGrid, {
    Column,
    Pager,
    Selection,
    DataGridTypes,
} from 'devextreme-react/data-grid';
import {useNavigate} from "react-router-dom";
import {useAuthDispatch, useAuthState} from "../Context/AuthProvider/AuthProvider";
import {appUseClient} from "../Services/ClientsServices";
import notify from "devextreme/ui/notify";

export const QuotesListPerClients: React.FunctionComponent = () => {
    const gridRef = React.useRef<DataGrid>(null);
    const dispatch = useAuthDispatch();
    const {token, clientes, cliente_seleccionado} = useAuthState();
    const navigate = useNavigate();

    const clietClickHandler = React.useCallback( (clienteID) => {
        if (clienteID !== cliente_seleccionado?.ID) {
            appUseClient(clienteID, dispatch, token + '').then((data) => {
                if (data.status === "SUCCESS") {
                    notify({
                            message: "Cliente seleccionado",
                            type: "success",
                            displayTime: 3500,
                            animation: {
                                show: {
                                    type: 'fade', duration: 400, from: 0, to: 1,
                                },
                                hide: {type: 'fade', duration: 40, to: 0},
                            },
                        },
                        {
                            position: "top right",
                            direction: "down-push"
                        });
                    navigate('/quotes');
                } else {
                    notify({
                            message: data.error.error,
                            type: "error",
                            displayTime: 3500,
                            animation: {
                                show: {
                                    type: 'fade', duration: 400, from: 0, to: 1,
                                },
                                hide: {type: 'fade', duration: 40, to: 0},
                            },
                        },
                        {
                            position: "top right",
                            direction: "down-push"
                        });
                }
            })
        }
    }, [cliente_seleccionado?.ID, dispatch, token] )

    const selectedChanged = React.useCallback((e: DataGridTypes.SelectionChangedEvent) => {
        if (e.selectedRowsData[0].ID === cliente_seleccionado?.ID) {
            navigate("/quotes");
        } else {
            clietClickHandler(e.selectedRowsData[0].ID)
        }
    }, [clietClickHandler]);

    React.useEffect(() => {}, [clientes])

    return(
        <>
            <DataGrid
                id="QuotesListPerClient"
                dataSource={clientes}
                onSelectionChanged={selectedChanged}
                keyExpr="ID"
                ref={gridRef}
                className="custom-datagrid"
            >
                <Selection mode="single"/>
                <Pager
                    visible={true}
                    allowedPageSizes={"auto"}
                    showInfo={true}
                    showNavigationButtons={true}
                    infoText="Página {0} de {1} ({2} clientes)" />
                <Column dataField="ID" caption="ID" visible={false}/>
                <Column dataField="cliente" caption="Nombre Diazar" visible={false}/>
                <Column dataField="cliente_nombre_comercial" caption="Cliente" width='80%'/>
                <Column dataField="cotizaciones_pendientes" caption="Sin responder" />
                <Column dataField="cotizaciones_sin_leer" caption="Sin leer" />
            </DataGrid>
        </>
    )
}