import React from "react";
import { Login } from "../Pages/Login/Login";
import { NotFound } from "../Pages/NotFound/NotFound"
import { TermsOfUse } from "../Pages/InfoPages/TermsOfUse";
import {PrivacyPolicies} from "../Pages/InfoPages/PrivacyPolicies";
import {ClearPage} from "../Pages/ClearPage/ClearPage";
import {QuotesPage} from "../Pages/Quotes/QuotesPage";
import {QuoteDetailsPage} from "../Pages/Quotes/QuoteDetailsPage"
import {UserProfilePage} from "../Pages/Profile/UserProfilePage";
import {ResetPassword} from "../Pages/Login/ResetPassword";
import {ResetPasswordWithToken} from "../Pages/Login/ResetPasswordWithToken";
import {ValidateEmail} from "../Components/Access/ValidateEmail";
import {AddDiazarClient} from "../Pages/Clients/AddDiazarClient";
import {ProductsPage} from "../Pages/Products/ProductsPage";
import {ClientsPage} from "../Pages/Clients/ClientsPage";
import {QuotesPagePerClient} from "../Pages/Quotes/QuotesPagePerClient";

export interface RoutesType {
    path: string;
    component: React.ComponentType;
    isPrivate: boolean;
    children?: {
        path: string;
        component: React.ComponentType;
        isPrivate:boolean
    }[];
}

export const routes:RoutesType[] = [
    {
        path:'/login',
        component:Login,
        isPrivate: false
    },
    {
        path:'/reset-password',
        component:ResetPassword,
        isPrivate: false
    },
    {
        path:'/reset-password/:TOKEN',
        component:ResetPasswordWithToken,
        isPrivate: false
    },
    {
        path:'/validate-email',
        component: ValidateEmail,
        isPrivate: false
    },
    {
        path:'/validate-email/:TOKEN',
        component: ValidateEmail,
        isPrivate: false
    },
    {
        path:'/add-diazar-client/:TOKEN',
        component: AddDiazarClient,
        isPrivate: false
    },
    {
        path:'/register',
        component: Login,
        isPrivate: false
    },
    {
        path:'/',
        component:ClearPage,
        isPrivate: true,
    },
    {
        path:'/quotes',
        component:QuotesPage,
        isPrivate: true,
    },
    {
        path:'/quotes/:QUOTE_ID',
        component:QuoteDetailsPage,
        isPrivate: true,
    },
    {
        path:'/quotes-clients',
        component: QuotesPagePerClient,
        isPrivate: true
    },
    {
        path:'/Profile',
        component: UserProfilePage,
        isPrivate: true,
    },
    {
        path:'/products',
        component: ProductsPage,
        isPrivate: true
    },
    {
        path:'/clients',
        component: ClientsPage,
        isPrivate: true
    },
    {
        path:'/terms-of-use',
        component:TermsOfUse,
        isPrivate: false
    },
    {
        path:'/privacy-policies',
        component:PrivacyPolicies,
        isPrivate: false
    },
    {
        path:'/*',
        component:NotFound,
        isPrivate: false
    }
]
