import React from "react";
import {LoginDispacher, UserApp, Company} from "./AuthDomain";
import {Client} from "../../Services/ClientsServices";

let user:UserApp = localStorage.getItem("currentUser")
    ? JSON.parse( localStorage.getItem("currentUser") + "" ).usuario
    : undefined;

let token:string = localStorage.getItem("currentUser")
    ? JSON.parse( localStorage.getItem("currentUser") + "" ).token
    : "";

let empresa: Company = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")+"").empresa
    : ""

let clientes = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")+"").clientes
    : []

let cliente_seleccionado = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")+"").cliente_seleccionado
    : null

let totalClients = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")+"").totalClients
    : 0

let totalNotifications = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")+"").totalNotifications
    : 0

let totalUnreadNotifications = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")+"").totalUnreadNotifications
    : 0

export interface LoginPayload { //HNResponse
    usuario?: UserApp
    token?: string,
    empresa?: Company,
    clientes?: Client[],
    totalClientes?: number,
    totalNotificaciones?: number,
    totalNotificacionesSinLeer?: number,
    cliente_seleccionado?: Client,
    loading?:        boolean,
    errorMessage?:   string
}

export const initialState:LoginPayload = {
    usuario: user,
    token: token,
    empresa: empresa,
    clientes: clientes,
    cliente_seleccionado: cliente_seleccionado,
    totalClientes: totalClients,
    totalNotificaciones: totalNotifications,
    totalNotificacionesSinLeer: totalUnreadNotifications,
    loading:      false,
    errorMessage: undefined
};

export let AuthReducer: React.Reducer<LoginPayload, LoginDispacher> ;
AuthReducer = (initialState: LoginPayload, action: LoginDispacher): LoginPayload => {
    switch (action.type) {
        case "REQUEST_LOGIN":
            return {
                ...initialState,
                loading: true
            };

        case "LOGIN_SUCCESS":
            let copy : LoginPayload = {...initialState, loading: false, errorMessage:undefined}
            if (typeof action.payload !== 'undefined' && action.payload !== null) {
                if ('usuario' in action.payload) {
                    copy.usuario = action.payload.usuario;
                }
                if ('token' in action.payload) {
                    copy.token = action.payload.token;
                }
                if ('empresa' in action.payload) {
                    copy.empresa = action.payload.empresa;
                }
                if ('clientes' in action.payload) {
                    copy.clientes = action.payload.clientes;
                }
                if ('cliente_seleccionado' in action.payload) {
                    copy.cliente_seleccionado = action.payload.cliente_seleccionado;
                }
            }
            return copy;

        case "LOGOUT":
            return {
                usuario: undefined,
                token: "",
                cliente_seleccionado: undefined,
                clientes: [],
                empresa: undefined,
                loading: false,
                errorMessage:undefined
            };

        case "LOGIN_ERROR":
            return {
                ...initialState,
                loading: false,
                errorMessage: action.error
            };

        case "UPDATE_USER":
            let updateUser : LoginPayload = {...initialState, loading: false, errorMessage:undefined}

            if (action.payload !== undefined && action.payload !== null){
                if ('ID' in action.payload && 'CreatedAt' in action.payload && 'empresa_id' in action.payload){
                    updateUser.usuario = action.payload
                }else{
                    console.error('The payload is not of type UserApp');
                }
            }
            return updateUser;

        case "UPDATE_COMPANY":
            let updateCompany: LoginPayload = {...initialState, loading: false, errorMessage: undefined}
            if (action.payload !== undefined && 'rfc' in action.payload) {
                updateCompany.empresa = action.payload;
            } else {
                console.error('The payload is not of type Company');
            }
            return updateCompany;

            case "UPDATE_CLIENTS":
                let updateCleintes: LoginPayload = {...initialState,  loading: false, errorMessage: undefined}
                if (action.payload){
                    if ('clientes' in action.payload) {
                        updateCleintes.clientes = action.payload.clientes;
                    }

                    if ('totalClientes' in action.payload) {
                        updateCleintes.totalClientes = action.payload.totalClientes;
                    }
                    if ('totalNotificaciones' in action.payload) {
                        updateCleintes.totalNotificaciones = action.payload.totalNotificaciones;
                    }
                    if ('totalNotificacionesSinLeer' in action.payload) {
                        updateCleintes.totalNotificacionesSinLeer = action.payload.totalNotificacionesSinLeer;
                    }
                }
                return updateCleintes;

        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
