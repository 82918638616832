import React from 'react';
import {DropDownOptions, Lookup} from "devextreme-react/lookup";
import {Client, appUseClient} from "../Services/ClientsServices"
import {useAuthDispatch, useAuthState} from "../Context/AuthProvider/AuthProvider";
import notify from "devextreme/ui/notify";
import {Button} from "devextreme-react/button";
import {Badge, Flex, FlexItem} from "@patternfly/react-core";


/**
 * Represents a component for looking up and selecting clients.
 *
 * @returns {ReactElement} - A React element representing the client lookup component.
 */
export const ClientsLookup = (): React.ReactElement => {
    const [items, setItems] = React.useState<Client[] | null>(null);
    const {token, cliente_seleccionado} = useAuthState();
    const dispatch = useAuthDispatch();
    const [value, setValue] = React.useState<number | undefined>(undefined);
    const [client, setClient] = React.useState<Client>();

    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

    const user = useAuthState()

    const handlerClient = React.useCallback((item: any) => {
        setClient(cliente_seleccionado);
        setValue(item.itemData.ID)
    }, [cliente_seleccionado]);

    /**
     * Renders the itemComponent for displaying information about an item.
     *
     * @param {Object} item - The item object containing the data to be displayed.
     * @param {number} index - The index of the item in the list.
     * @returns {ReactElement} - A React element representing the itemComponent.
     */
    const itemComponent = (item: Client, index: number): React.ReactElement => {
        const colorPendientes = (item.cotizaciones_pendientes > 0) ? '#ff8d0a' : '#ccc';
        const colorSinLeer = (item.cotizaciones_pendientes > 0) ? '#790015' : '#ccc';
        return (
            <div>
                <h1 style={{fontSize:'18px'}}>{item.cliente_nombre_comercial ? item.cliente_nombre_comercial : item.cliente}</h1>
                <Flex>
                    <FlexItem>Cotizaciones:</FlexItem>
                    <FlexItem align={{ default: 'alignRight' }}>
                        <>
                            <Badge key={2} style={{backgroundColor: colorSinLeer}} id={`badgeCompanySLeer${index}`}>
                                {item.cotizaciones_sin_leer}
                            </Badge>
                        </>

                        &nbsp;/&nbsp;

                        <>
                            <Badge key={1} style={{backgroundColor: colorPendientes}} id={`badgeCompanyCPendientes${index}`}>
                                {item.cotizaciones_pendientes}
                            </Badge>

                        </>
                    </FlexItem>

                </Flex>
            </div>
        )
    }

    React.useMemo(() => {
        if (value !== undefined && cliente_seleccionado?.ID !== value) {
            appUseClient(value + "", dispatch, token+'')
                .then((data) => {
                    if (data.status === "SUCCESS") {
                        setClient(data.response.cliente_seleccionado);
                        notify({
                                message: "Cliente seleccionado",
                                height: 50,
                                width: 150,
                                type: "success",
                                displayTime: 3500,
                                animation: {
                                    show: {
                                        type: 'fade', duration: 400, from: 0, to: 1,
                                    },
                                    hide: {type: 'fade', duration: 40, to: 0},
                                },
                            },
                            {
                                position: "top right",
                                direction: "down-push"
                            });
                    } else {
                        notify({
                                message: data.error.error,
                                height: 50,
                                width: 150,
                                type: "error",
                                displayTime: 3500,
                                animation: {
                                    show: {
                                        type: 'fade', duration: 400, from: 0, to: 1,
                                    },
                                    hide: {type: 'fade', duration: 40, to: 0},
                                },
                            },
                            {
                                position: "top right",
                                direction: "down-push"
                            });
                    }
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    React.useEffect(() => {
        if (user.clientes !== undefined) {
            setItems(user.clientes);
        }else{
            setItems([])
        }
    }, [user]);

    React.useEffect(() => {
        if (cliente_seleccionado !== null) {
            setValue(cliente_seleccionado?.ID);
            setClient(cliente_seleccionado);
        }
    }, [cliente_seleccionado]);

    return (
        (client === undefined) ?
            <Lookup
                placeholder="Selecciona a tu cliente"
                dataSource={items}
                itemRender={itemComponent}
                valueExpr="ID"
                onItemClick={handlerClient}
                displayExpr={cliente_seleccionado?.cliente_nombre_comercial ? cliente_seleccionado?.cliente_nombre_comercial : cliente_seleccionado?.cliente }
                wrapItemText={true}
                searchMode="contains"
                value={value}
                id="select-clients"
                onOpened={() => setIsDropdownOpen(true)}
                onClosed={() => {
                    setClient(cliente_seleccionado);
                    setIsDropdownOpen(false)}
                }
                opened={isDropdownOpen}
            >
                <DropDownOptions
                    hideOnOutsideClick={true}
                    showTitle={false}
                />
            </Lookup>
            :
            <Button
                id="select-clients-btn"
                text={client?.cliente_nombre_comercial ? client?.cliente_nombre_comercial : client?.cliente}
                onClick={() => {
                    setClient(undefined);
                    setIsDropdownOpen(true);
                }}
                type="normal"
                stylingMode="outlined"
            />
    )
}
