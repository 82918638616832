import React from 'react';
import {LoginLayout} from "../../Layouts/LoginLayout";
import {ResetPasswordForm} from "../../Components/ResetPasswordForm";
import { Button } from 'devextreme-react/button';
import UsersServices from "../../Services/UsersServices";
import notify from "devextreme/ui/notify";

export const ResetPassword = () => {
    const [sendEmail, setSendEmail] = React.useState<boolean>(false);
    const [email, setEmail] = React.useState<string>('');
    const [timer, setTimer] = React.useState<number>(120);

    const resendEmail = () => {
        UsersServices.resetPassword(email)
            .then((resp) => {
                if (resp.status === "SUCCESS") {
                    setTimer(60);
                } else {
                    notify({
                            message: resp.error.error,
                            height: 50,
                            width: 150,
                            type: "error",
                            displayTime: 3500,
                            animation: {
                                show: {
                                    type: 'fade', duration: 400, from: 0, to: 1,
                                },
                                hide: { type: 'fade', duration: 40, to: 0 },
                            },
                        } ,
                        {
                            position : "top right",
                            direction: "down-push"
                        });
                }
            })
    }

    React.useEffect(() => {
        if (sendEmail) {
            setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }
    }, [sendEmail])

    return (
        <LoginLayout loginTitle={sendEmail ? "Revisa tu bandeja de correo electrónico" : "Restablecer Contraseña"}
                     loginSubtitle={sendEmail ? "Se ha enviado un mensaje a tu correo electrónico con las instrucciones para recuperar su contraseña" : "¡Calma! te ayudo a restablecer tu contraseña, solo indicame tu correo electrónico registrado. Por favor"}
        >
            {
                sendEmail ?
                    <div style={{display: "flex", justifyContent: 'center'}}>
                        {timer > 0 ?
                            <p>{timer}</p>:
                            <Button
                            text="Reenviar mensaje"
                            stylingMode="text"
                            type="default"
                            onClick={resendEmail}
                        />
                        }
                    </div>:
                    <ResetPasswordForm sendEmail={setSendEmail} email={setEmail}/>
            }
        </LoginLayout>
    )
}