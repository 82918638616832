import React from "react";
import {
    Bullseye,
    Nav,
    NavGroup, NavItem,
    PageSidebar,
    Spinner, PageSidebarBody, NavExpandable, Flex, FlexItem, Badge
} from "@patternfly/react-core";
import {useAuthDispatch, useAuthState} from "../../Context/AuthProvider/AuthProvider";
import {useLocation, useNavigate} from "react-router-dom";
import {appUseClient, getAllClients} from "../../Services/ClientsServices";
import notify from "devextreme/ui/notify";
import {logout} from "../../Context/AuthProvider/AuthProviderActions";


const DiazarNav: React.FunctionComponent = (_props) => {
    const { loading } = useAuthState()
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [ activeItem, setActiveItem ] = React.useState<string>('/')

    const [timer, setTimer] = React.useState<number>(0)

    const dispatch = useAuthDispatch();
    const user = useAuthState()

    const {token, cliente_seleccionado} = useAuthState();

    React.useEffect( ()=>{
        setActiveItem( pathname )
    }, [ pathname ] )

    React.useEffect( () => {
        if (user.token){
            getAllClients(user.token, dispatch).then( (data) => {
                if (!data.ok && typeof data.data === 'string'){
                    notify({
                            message: data.data,
                            height: 50,
                            width: 150,
                            type: "error",
                            displayTime: 3500,
                            animation: {
                                show: {
                                    type: 'fade', duration: 400, from: 0, to: 1,
                                },
                                hide: {type: 'fade', duration: 40, to: 0},
                            },
                        },
                        {
                            position: "top right",
                            direction: "down-push"
                        });
                    if (data.data === 'Token no válido') {
                        logout(dispatch);
                    }
                }
            })
        }

        setTimeout(()=>{
            setTimer(timer + 1)
        }, 10000)
    }, [dispatch, timer, user.token])

    const clietClickHandler = React.useCallback( (clienteID) => {
        if (clienteID !== cliente_seleccionado?.ID) {
            appUseClient(clienteID, dispatch, token + '').then((data) => {
                if (data.status === "SUCCESS") {
                    notify({
                            message: "Cliente seleccionado",
                            type: "success",
                            displayTime: 3500,
                            animation: {
                                show: {
                                    type: 'fade', duration: 400, from: 0, to: 1,
                                },
                                hide: {type: 'fade', duration: 40, to: 0},
                            },
                        },
                        {
                            position: "top right",
                            direction: "down-push"
                        });
                } else {
                    notify({
                            message: data.error.error,
                            type: "error",
                            displayTime: 3500,
                            animation: {
                                show: {
                                    type: 'fade', duration: 400, from: 0, to: 1,
                                },
                                hide: {type: 'fade', duration: 40, to: 0},
                            },
                        },
                        {
                            position: "top right",
                            direction: "down-push"
                        });
                }
            })
        }
    }, [cliente_seleccionado?.ID, dispatch, token] )

    const ClientesNav = React.useMemo( () => {
        const clientes = user.clientes

        if( clientes === undefined || clientes.length === 0 ){
            return null
        }

        return clientes.map( (cliente, index) => {
            const isActiveClient =  user.cliente_seleccionado !== null && user.cliente_seleccionado !== undefined && user.cliente_seleccionado.ID === cliente.ID

            const colorPendientes = (cliente.cotizaciones_pendientes > 0) ? '#ff8d0a' : '#ccc';
            const colorSinLeer = (cliente.cotizaciones_pendientes > 0) ? '#790015' : '#ccc';


            return (
                <NavExpandable
                    title={ cliente.cliente_nombre_comercial ? cliente.cliente_nombre_comercial : cliente.cliente }
                    isActive={ isActiveClient }
                    key={index}
                    isExpanded={isActiveClient}
                    onClick={() => clietClickHandler(cliente.ID)}
                    /*onExpand={ () => clietClickHandler(cliente.ID) }*/
                >
                    { (isActiveClient) ?
                        <NavItem
                            onClick={() => navigate(`/quotes`)}
                            itemId="quotes"
                            isActive={activeItem === '/quotes'}
                        >
                            <Flex>
                                <FlexItem>Cotizaciones</FlexItem>
                                <FlexItem align={{ default: 'alignRight' }}>
                                    <Badge style={{background: colorSinLeer}} key={1}>
                                        {cliente.cotizaciones_sin_leer}
                                    </Badge>
                                    &nbsp;/&nbsp;
                                    <Badge style={{background: colorPendientes}} key={2}>
                                        {cliente.cotizaciones_pendientes}
                                    </Badge>
                                </FlexItem>
                            </Flex>

                        </NavItem> : null
                    }

                </NavExpandable>
            )
        } )

    }, [user.clientes, user.cliente_seleccionado, activeItem, clietClickHandler, navigate] )

    if( loading ){
        return  (
            <Bullseye style={ {marginTop: '20px'} }>
                <Spinner aria-label="Cargando Información"/>
            </Bullseye>
        )
    }

    const clientClass = ( activeItem === '/clients' ) ? 'pf-m-clicleable active' : 'pf-m-clicleable';

    return (
        <Nav aria-label="Nav" key="diazar-nav">
            <NavGroup aria-label="Cotizaciones">
                <NavItem
                    onClick={() => navigate("/")}
                    itemId="home"
                    isActive={activeItem === '/'}
                >
                    Inicio
                </NavItem>
                <NavItem
                    onClick={() => navigate("/products")}
                    itemId="products"
                    isActive={activeItem === '/products'}
                >
                    Catálogo de productos
                </NavItem>
                <NavItem
                    onClick={() => navigate("/quotes-clients")}
                    itemId="cotxcliente"
                    isActive={activeItem === '/quotes' || activeItem === '/quotes-clients'}
                >
                    Cotizaciones por cliente
                </NavItem>
            </NavGroup>

            {/*<NavGroup title={ "Clientes" }
                      aria-label="Clientes"
                      className={ clientClass }
                      onClick={
                            (e) => {
                                if( e.target  ){
                                    const target = e.target as HTMLElement
                                    if (  target.getAttribute( 'class' ) === 'pf-v5-c-nav__section-title' ){
                                        navigate("/clients")
                                    }
                                }
                            }
                      }
            >
                { ClientesNav }
            </NavGroup>*/}
        </Nav>
    )
}

export const DiazarSideBar: React.FunctionComponent = (_props) => {
    const PageNav = <DiazarNav />
    return (
        <PageSidebar>
            <PageSidebarBody>
                {PageNav}
            </PageSidebarBody>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            </div>
        </PageSidebar>
    )
}
