import React from 'react';
import { LabelGroup, Label, Menu, MenuContent, MenuList, MenuItem, Popper } from '@patternfly/react-core';
import { useAuthState } from "../Context/AuthProvider/AuthProvider";
import categoryServices from "../Services/CategoryServices";
import notify from "devextreme/ui/notify";

interface DiazarBusinessCategoriesTagsProps {
    actionCategories: React.Dispatch<React.SetStateAction<any[]>>;
}

export const DiazarBusinessCategoriesTags: React.FunctionComponent<DiazarBusinessCategoriesTagsProps> = (props) => {
    const toggleRef = React.useRef<HTMLDivElement>();
    const menuRef = React.useRef<HTMLDivElement>();
    const containerRef = React.useRef<HTMLDivElement>();

    const { empresa , token} = useAuthState();

    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [labels, setLabels] = React.useState<any>([]);
    const [catCategories, setCatCategories] = React.useState<any>([]);

    const onClose = React.useCallback((labelId: string) => {
        const newLabels = labels.filter((l: any) => l.ID !== labelId);
        props.actionCategories(newLabels);
        setLabels(newLabels);
    }, [labels, props]);

    const onEdit = React.useCallback((nextText: string, index: number) => {
        const copy = [...labels];
        const updatedProps = {
            ...labels[index].props,
            editableProps: { 'aria-label': `An editable label with text ${nextText}` }
        };

        copy[index] = { name: nextText, props: updatedProps, id: labels[index].id };
        setLabels(copy);
    }, [labels]);

    const onAdd = React.useCallback((labelText: string) => {
        if (labels  && (labels.find((label: any) => label.clave === labelText))) {
            notify({
                    message: "La categoría ya fue agregada",
                    type: "warning",
                    displayTime: 5000,
                    animation: {
                        show: {
                            type: 'fade', duration: 400, from: 0, to: 1,
                        },
                        hide: {type: 'fade', duration: 40, to: 0},
                    },
                },
                {
                    position: "top right",
                    direction: "down-push"
            })
            return;
        }
        const index = catCategories.find((cat: any) => cat.clave === labelText);
        const newLabels = [
            {
                clave: labelText,
                ID: index.ID
            },
            ...labels
        ];
        setLabels(newLabels);
        setIsOpen(!isOpen);
        props.actionCategories(newLabels);
    }, [labels, catCategories, isOpen, props]);

    const handleMenuKeys = React.useCallback((event: KeyboardEvent) => {
        if (isOpen && menuRef.current?.contains(event.target as Node)) {
            if (event.key === 'Escape' || event.key === 'Tab') {
                setIsOpen(!isOpen);
                toggleRef.current?.focus();
            }
        }
    }, [isOpen, menuRef, toggleRef]);

    const handleClickOutside = React.useCallback((event: MouseEvent) => {
        if (
            isOpen &&
            !(menuRef.current?.contains(event.target as Node) || toggleRef.current?.contains(event.target as Node))
        ) {
            setIsOpen(false);
        }
    }, [isOpen, menuRef, toggleRef]);

    const onToggleClick = React.useCallback((event: React.MouseEvent) => {
        event.preventDefault();
        setTimeout(() => {
            if (menuRef.current) {
                const firstElement = menuRef.current.querySelector('li > button:not(:disabled)');
                firstElement && (firstElement as HTMLElement).focus();
            }
        }, 0);
        setIsOpen(!isOpen);
    }, [menuRef, isOpen]);

    // @ts-ignore
    const menu = (
        // @ts-ignore
        <Menu ref={menuRef} onSelect={(_ev, itemId) => onAdd(itemId.toString())}>
            <MenuContent>
                <MenuList>
                    {catCategories
                        ? catCategories.map((cat: any) => <MenuItem key={cat.ID} itemId={cat.clave} >{cat.clave}</MenuItem>)
                        : null
                    }
                </MenuList>
            </MenuContent>
        </Menu>
    );

    const toggle = (
        // @ts-ignore
        <div ref={toggleRef}>
            <Label color="blue" variant="outline" isOverflowLabel onClick={onToggleClick}>
                Agregar
            </Label>
        </div>
    );

    const FnLabelGroup = React.useMemo(() => {
        if (labels === null) {
            return null;
        } else {
        return (
            labels.map((label, index) => (
                <Label
                    key={label.ID}
                    id={label.ID}
                    color="blue"
                    onClose={() => onClose(label.ID)}
                    onEditCancel={(_event, prevText) => onEdit(prevText, index)}
                    onEditComplete={(_event, newText) => onEdit(newText, index)}
                    {...label.props}
                >
                    {label.clave}
                </Label>
            ))
        )
            }
    }, [labels, onEdit, onClose]);

    // se encarga de hacer el fetch de las categorias y setearlas en el estado de labels
    React.useEffect(() => {
        const fetchCategories = async () => {
            const response = await categoryServices.getAllCategories(token+'');
            if (response.status) {
                setCatCategories(response.response);
            } else {
                notify({
                        message: response.response,
                        height: 50,
                        width: 150,
                        type: "error",
                        displayTime: 5000,
                        animation: {
                            show: {
                                type: 'fade', duration: 400, from: 0, to: 1,
                            },
                            hide: {type: 'fade', duration: 40, to: 0},
                        },
                    },
                    {
                        position: "top right",
                        direction: "down-push"
                    });
            }
        }
        fetchCategories();
    }, [token]);

    React.useEffect(() => {
        window.addEventListener('keydown', handleMenuKeys);
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('keydown', handleMenuKeys);
            window.removeEventListener('click', handleClickOutside);
        };
    }, [handleClickOutside, handleMenuKeys, isOpen, menuRef]);

    React.useEffect(() => {
        setLabels(empresa?.categorias);
    }, [empresa]);

    return (
        // @ts-ignore
        <div ref={containerRef}>
            <LabelGroup
                categoryName="Categorias: "
                numLabels={5}
                isEditable
                addLabelControl={
                    <Popper trigger={toggle} triggerRef={toggleRef} popper={menu} popperRef={menuRef} isVisible={isOpen} />
                }
            >
                {FnLabelGroup}
            </LabelGroup>
        </div>
    );
};
