import React from 'react';
import '@patternfly/react-core/dist/styles/base.css'
import 'devextreme/dist/css/dx.light.css';
import './css/dx.generic.diazar-scheme.css';
import './App.css';
import './change-colors.css';
import './custom.css';
import {
    BrowserRouter as Router,
    Routes, Route, Navigate
} from 'react-router-dom';
import {routes} from './Config/routes'
import {AuthProvider, useAuthState} from "./Context/AuthProvider/AuthProvider";
import Moment from "react-moment";
import 'moment/locale/es';
import {AlertProvider} from "./Context/AlertProvider/AlertProvider";
import {DiazarSuiteProvider} from "./Context/DiazarSuiteProvider/DiazarSuiteProvider";
import {CookiesProvider} from "react-cookie";
import { ClientContextProvider } from 'react-fetching-library';
import {Client} from "./Config/Client";
import deMessages from "devextreme/localization/messages/es.json"
import { locale, loadMessages } from "devextreme/localization";
import config from 'devextreme/core/config';
import { licenseKey } from './devextreme-license';

Moment.globalLocale = 'es';

loadMessages(deMessages);
locale('es-MX');
config({ defaultCurrency: 'MXN', licenseKey })

const AppRoutes:React.FunctionComponent = (_props) => {
    const user = useAuthState()

    return (
        <Router>
            <Routes>
                { routes.map( (props ) =>
                    <Route
                        key={props.path}
                        path={props.path}
                        element={( !props.isPrivate || Boolean(user.token) ) ? <props.component /> : <Navigate to="/login" replace />}
                    >
                        {props.children ? props.children.map(( el ) => {
                            return(
                                <Route
                                    key={el.path}
                                    path={el.path}
                                    element={(!el.isPrivate || Boolean(user.token) ) ? <el.component /> : <Navigate to="/login" replace />} />
                            )
                        }) : <></> }
                    </Route>
                ) }
            </Routes>
        </Router>
    )
}

export const App: React.FunctionComponent = (_props) => {
    React.useEffect( ()=>{
        const el = document.querySelector(".loader-container");
        if (el) {
            el.remove();
        }
    }, [] )

    return (
        <CookiesProvider>
            <AuthProvider>
                <DiazarSuiteProvider>
                    <AlertProvider>
                        <ClientContextProvider client={Client}>
                            <AppRoutes />
                        </ClientContextProvider>
                    </AlertProvider>
                </DiazarSuiteProvider>
            </AuthProvider>
        </CookiesProvider>
    );
}
