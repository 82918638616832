import { ServicesUrl } from "../Config/UrlRuotes";

const getCompany = async (id: string, token: string) => {
    const response = await fetch(`${ServicesUrl.users}/${id}/company`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    const responseJson = await response.json();

    if (responseJson.status === "SUCCESS") {
        return {
            status: true,
            response: responseJson.response
        }
    } else {
        return {
            status: false,
            response: responseJson.error.error
        }
    }
}

const updateCompany = async (datas: object, id: string, token: string) => {
    const response = await fetch(`${ServicesUrl.users}/${id}/company`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(datas)
    });

    const responseJson = await response.json();

    if (responseJson.status === "SUCCESS") {
        return {
            status: true,
            response: responseJson
        }
    } else {
        return {
            status: false,
            response: responseJson.error.error
        }
    }
}

const exportedObjects = {updateCompany, getCompany};
export default exportedObjects;