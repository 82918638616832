import { ServicesUrl } from "../Config/UrlRuotes";

export interface IQuote {
    "solicitud_cotizacion_id": number;
    "tipo_solicitud": number;
    "enviada": boolean;
    "recibida": boolean;
    "fecha_envio": string;
    "fecha_recibida": string;
    "nota_de_envio": string;
    "nota_de_respuesta": string;
    "fecha_vigencia": string;
    "pct_desc": number;
    "vendedor": string;
    "condiciones_de_pago": string;
    "cotizacion_id": number;
    "folio": number;
    "fecha": string;
    "observacion": string;
    "proyecto_id": number;
    "proyecto": string;
    "elabora_id": number;
    "elabora": string;
    items: ItemQuote[];
}

export interface ItemQuote {
    "item_cotizacion_id": number;
    "observaciones": string;
    "cantidad": number;
    "fecha_de_entrega": string;
    "item_sol_cotizacion_id": number;
    "solicitud_cotizacion_id": number;
    "fecha_de_entrega_proveedor": string;
    "cantidad_disponible": number;
    "pu_prov": number;
    "observaciones_proveedor": string;
    "descuento": number;
    "cuenta_id": number;
    "cuenta": string;
    "recurso_id": number;
    "recurso_clave": string;
    "recurso_descripcion": string;
    "unidad": string;
    "precio_sugerido": number;
    "recurso_catalogo_id": number;
}

export interface ResponseItemsQuotes extends IQuote {
    items: ItemQuote[];
    elabora_id: number;
    elabora: string;
}

export interface Error {
    "has-error": false;
    "error"?: string;
}

export interface Response {
    "limit": number;
    "page": number;
    "total": number;
    "rows": IQuote[];
}

export interface ResponseReqItems {
    "status": string;
    "error": Error;
    "response": ResponseItemsQuotes
}

export interface QuotesResponse {
    "status": string;
    "error": Error;
    "response": Response;
}

/**
 * Retrieves all quotes from the API based on the provided page number.
 *
 * @param {number} page - The page number to retrieve quotes from.
 * @returns {Promise<Array<Quote>>} - A promise that resolves with an array of quotes.
 * @throws {Error} - If there's an error with the API call.
 */
const getAllQuotes = async ( page: number, token: string): Promise<Response | string> => {
    const LIMIT = 500
    const response = await fetch(`${ServicesUrl.api}/quotes?limit=${LIMIT}&page=${page}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

    const responseJson: QuotesResponse = await response.json();

    if (responseJson.status === "SUCCESS") {
        return responseJson.response;
    } else {
        console.log(response)
        return responseJson.error.error + '';
    }
}

/**
 * Retrieves a quote by its ID solicitud.
 * @param {string} id_request - The ID solicitud of the quote to retrieve.
 * @returns {Promise} - Returns a promise that resolves to the quote data if successful, or throws an error if unsuccessful.
 * @throws {Error} - Throws an error if the quote data cannot be loaded.
 */
const getQuoteByIdSolicitud = async (id_request: string, token: string): Promise<ResponseItemsQuotes> => {
    const consult = await fetch(`${ServicesUrl.api}/quotes/${id_request}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    const response: ResponseReqItems = await consult.json();

    if (response.status === "SUCCESS") {
        return response.response;
    } else {
        throw new Error('No se pudo cargar los datos de la cotización. ' + response.error.error);
    }
}

const putQuoteDataByIdRequest = async (id_request: string, property: string, value: string | Date | number | boolean, token: string) => {
    if (property === 'pct_desc') {
        value = Number(value);
    }

    const consult = await fetch(`${ServicesUrl.api}/quotes/${id_request}`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
            [property]: value
        })
    });

    const response: ResponseReqItems = await consult.json();

    if (response.status === "SUCCESS") {
        return {
            status: true,
            response: ''
        };
    } else {
        return {
            status: false,
            response: response.error.error
        };
    }
}

const putItemByIdRequest = async (id_request, id_item, item, token) => {
    const consult = await fetch(`${ServicesUrl.api}/quotes/${id_request}/items/${id_item}`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(item)
    });

    const response = await consult.json();

    if (response.status === "SUCCESS") {
        return true;
    } else {
        throw new Error("No se pudo actualizar el item de la cotización");
    }
}

const exportedObjects = {getAllQuotes, getQuoteByIdSolicitud, putQuoteDataByIdRequest, putItemByIdRequest};
export default exportedObjects;