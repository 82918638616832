import React from 'react';
import {
    Avatar,
    Divider,
    Grid, GridItem
} from "@patternfly/react-core";
// import TextBox from "devextreme-react/text-box";
// import {Button} from "devextreme-react/button";
import { LoadPanel } from 'devextreme-react/load-panel';
// import Validator, {RequiredRule} from "devextreme-react/validator";
import { useAuthState } from "../Context/AuthProvider/AuthProvider";
import {useAuthDispatch} from "../Context/AuthProvider/AuthProvider";
import {updateUser} from "../Context/AuthProvider/AuthProviderActions";
import UsersServices from "../Services/UsersServices";
import notify from "devextreme/ui/notify";
// import ValidationGroup from 'devextreme-react/cjs/validation-group';
// import {PasswordModal} from "./PasswordModal";
import {UploadImageModal} from "./UploadImageModal";
import imgAvatar from '@patternfly/react-styles/css/assets/images/img_avatar.svg';
import {FormHelperText, Form, Button, ActionGroup} from "@patternfly/react-core";
import PencilAltIcon from '@patternfly/react-icons/dist/esm/icons/pencil-alt-icon';
import UploadIcon from '@patternfly/react-icons/dist/esm/icons/upload-icon'
import {ExclamationCircleIcon} from "@patternfly/react-icons";
import {DiazarFormInput} from "./Form/DiazarFormInput";

export const FormProfile = () => {
    const {usuario, token} = useAuthState();
    // const [status, setStatus] = React.useState<boolean>(true);
    // const [textButton, setTextButton] = React.useState<string>('Editar datos');
    // const [iconButton, setIconButton] = React.useState<string>('edit');
    // const [loading2, setLoading2] = React.useState<boolean>(false);
    const [name, setName] = React.useState<string>(usuario?.nombres || '');
    const [isValidName, setIsValidName] = React.useState<boolean>(false);
    const dataName = usuario?.nombres;
    const [lastname, setLastname] = React.useState<string>(usuario?.apellidos || '');
    const [isValidLastname, setIsValidLastname] = React.useState<boolean>(false);
    const dataLastname = usuario?.apellidos;
    const [email, setEmail] = React.useState<string>(usuario?.email || '');
    const [isValidEmail, setIsValidEmail] = React.useState<boolean>(false);
    const dataEmail = usuario?.email;
    // const [passwordModal, setPasswordModal] = React.useState<boolean>(false);
    const [uploadImageModal, setUploadImageModal] = React.useState<boolean>(false);
    const dispatch = useAuthDispatch()
    const { loading, errorMessage } = useAuthState();

    const handleEdit = (e) => {
        // const rules = e.validationGroup.validate();

        /*if (status) {
            setStatus(false);
            setTextButton('Guardar cambios');
            setIconButton('save');
        } else {
            setLoading2(true);*/
            // if (rules.isValid) {
                const id = usuario?.ID+''
                try {
                    UsersServices.updateUser({nombres: name, apellidos: lastname}, id, token+'')
                        .then((resp) => {
                            if (resp.status === "SUCCESS") {
                                updateUser(resp.response, dispatch)
                                //setStatus(true);
                                //setTextButton('Editar datos');
                                //setIconButton('edit');
                                notify({
                                        message: 'Actualizado con éxito',
                                        height: 50,
                                        width: 150,
                                        type: "success",
                                        displayTime: 3500,
                                        animation: {
                                            show: {
                                                type: 'fade', duration: 400, from: 0, to: 1,
                                            },
                                            hide: {type: 'fade', duration: 40, to: 0},
                                        },
                                    },
                                    {
                                        position: "top right",
                                        direction: "down-push"
                                    })
                            } else {
                                notify({
                                        message: 'resp.error.error',
                                        height: 50,
                                        width: 150,
                                        type: "error",
                                        displayTime: 3500,
                                        animation: {
                                            show: {
                                                type: 'fade', duration: 400, from: 0, to: 1,
                                            },
                                            hide: {type: 'fade', duration: 40, to: 0},
                                        },
                                    },
                                    {
                                        position: "top right",
                                        direction: "down-push"
                                    })
                            }
                            // setLoading2(false);
                        })
                } catch {
                    notify({
                            message: 'Algo salio mal con la operación',
                            height: 50,
                            width: 150,
                            type: "error",
                            displayTime: 3500,
                            animation: {
                                show: {
                                    type: 'fade', duration: 400, from: 0, to: 1,
                                },
                                hide: { type: 'fade', duration: 40, to: 0 },
                            },
                        } ,
                        {
                            position : "top right",
                            direction: "down-push"
                        });
                    // setLoading2(false);
                }
            /*} else {
                notify({
                        message: 'Por favor, revisa tus datos.',
                        height: 50,
                        width: 150,
                        type: "warning",
                        displayTime: 3500,
                        animation: {
                            show: {
                                type: 'fade', duration: 400, from: 0, to: 1,
                            },
                            hide: { type: 'fade', duration: 40, to: 0 },
                        },
                    } ,
                    {
                        position : "top right",
                        direction: "down-push"
                    })
                setLoading2(false);
            }*/
        // }
    }

    /*const valueChanged = (e) => {
        switch (e.element.id) {
            case "name":
                setName(e.value);
                break;
            case "lastname":
                setLastname(e.value)
                break;
            case "email":
                setEmail(e.value);
                break;
        }
    }*/

    const dateFormat = (date: Date) => date.toLocaleDateString('es-MX', { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit'}).replace(/\//g, '-');

    const handleUpload = () => {
        setUploadImageModal(true)
    }

    const isValidForm = React.useMemo<boolean>( () => {
        const isLoading = ( loading !== undefined ) ? loading : false
        if (!isLoading && isValidName && isValidLastname && isValidEmail) {
            return name !== dataName || lastname !== dataLastname || email !== dataEmail;
        } else {
            return false
        }

    }, [ isValidName, isValidLastname, loading, isValidEmail, name, dataName, lastname, dataLastname, email, dataEmail] )

    const helperText = React.useMemo( () => {
        if ( errorMessage === undefined ){
            return null
        }else{
            return (
                <FormHelperText  >
                    <ExclamationCircleIcon />
                    &nbsp;{errorMessage}.
                </FormHelperText>
            )
        }
    }, [errorMessage] )

    return (
        <>
            <Form>
                {helperText}
                <Grid hasGutter id="profile" style={{padding: "20px"}} >
                    <GridItem md={4} mdOffset={4} sm={12} style={{display:"flex", justifyContent:"center"}}>
                        <Avatar src={usuario?.imagen || imgAvatar} alt="avatar" size="xl" />
                    </GridItem>
                    <GridItem md={4} mdOffset={4} sm={12} style={{display:"flex", justifyContent:"center"}}>
                        <Button
                            variant="link"
                            onClick={handleUpload}
                            icon={usuario?.imagen ? <PencilAltIcon /> : <UploadIcon />}
                        >
                            {usuario?.imagen ? "Cambiar foto" : "Cargar foto"}
                        </Button>
                    </GridItem>
                    <GridItem md={4} mdOffset={4} sm={12}>
                        <DiazarFormInput id="nameUser" label="Nombre" isRequired
                                         type="text"
                                         placeholder="Ingresa tu nombre"
                                         initialValue={name}
                                         getState={(isValid, value) => {
                                             setIsValidName(isValid)
                                             setName(value)
                                         }}
                                         min={4}
                        />
                    </GridItem>
                    <GridItem md={4} mdOffset={4} sm={12}>
                        <DiazarFormInput id="lastname" label="Apellido" isRequired
                                         type="text"
                                         placeholder="Ingresa tu apellido"
                                         initialValue={lastname}
                                         getState={(isValid, value) => {
                                             setIsValidLastname(isValid)
                                             setLastname(value)
                                         }}
                                         min={2}
                        />
                    </GridItem>
                    <GridItem md={4} mdOffset={4} sm={12}>
                        <DiazarFormInput id="email" label="Correo Electrónico" isRequired
                                         type="email"
                                         placeholder="Ingresa tu correo electrónico"
                                         initialValue={email}
                                         getState={(isValid, value) => {
                                             setIsValidEmail(isValid)
                                             setEmail(value)
                                         }}
                        />
                    </GridItem>
                    <GridItem md={4} mdOffset={4} sm={12} style={{display:"flex", justifyContent:"center"}}>
                        <ActionGroup>
                            <Button
                                type="submit"
                                variant="primary"
                                onClick={ e => { e.preventDefault(); handleEdit(e) }}
                                isDisabled={ !isValidForm }
                            >
                                Guardar cambios
                            </Button>
                        </ActionGroup>
                    </GridItem>
                    <Divider />
                    <GridItem span={12} style={{textAlign: "right", fontSize: '12px'}}>
                        <p>creado: {dateFormat( new Date(usuario?.CreatedAt || ''))} hrs</p>
                        <p>actualizado: {dateFormat( new Date(usuario?.UpdatedAt || ''))} hrs</p>
                    </GridItem>
                </Grid>
            </Form>
            {/*<ValidationGroup >
                <Grid hasGutter id="datas" style={{padding: "20px"}} >
                    <GridItem md={4} mdOffset={4} sm={12} style={{display:"flex", justifyContent:"center"}}>
                        <Avatar src={usuario?.imagen || imgAvatar} alt="avatar" size="xl" />
                        <Button
                            text={usuario?.imagen ? "Cambiar" : "Cargar"}
                            onClick={handleUpload}
                        />
                    </GridItem>
                    <GridItem md={4} mdOffset={4} sm={12}>
                        <TextBox
                            id="name"
                            label="Nombres"
                            labelMode="floating"
                            mode="text"
                            readOnly={status}
                            value={name}
                            valueChangeEvent='input'
                            onValueChanged={valueChanged}
                        >
                            <Validator name="name">
                                <RequiredRule message="Necesito saber tu nombre"/>
                            </Validator>
                        </TextBox>
                    </GridItem>
                    <GridItem md={4} mdOffset={4} sm={12}>
                        <TextBox
                            id="lastname"
                            label="Apellidos"
                            labelMode="floating"
                            mode="text"
                            readOnly={status}
                            value={lastname}
                            valueChangeEvent='input'
                            onValueChanged={valueChanged}
                        >
                            <Validator name="lastname">
                                <RequiredRule message="Necesito saber tu apellido"/>
                            </Validator>
                        </TextBox>
                    </GridItem>
                    <GridItem md={4} mdOffset={4} sm={12}>
                        <TextBox
                            id="email"
                            label="Email"
                            labelMode="floating"
                            mode="email"
                            readOnly={true}
                            value={email}
                            valueChangeEvent='input'
                            onValueChanged={valueChanged}
                        >
                            <Validator name="email">
                                <RequiredRule message="Ingresa un correo electrónico valido" type="email"/>
                                <RequiredRule message="Necesito tu correo electrónico"/>
                            </Validator>
                        </TextBox>
                    </GridItem>
                    <GridItem md={4} mdOffset={4} sm={12} style={{display:"flex", justifyContent:"center"}}>
                        <Button
                            stylingMode="contained"
                            type="default"
                            text="Cambiar Contraseña"
                            onClick={() => setPasswordModal(true)}
                        />
                    </GridItem>
                    <GridItem md={4} mdOffset={4} sm={12} style={{display:"flex", justifyContent:"center"}}>
                        <Button
                            stylingMode="outlined"
                            text={textButton}
                            icon={iconButton}
                            type="default"
                            onClick={handleEdit}
                        />
                    </GridItem>
                    <Divider />
                    <GridItem span={12} style={{textAlign: "right", fontSize: '12px'}}>
                        <p>creado: {dateFormat( new Date(usuario?.CreatedAt || ''))} hrs</p>
                        <p>actualizado: {dateFormat( new Date(usuario?.UpdatedAt || ''))} hrs</p>
                    </GridItem>
                </Grid>
            </ValidationGroup>*/}
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: '#datas'}}
                visible={loading}
                showIndicator={false}
                message="Guardado..."
            />
            {/*<PasswordModal isModalOpen={passwordModal} handleModalToggle={setPasswordModal} id={usuario?.ID} />*/}
            <UploadImageModal isModalOpen={uploadImageModal} handleModalToggle={setUploadImageModal} id={usuario?.ID} image={usuario?.imagen}/>
        </>
    )
}