import React from 'react';
import {Grid, GridItem} from "@patternfly/react-core";
import TextBox from "devextreme-react/text-box";
import { TextArea } from 'devextreme-react/text-area';
import Validator, {RequiredRule} from "devextreme-react/validator";
import ValidationGroup from "devextreme-react/cjs/validation-group";
import {Button} from "devextreme-react/button";
import FeedbackServices from "../Services/FeedbackServices";
import notify from "devextreme/ui/notify";

interface FeedbackFormProps {
    sendingAction: React.Dispatch<React.SetStateAction<boolean>>;
    isOpenModalAction: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FeedbackForm = (props: FeedbackFormProps) => {
    const [name, setName] = React.useState<string>('');
    const [email, setEmail] = React.useState<string>('');
    const [message, setMessage] = React.useState<string>('');

    const sendMessage = React.useCallback(() => {
        try {
            FeedbackServices.sendFeedback({
                name: name,
                email: email,
                message: message
            })
                .then((resp) => {
                    if (resp.status) {
                        notify({
                                message: 'Mensaje enviado, ¡Gracias!',
                                height: 50,
                                width: 150,
                                type: "success",
                                displayTime: 3500,
                                animation: {
                                    show: {
                                        type: 'fade', duration: 400, from: 0, to: 1,
                                    },
                                    hide: {type: 'fade', duration: 40, to: 0},
                                },
                            },
                            {
                                position: "top right",
                                direction: "down-push"
                            })
                        setName('');
                        setEmail('');
                        setMessage('');
                        props.isOpenModalAction(false);
                    } else {
                        notify({
                                message: 'Ocurrio un error, intenta de nuevo mas tarde',
                                height: 50,
                                width: 150,
                                type: "error",
                                displayTime: 3500,
                                animation: {
                                    show: {
                                        type: 'fade', duration: 400, from: 0, to: 1,
                                    },
                                    hide: {type: 'fade', duration: 40, to: 0},
                                },
                            },
                            {
                                position: "top right",
                                direction: "down-push"
                            })
                    }
                    props.sendingAction(false);
                })
        } catch (error) {
            notify({
                    message: error,
                    height: 50,
                    width: 150,
                    type: "error",
                    displayTime: 3500,
                    animation: {
                        show: {
                            type: 'fade', duration: 400, from: 0, to: 1,
                        },
                        hide: {type: 'fade', duration: 40, to: 0},
                    },
                },
                {
                    position: "top right",
                    direction: "down-push"
                })
            console.error(error);
            props.sendingAction(false);
        }
    }, [email, message, name, props]);

    const handleSubmit = React.useCallback((e) => {
        const rules = e.validationGroup.validate();

        if (rules.isValid) {
            props.sendingAction(true);
            sendMessage();
        }
    }, [props, sendMessage])

    const onValueChanged = (e: any) => {
        switch (e.element.id) {
            case ('name'):
                setName(e.value);
                break;
            case ('email'):
                setEmail(e.value);
                break;
            case 'message':
                setMessage(e.value);
        }
    }

    return(
        <ValidationGroup>
            <Grid hasGutter>
                <GridItem sm={12} md={6} rowSpan={1}>
                    <TextBox
                        label="Nombre"
                        labelMode="outside"
                        stylingMode="filled"
                        id="name"
                        value={name}
                        valueChangeEvent="input"
                        onValueChanged={onValueChanged}
                    >
                        <Validator>
                            <RequiredRule message="Necesito tu nombre"/>
                        </Validator>
                    </TextBox>
                </GridItem>
                <GridItem sm={12} md={6} rowSpan={1}>
                    <TextBox
                        label="Correo electrónico"
                        labelMode="outside"
                        stylingMode="filled"
                        id="email"
                        value={email}
                        valueChangeEvent="input"
                        onValueChanged={onValueChanged}
                    >
                        <Validator>
                            <RequiredRule message="Necesito tu correo electrónico"/>
                            <RequiredRule message="Necesito un correo electrónico valido" type="email"/>
                        </Validator>
                    </TextBox>
                </GridItem>
                <GridItem md={12} rowSpan={1}>
                    <TextArea
                        label="Mensaje"
                        labelMode="outside"
                        stylingMode="filled"
                        id="message"
                        value={message}
                        autoResizeEnabled={true}
                        minHeight={50}
                        maxHeight={300}
                        valueChangeEvent="input"
                        onValueChanged={onValueChanged}
                    >
                        <Validator>
                            <RequiredRule message="Necesito que me platiques tu idea"/>
                        </Validator>
                    </TextArea>
                </GridItem>
                <GridItem md={12} rowSpan={1}>
                    <div style={{display:'flex', justifyContent:'right'}}>
                        <Button
                            text="Enviar"
                            type="default"
                            icon="tips"
                            stylingMode="contained"
                            onClick={handleSubmit}
                            style={{marginRight: '5px'}}
                        />
                        <Button
                            text="Cancelar"
                            type="default"
                            stylingMode="text"
                            onClick={() => props.isOpenModalAction(false)}
                            style={{marginLeft: '5px'}}
                        />
                    </div>
                </GridItem>
            </Grid>
        </ValidationGroup>
    )
}