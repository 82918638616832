import React from "react";
import {Breadcrumb, BreadcrumbItem} from "@patternfly/react-core";
import {useNavigate} from "react-router-dom";

export interface BreadcrumbsType {
    title: string
    to?: string
    isActive?: boolean
}

interface ViewProps {
    breadcrumbs: BreadcrumbsType[]
}

export const DiazarPageBreadcrumb : React.FunctionComponent<ViewProps> = (props)=>{
    const { breadcrumbs } = props
    let navigate = useNavigate()

    const items = React.useMemo( ()=>{
        return breadcrumbs.map( (item, index) => (
            <BreadcrumbItem to={ item.to }
                            isActive={ item.isActive }
                            key={ `breadcrumb-item-${ index }` }
                            onClick={ (evt) => {
                                evt.preventDefault();
                                if( item.to !== undefined ){
                                    navigate( item.to )
                                }
                            } }
            >
                { item.title }
            </BreadcrumbItem>
        ) )
    }, [breadcrumbs, navigate] )

    if( breadcrumbs.length === 0 ) return null

    return (
        <Breadcrumb> { items } </Breadcrumb>
    );
}