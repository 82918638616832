import React from "react";
import {
    Button,
    DescriptionList,
    DescriptionListTerm,
    DescriptionListGroup,
    DescriptionListDescription, TextInput, Popover, InputGroup, InputGroupItem, CalendarMonth
} from '@patternfly/react-core';
import OutlinedCalendarAltIcon from '@patternfly/react-icons/dist/esm/icons/outlined-calendar-alt-icon';
import QuotesServices from "../Services/QuotesServices";
import { LoadIndicator } from 'devextreme-react/load-indicator';
import TextBox, { TextBoxTypes } from 'devextreme-react/text-box';
import TextArea from 'devextreme-react/text-area';
import {useAuthState} from "../Context/AuthProvider/AuthProvider";
import notify from "devextreme/ui/notify";

export interface Datas {
    quote_id:string;
    proveedor: string;
    fechaDeEntrega: string;
    elaboro: string;
    descuentoGral: number;
    vencimiento: string | Date;
    numCliente: string;
    vendedor: string;
    notasCliente: string;
}

export interface Actions {
    descuentoGral: React.Dispatch<React.SetStateAction<number>>;
    numCliente: React.Dispatch<React.SetStateAction<string>>;
    vendedor: React.Dispatch<React.SetStateAction<string>>;
    fechaVencimiento: React.Dispatch<React.SetStateAction<string | Date>>;
    descDefault: React.Dispatch<React.SetStateAction<number>>;
}

interface QuoteDataProps {
    datas: Datas;
    actions: Actions;
    editable: boolean;
}

export const QuoteData = (props: QuoteDataProps) => {
    /**
     * Checks if the calendar is open or not.
     *
     * @type {boolean}
     * @description The value of `isCalendarOpen` variable determines whether the calendar is currently open or not.
     *              It is a boolean variable that can be either `true` if the calendar is open or `false` if it is closed.
     *              This variable can be used to control the visibility and behavior of the calendar in an application.
     *              Whenever the calendar is opened or closed, the value of this variable should be updated accordingly.
     */
    const [isCalendarOpen, setIsCalendarOpen] = React.useState<boolean>(false);

    /**
     * Formats a given date object into a string representation using the 'es-mx' locale.
     *
     * This function takes a Date object as an argument and returns a string that represents the date in the format 'DD-MM-YYYY'.
     * It uses the 'toLocaleDateString' method of the Date object to format the date according to the specified locale ('es-MX').
     * It then replaces all '/' characters in the resulting string with '-' characters to match the desired format.
     *
     * @param {Date} date - The date object to be formatted.
     * @returns {string} The formatted date string in the format 'DD-MM-YYYY'.
     */
    const dateFormat = (date: Date) => date.toLocaleDateString('es-MX', { year: 'numeric', month: 'short', day: '2-digit'}).replace(/\//g, '-');

    // const [pct, setPCT] = React.useState(0);
    const [saveDueDate, setSaveDueDate] = React.useState(false)
    // const [savePCTDesc, setSavePCTDesc] = React.useState(false)
    const { token} = useAuthState()

    /**
     * Handles the selection of a date on the calendar.
     *
     * This function is triggered when a user selects a date from the calendar. It updates the `fechaVencimiento` property
     * in the `props.actions` object with the selected date, formatted as a string. It also toggles the state of the calendar
     * open/close status.
     *
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} _event - The mouse event object. Not used in this function, hence the underscore.
     * @param {Date} newValueDate - The selected date value from the calendar.
     */
    const onSelectCalendar = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newValueDate: Date) => {
        setSaveDueDate(true)
        QuotesServices.putQuoteDataByIdRequest(props.datas.quote_id, "fecha_vigencia", newValueDate, token+'')
            .then ((resp) => {
                if (resp.status) {
                    props.actions.fechaVencimiento(newValueDate);
                    setSaveDueDate(false)
                } else {
                    notify({
                            message: resp.response,
                            height: 50,
                            width: 150,
                            type: "error",
                            displayTime: 5000,
                            animation: {
                                show: {
                                    type: 'fade', duration: 400, from: 0, to: 1,
                                },
                                hide: {type: 'fade', duration: 40, to: 0},
                            },
                        },
                        {
                            position: "top right",
                            direction: "down-push"
                        });
                }
            })
        setIsCalendarOpen(!isCalendarOpen);
    };

    /**
     * Toggles the state of the calendar.
     * If the calendar is currently open, it will be closed.
     * If the calendar is currently closed, it will be opened.
     * The time picker will be closed.
     *
     * @function
     * @name onToggleCalendar
     * @returns {void}
     */
    const onToggleCalendar = () => {
        setIsCalendarOpen(!isCalendarOpen);
    };

    /**
     * @description Represents a Calendar component.
     *
     * @param {Object} props - The properties for the Calendar component.
     * @param {string} props.locale - The locale for the calendar (e.g. "es-MX").
     * @param {Date} props.date - The initial date value for the calendar.
     * @param {function} props.onChange - The function to be called when a date is selected.
     *
     * @returns {React.Component} The Calendar component.
     */
    const calendar = <CalendarMonth locale="es-MX" date={new Date(props.datas.vencimiento)} onChange={onSelectCalendar} />;

    const calendarButton = (
        <Button variant="control" aria-label="Toggle the calendar" onClick={onToggleCalendar} isDisabled={!props.editable}>
            <OutlinedCalendarAltIcon />
        </Button>
    );

    /*const Actions = (event, value?) => {
        switch(event.target.id) {
            case "descuentoGral":
                if (value) {
                    setPCT(value);
                } else {
                    setSavePCTDesc(true)
                    QuotesServices.putQuoteDataByIdRequest(props.datas.quote_id, "pct_desc", event.target.value / 100, token+'')
                        .then((resp) => {
                            if (!resp.status) {
                                notify({
                                        message: resp.response,
                                        height: 50,
                                        width: 150,
                                        type: "error",
                                        displayTime: 5000,
                                        animation: {
                                            show: {
                                                type: 'fade', duration: 400, from: 0, to: 1,
                                            },
                                            hide: {type: 'fade', duration: 40, to: 0},
                                        },
                                    },
                                    {
                                        position: "top right",
                                        direction: "down-push"
                                    });
                            }
                            setSavePCTDesc(false)
                        })
                    props.actions.descuentoGral(event.target.value / 100);
                }
                break;

            case "numCliente":
                props.actions.numCliente(value);
                break;

            case "vendedor":
                props.actions.vendedor(value);
                break;
        }
    }*/

    /*React.useEffect(() => {
        setPCT(props.datas.descuentoGral * 100);
    }, [props.datas.descuentoGral])*/

    return (
        <div style={{backgroundColor: "white", padding: "20px"}}>
            <DescriptionList
                columnModifier={{
                    sm: '1Col',
                    md: '2Col',
                    lg: '3Col'
                }}
            >
                {/*<DescriptionListGroup>
                    <DescriptionListTerm>Proveedor</DescriptionListTerm>
                    <DescriptionListDescription>{props.datas.proveedor}</DescriptionListDescription>
                </DescriptionListGroup>*/}
                <DescriptionListGroup>
                    <DescriptionListTerm>Solicitante</DescriptionListTerm>
                    <DescriptionListDescription>{props.datas.elaboro}</DescriptionListDescription>
                </DescriptionListGroup>
                <DescriptionListGroup>
                    <DescriptionListTerm>Fecha de entrega requerida</DescriptionListTerm>
                    <DescriptionListDescription>{dateFormat(new Date(props.datas.fechaDeEntrega))}</DescriptionListDescription>
                </DescriptionListGroup>
                <DescriptionListGroup>
                    <DescriptionListTerm>Vencimiento de la cotización</DescriptionListTerm>
                    <DescriptionListDescription>
                        <Popover
                            position="bottom"
                            bodyContent={calendar}
                            showClose={false}
                            isVisible={isCalendarOpen}
                            hasNoPadding
                            hasAutoWidth
                        >
                            <InputGroup>
                                <InputGroupItem>
                                    <TextInput
                                        type="text"
                                        id="date-time"
                                        aria-label="date and time picker vencimiento"
                                        value={dateFormat(new Date(props.datas.vencimiento))}
                                        readOnly={true}
                                        isDisabled={!props.editable}
                                    />
                                </InputGroupItem>
                                <InputGroupItem>{calendarButton}</InputGroupItem>
                                <LoadIndicator className="button-indicator" visible={saveDueDate} style={{padding: "5px"}}/>
                            </InputGroup>
                        </Popover>
                    </DescriptionListDescription>
                </DescriptionListGroup>
                <DescriptionListGroup>
                    <DescriptionListTerm>Vendedor</DescriptionListTerm>
                    <DescriptionListDescription>
                        {props.datas.vendedor ? props.datas.vendedor : "Sin asignar"}
                    </DescriptionListDescription>
                </DescriptionListGroup>
                {/*<DescriptionListGroup>
                    <DescriptionListTerm>Número de cliente</DescriptionListTerm>
                    <DescriptionListDescription>
                        <TextInput
                            id="numCliente"
                            className="numClient"
                            value={props.datas.numCliente}
                            type="text"
                            onChange={Actions}
                            aria-label="text input numCliente"
                            isDisabled={!props.editable}
                        />
                    </DescriptionListDescription>
                </DescriptionListGroup>*/}
                <DescriptionListGroup>
                    <DescriptionListTerm>Descuento por defecto</DescriptionListTerm>
                    <DescriptionListDescription >
                        <TextBox
                            mask="YZ%"
                            maskRules={{Y: /[0-9]/, Z: /[0-9]/}}
                            inputAttr={{ 'aria-label': 'desc-default' }}
                            maskInvalidMessage="Del 01 al 99"
                            width="55px"
                            valueChangeEvent="focusout"
                            onValueChanged={(e : TextBoxTypes.ValueChangedEvent) => props.actions.descDefault(e.value)}
                            readOnly={!props.editable}
                        />
                    </DescriptionListDescription>
                </DescriptionListGroup>
            </DescriptionList>
            <br />
            <DescriptionList
                columnModifier={{
                    sm: '1Col',
                    md: '2Col'
                }}
            >
                <DescriptionListGroup>
                    <DescriptionListTerm>Notas del cliente:</DescriptionListTerm>
                    <DescriptionListDescription>
                        <TextArea
                            height={70}
                            value={props.datas.notasCliente}
                            placeholder="No hubieron comentarios del cliente"
                            readOnly={true}
                            inputAttr={{ 'aria-label': 'Notes del cliente' }}
                        />
                    </DescriptionListDescription>
                </DescriptionListGroup>
            </DescriptionList>
        </div>
    )
}