import React from "react";
import {Button, ButtonVariant, Grid, Popover, ToolbarItem, Tooltip} from "@patternfly/react-core";
import {BsGrid3X3GapFill} from "react-icons/bs";
import {DiazarSuiteApp} from "../Context/DiazarSuiteProvider/DiazarSuiteProviderDomain";
import { useAppsState } from "../Context/DiazarSuiteProvider/DiazarSuiteProvider";

const DiazarSuiteIcon : React.FunctionComponent<DiazarSuiteApp> = (app)=>{
    const trigger = ['mouseenter'];
    const tooltipRef = React.useRef<HTMLAnchorElement>(null);

    return (
        <React.Fragment>
            <a href={ app.app_url } target="_blank" ref={ tooltipRef } rel="noreferrer">
                <img src={ app.logo_url } alt={ app.name }/>
            </a>
            <Tooltip content={<div>{ app.name }<br/>{ app.description }</div>}
                     trigger={trigger.join(' ')}
                     triggerRef={ tooltipRef }
                     position="bottom"
                     distance={ 5 }
                     animationDuration={ 1200 }
            />
        </React.Fragment>
    )
}

export const DiazarSuitePopover : React.FunctionComponent = (_props) =>{
    const switeData = useAppsState()


    const bodyContent = React.useMemo( ()=>{
        if( switeData.apps === undefined ){
            return null
        }

        return (
            <Grid sm={3} hasGutter id="diazar-suite-grid">
                { switeData.apps.map( app =>  <DiazarSuiteIcon key={ app.id } {...app} /> ) }
            </Grid>
        )
    }, [ switeData.apps ] )

    if( switeData.apps === undefined || switeData.apps.length === 0 ){
        return null
    }

    return (
        <ToolbarItem>
            <Popover
                aria-label="Popover with no header, footer, close button, and padding"
                showClose={false}
                bodyContent={ hide => bodyContent }
                headerContent="Diazar Suite"
            >
                <Button aria-label="diazar-suite-btn"
                        variant={ButtonVariant.plain}
                        style={ {fontSize:'20px'} }
                >
                    <BsGrid3X3GapFill />
                </Button>
            </Popover>

        </ToolbarItem>
    );
}