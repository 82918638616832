import React from 'react';
import {LoginLayout} from "../../Layouts/LoginLayout";
import UsersServices from "../../Services/UsersServices";
import notify from "devextreme/ui/notify";
import {useNavigate, useParams} from "react-router-dom";
import {ChangePasswordForm} from "../../Components/ChangePasswordForm";

export const ResetPasswordWithToken = () => {
    const { TOKEN } = useParams();
    const navigate = useNavigate();
    const [tokenValid, setTokenValid] = React.useState<boolean>(false);

    const validToken = async () => {
        try {
            const response = await UsersServices.validateResetPasswordToken(TOKEN+'');
            if (response.status === 'SUCCESS') {
                // Token is valid, continue with password reset
                setTokenValid(true);
            } else {
                // Token is invalid, show error message
                notify({
                        message: response.error.error,
                        height: 50,
                        width: 150,
                        type: "error",
                        displayTime: 3500,
                        animation: {
                            show: {
                                type: 'fade', duration: 400, from: 0, to: 1,
                            },
                            hide: { type: 'fade', duration: 40, to: 0 },
                        },
                    } ,
                    {
                        position : "top right",
                        direction: "down-push"
                    });
                navigate("/login");
            }
        } catch (error) {
            // Error occurred while validating token, show error message
            notify({
                    message: "Ocurrio un problema mientras se validaba el token",
                    height: 50,
                    width: 150,
                    type: "error",
                    displayTime: 3500,
                    animation: {
                        show: {
                            type: 'fade', duration: 400, from: 0, to: 1,
                        },
                        hide: { type: 'fade', duration: 40, to: 0 },
                    },
                } ,
                {
                    position : "top right",
                    direction: "down-push"
                });
            navigate("/login");

        }
    }

    React.useEffect(() => {
        if (!tokenValid) {
            validToken();
        }

    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [TOKEN])

    return (
        <LoginLayout loginTitle={"Restablecer Contraseña"}
                     loginSubtitle={"Coloca tu nueva contraseña y confirmala"}
        >
            {tokenValid ?
                    <ChangePasswordForm />
                :
                    null
            }

        </LoginLayout>
    )
}