import React from "react";
import DataGrid, {
    Column,
    DataGridTypes,
    Pager,
    Selection,
    Grouping,
    GroupPanel,
    FilterRow, HeaderFilter, SearchPanel
} from "devextreme-react/data-grid";
import {IQuote} from "../Services/QuotesServices";
import {useNavigate} from "react-router-dom";
import {LoadPanel} from "devextreme-react/load-panel";

interface QuotesListProps {
    datas: IQuote[];
    ignore: boolean;
    isLoading: boolean;
}

export const QuotesList: React.FC<QuotesListProps> = (props) => {
    const gridRef = React.useRef<DataGrid>(null);
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState<boolean>(props.isLoading);
    /**
     * Callback function to handle selection change in a data grid.
     */
    const selectedChanged = React.useCallback((e: DataGridTypes.SelectionChangedEvent) => {
        navigate(`/quotes/${e.selectedRowsData[0].solicitud_cotizacion_id}`)
    }, [navigate])

    React.useEffect(() => {
        if (props.datas.length > 0) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [props.datas]);

    return (
        <>
            <DataGrid
                id="QuotesList"
                dataSource={props.datas}
                onSelectionChanged={selectedChanged}
                keyExpr="cotizacion_id"
                ref={gridRef}
                columnAutoWidth={true}
                className="custom-datagrid"
            >
                <Selection mode="single"/>
                <Pager
                    visible={true}
                    allowedPageSizes={"auto"}
                    showInfo={true}
                    showNavigationButtons={true}
                    infoText="Página {0} de {1} ({2} cotizaciones)"
                />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} />
                <Grouping contextMenuEnabled={true} />
                <FilterRow visible={true} />
                <GroupPanel visible={"auto"} />
                <Column dataField="cotizacion_id" caption="ID" visible={false} dataType="number"/>
                <Column dataField="folio" caption="Folio" visible={true} dataType="number"/>
                <Column dataField="vendedor" caption="Vendedor" visible={true} dataType="string"/>
                <Column dataField="fecha" dataType="datetime" visible={true} />
                <Column dataField="fecha_recibida" dataType="datetime" visible={true} />
                <Column dataField="proyecto" caption="Proyecto" visible={true} groupIndex={0} dataType="string"/>
                <Column dataField="nota_de_envio" caption="Nota del cliente" visible={true} dataType="string"/>
                <Column dataField="nota_de_respuesta" caption="Nota enviadas al cliente" visible={true} dataType="string"/>
                <Column dataField="observacion" caption="Observaciones" visible={true} dataType="string"/>
                <Column dataField="recibida" caption="Terminadas" dataType="boolean" visible={true} filterValue={!props.ignore} />
            </DataGrid>
            <LoadPanel
                position={{of: '#QuotesList'}}
                visible={loading}
            />
        </>
    )
}