import React from 'react';
import {LoginLayout} from "../../Layouts/LoginLayout";
import {LoginForm} from "../../Components/Access/LoginForm";
import {RegisterForm} from "../../Components/Access/RegisterForm";
import { useLocation, useSearchParams } from 'react-router-dom';

export const Login = () => {
    const [title, setTitle] = React.useState<string>('');
    const [subtitle, setSubtitle] = React.useState<string>('');
    const [textContent, setTextContent] = React.useState<string>('')
    const [component, setComponent] = React.useState<React.ReactNode>(null);
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const descriptionApp = "Con el cotizador Diazar, puedes generar cotizaciones sincronizadas en tiempo real para un seguimiento práctico con tus clientes.\n" +
        "\n" +
        "Ingresa precios, cantidades y tiempos de entrega, además de adjuntar documentos y notas para una comunicación efectiva. Obtén una mayor visibilidad con tus clientes."

    const addClient = searchParams.get("addClient");

    React.useEffect(() => {
        switch (location.pathname) {
            case "/login":
                setTitle('Iniciar sesión');
                setSubtitle('Ingresa tu nombre de usuario y contraseña para acceder al portal de cotizaciones');
                setTextContent(`${descriptionApp}`);
                setComponent(<LoginForm actions={{
                    addClient: addClient
                }}/>);
                break;
            case "/register":
                setTitle('Registro');
                setSubtitle('Solo brindame unos datos para comenzar a utilizar esta poderosa herramienta.');
                setTextContent(`${descriptionApp}`);
                setComponent(<RegisterForm />);
                break;
        }
    }, [addClient, location])

    return (
        <LoginLayout loginTitle={title}
                     loginSubtitle={subtitle}
                     textContent={textContent}
        >
            {component}
        </LoginLayout>
    )
}