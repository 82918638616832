import React, {useState} from "react";
import {
    Button,
    FormGroup,
    FormHelperText,
    HelperText,
    HelperTextItem,
    InputGroup,
    TextInput, InputGroupItem
} from "@patternfly/react-core";
import {EyeIcon, EyeSlashIcon} from "@patternfly/react-icons";
import {Link} from "react-router-dom";

interface ViewProps {
    id: string
    label: string,

    getState?: (isValid:boolean, value:string) => void
    type?: 'text' | 'date' | 'datetime-local' | 'email' | 'month' | 'number' | 'password' | 'search' | 'tel' | 'time' | 'url';
    placeholder?: string;
    helperText?: string;
    isRequired?: boolean;
    initialValue?: string;
    isReadOnly?:boolean;
    isEqualTo?: string;
    min?:number;
    max?:number;
    register?: boolean;
    viewPassword?: boolean;
    validate?: {
        rule: string;
        text: string;
    };
}

export const DiazarFormInput: React.FunctionComponent<ViewProps> = (props) => {
    const initialValue = ( props.initialValue ) ? props.initialValue : '';
    const readOnly = ( props.isReadOnly ) ? props.isReadOnly : false;

    const [value, setValue] = React.useState<string>( initialValue )
    const [isValidValue, setIsValidValue] = React.useState<boolean>( true )
    const [hasInitialChange, setHasInitialChange] = React.useState<boolean>( false )
    const [seePassword, setSeePassword] = useState<boolean>(false)

    const type = React.useMemo( ()=>{
        let retType = ( props.type ) ? props.type : 'text'

        if( retType === 'password' && seePassword ){
            retType = 'text'
        }

        return retType
    }, [props.type, seePassword] )

    const helperText = React.useMemo( ()=>{
        let message = ( props.helperText ) ? props.helperText : ''
        const variant = ( hasInitialChange && !isValidValue ) ?  'error' : 'default'

        if( hasInitialChange && !isValidValue ){
            switch ( type ){
                case "email":
                    message = 'Introduzca un email válido'
                    break
                default:
                    message = 'Este campo es requerido'
                    break
            }

            if( props.isEqualTo && value !== props.isEqualTo){
                message = 'Los campos no coinciden'
            }

            if(props.min && value.length < props.min){
                message = `La longitud mínima es de ${ props.min } caracteres`
            }

            if(props.max && value.length > props.max){
                message = `La longitud máxima es de ${ props.max } caracteres`
            }
        }

        if( message === '' ){
            return null
        }

        return (
            <FormHelperText  >
                <HelperText id={ `helper-text-${ props.id }` } aria-live="polite">
                    {
                        (
                            <HelperTextItem variant={ variant } hasIcon={ (hasInitialChange && !isValidValue) }>
                                { message }
                            </HelperTextItem>
                        )
                    }
                </HelperText>
            </FormHelperText>
        )
    }, [value, hasInitialChange, isValidValue, props.helperText, props.id, props.isEqualTo, type, props.min, props.max] )

    React.useEffect( ()=>{
        let validAux = true

        if( props.isRequired ){
            const type = ( props.type ) ? props.type : 'text'

            switch ( type ){
                case "email":
                    const isValid = String(value)
                        .toLowerCase()
                        .match(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        );

                    validAux = isValid !== null

                    break
                default:
                    validAux = value.trim() !== ''
                    break
            }

        }

        if ( props.validate ) {
            const isValid = String(value)
                .match(props.validate.rule);
            validAux = isValid !== null;
        }

        if( validAux && props.isEqualTo ){
            validAux = ( value === props.isEqualTo )
        }

        if( validAux && props.min){
            validAux  = ( value.length >= props.min )
        }

        if(validAux && props.max){
            validAux = ( value.length <= props.max )
        }

        setIsValidValue( validAux )

    }, [value, props.isRequired, props.type, props.isEqualTo, props.min, props.max ] )

    React.useEffect( ()=>{
        if( props.getState ){
            props.getState(isValidValue, value)
        }
    }, [value, isValidValue, props] )

    const inputGropPassword = React.useMemo( ()=>{
        const retType = ( props.type ) ? props.type : 'text'

        if( retType !== 'password' || props.viewPassword === false){
            return  null
        }

        return (
            <Button variant="control"
                    onClick={ () => setSeePassword( flag => !flag ) }
            >
                { ( seePassword ) ? <EyeSlashIcon /> : <EyeIcon /> }
            </Button>
        )
    }, [props.type, seePassword] )

    return (
        <FormGroup label={ props.label }
                   isRequired={ props.isRequired }
                   fieldId={props.id}
        >
            <InputGroup >
                <InputGroupItem isFill >
                    <TextInput
                        id={props.id}
                        type={ type }
                        placeholder={ props.placeholder }
                        value={value}
                        onChange={ (_event, value:string) => { setValue( value ) } }
                        aria-invalid={ (hasInitialChange && !isValidValue) }
                        onBlur={ ()=>{ setHasInitialChange(true) } }
                        min={ props.min }
                        max={ props.max }
                        readOnlyVariant={readOnly ? "default" : undefined}
                        readOnly={ readOnly }
                    />
                </InputGroupItem>
                { inputGropPassword }
            </InputGroup>
            { helperText }
            {
                props.type === 'password' && props.register !== true ?
                    <Button variant="link" isInline>
                        <Link to="/reset-password">
                            ¿Olvidaste tu contraseña?
                        </Link>
                    </Button>
                    :
                    null
            }
        </FormGroup>
    )
}