import React from 'react';
import {PageLayout} from "../../Layouts/PageLayout";
import {QuoteDataGrid} from "../../Components/QuoteDataGrid";
import {Actions, QuoteData} from "../../Components/QuoteData";
import {useNavigate, useParams} from 'react-router-dom';
import { LoadPanel } from 'devextreme-react/load-panel';
import QuotesServices, {ResponseItemsQuotes} from '../../Services/QuotesServices';
import {QuoteFooter} from "../../Components/QuoteFooter";
import {useAuthState} from "../../Context/AuthProvider/AuthProvider";
import {DiazarToolbar} from "../../Components/DiazarToolbar";

interface QuoteDatas {
    quote_id: string;
    proveedor: string;
    fechaDeEntrega: string;
    elaboro: string;
    descuentoGral: number;
    numCliente: string;
    vencimiento: string | Date;
    vendedor: string;
    notasCliente: string;
}

/**
 * Loads a quote by quoteId.
 *
 * @param {string|undefined} quoteId - The quote ID.
 * @returns {Promise<unknown>} - The promise that resolves with the loaded quote, or rejects with an error.
 * @throws {Error} - If the quoteId is undefined.
 */
const loadQuote = async (quoteId: string | undefined, token: string): Promise<ResponseItemsQuotes> => {
        if (quoteId === undefined) {
            throw new Error("Necesito un ID de Solicitud para cargar información")
        }
        return await QuotesServices.getQuoteByIdSolicitud(quoteId, token+'');
};

/**
 * Represents a page for displaying quote details.
 *
 * @component QuoteDetailsPage
 * @category Pages
 * @returns {React.Element} The quote details page component.
 */
export const QuoteDetailsPage: React.FunctionComponent = (_props) => {
    const { QUOTE_ID } = useParams();
    const {token} = useAuthState();
    const [descuentoGral, setDescuentoGral ] = React.useState<number>(0);
    const [numCliente, setNumCliente] = React.useState<string>('');
    const [vendedor, setVendedor] = React.useState<string>('')
    const [fechaVencimiento, setFechaVencimiento] = React.useState<string | Date>('DD-MM-AAAA');
    const [deliveryDate, setDeliveryDate] = React.useState<string>('');
    const [quoteEditable, setQuoteEditable] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState(false);
    const [descDefault, setDescDefault] = React.useState<number>(0);
    const [notaProv, setNotaProv] = React.useState<string>('');
    const [condPago, setCondPago] = React.useState<string>('');
    const [folio, setFolio] = React.useState<string>('');
    const [proyecto, setProyecto] = React.useState<string>('');
    const navigate = useNavigate();
    const [quoteData, setQuoteData] = React.useState<QuoteDatas>({
        quote_id:  '',
        proveedor: '',
        fechaDeEntrega: '',
        elaboro: '',
        descuentoGral: descuentoGral,
        numCliente: numCliente,
        vencimiento: fechaVencimiento,
        vendedor: vendedor,
        notasCliente: ''
    });

    /**
     * An object representing various actions.
     *
     * @typedef {Object} Actions
     * @property {function} descuentoGral - A function to set the general discount.
     * @property {function} numCliente - A function to set the customer number.
     * @property {function} vendedor - A function to set the salesperson.
     * @property {function} fechaVencimiento - A function to set the expiration date.
     * @property {function} horaVencimiento - A function to set the expiration time.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const ACTIONS: Actions = {
        descuentoGral: setDescuentoGral,
        numCliente: setNumCliente,
        vendedor: setVendedor,
        fechaVencimiento: setFechaVencimiento,
        descDefault: setDescDefault
    }

    /**
     * Represents the current logged in user.
     */
    const USER = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser') + "") : {};

    /**
     * Memoized data quote.
     * @type {React.Element}
     * @param {Array<Object>} quoteData - The array of quote data.
     * @param {Object} ACTIONS - The actions object.
     * @param {boolean} quoteEditable - Flag indicating whether the quote is editable.
     * @returns {React.Element} The memoized data quote component.
     */
    const dataQuote = React.useMemo(() => <QuoteData
        datas={quoteData}
        actions={ACTIONS}
        editable={quoteEditable}
    />, [ACTIONS, quoteData, quoteEditable]);

    /**
     * footerQuote
     *
     * @description Returns a memoized React component for the footer quote area.
     *
     * @function
     * @name footerQuote
     *
     * @param {number} QUOTE_ID - The ID of the quote.
     * @param {string} notaProv - The nota value for the quote.
     * @param {boolean} quoteEditable - Determines if the quote is editable or not.
     * @param {string} condPago - The condPago value for the quote.
     *
     * @returns {React.Component} - A memoized React component for the footer quote area.
     */
    const footerQuote = React.useMemo(() => <QuoteFooter
        quoteId={QUOTE_ID}
        nota={notaProv}
        editable={quoteEditable}
        condPago={condPago}
    />,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [notaProv, QUOTE_ID, quoteEditable])

    React.useEffect(() => {
        if (loading && quoteData.quote_id.length === 0) {
            loadQuote(QUOTE_ID, token+'').then((resp) => {
                if (USER) {
                    setQuoteData(prevState => ({
                        ...prevState,
                            "proveedor": USER.empresa.nombre,
                            "fechaDeEntrega": resp.fecha_envio,
                            "elaboro": resp.elabora,
                            "descuentoGral": resp.pct_desc,
                            "vencimiento": resp.fecha_vigencia,
                            "notasCliente": resp.nota_de_envio,
                            "quote_id": resp.solicitud_cotizacion_id + ''
                    }));
                    setQuoteEditable(!resp.recibida);
                    setDescuentoGral(resp.pct_desc);
                    setFechaVencimiento(resp.fecha_vigencia);
                    setNotaProv(resp.nota_de_respuesta);
                    setCondPago(resp.condiciones_de_pago);
                    setFolio(resp.folio+'');
                    setProyecto(`(${resp.proyecto_id}) ${resp.proyecto}`)
                }
                setLoading(false);
            }).catch((error) => navigate('/quotes'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [QUOTE_ID, USER, loading, quoteData.quote_id, quoteData.proveedor, token]);

    React.useEffect(() => {
        setQuoteData(prev => {
            return {...prev, quote_id:''}
        })
        setLoading(true);
    }, [QUOTE_ID, token])

    React.useEffect(() => {
        setQuoteData(prevState => ({
            ...prevState,
            descuentoGral: descuentoGral,
            numCliente: numCliente,
            vencimiento: fechaVencimiento,
            vendedor: vendedor
        }))
    }, [descuentoGral, numCliente, fechaVencimiento, vendedor, folio, quoteEditable])

    return (
        <PageLayout title={`Cotización #${folio}`}
                    description={`${proyecto} / Solicitud: ${QUOTE_ID}`}
                    breadcrumbs={ [{title:'Inicio', to: '/'}, {title:'Clientes', to: '/quotes-clients'},{title:'Cotizaciones', to:'/quotes'}, {title: `Folio ${folio}`, isActive: true}] }
        >
            <div id="quote">
                <DiazarToolbar actions={{
                    back: '/quotes',
                }}/>
                {dataQuote}
                <br/>
                <QuoteDataGrid
                    RequestDeliveryDate={quoteData.fechaDeEntrega}
                    deliveryDate={deliveryDate}
                    deliveryDateAction={setDeliveryDate}
                    quoteID={QUOTE_ID}
                    editable={quoteEditable}
                    descDefault={descDefault}
                />
                <br />
                {footerQuote}
                <br />
            </div>
            <LoadPanel
                position={{of: '#quote'}}
                visible={loading}
            />
        </PageLayout>
    )
}
