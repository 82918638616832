import React from "react";
import {PageLayout} from "../../Layouts/PageLayout";
import {ClientsList} from "../../Components/ClientsList";

export const ClearPage: React.FunctionComponent = (_props) => {
    const description = ""

    return (
        <PageLayout title="Inicio"
                    description={description}
                    breadcrumbs={ [{title:'Inicio'}] }
        >
            <ClientsList/>
        </PageLayout>
    )
}