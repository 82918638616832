import React from "react";
import {PageLayout} from "../../Layouts/PageLayout";

export const ClientsPage : React.FunctionComponent = (_props) => {
    return (
        <PageLayout title="Clientes">

        </PageLayout>
    )
}
