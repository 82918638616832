import {ServicesUrl} from "../Config/UrlRuotes";

export interface FeedbackMessage{
    name: string;
    email: string;
    message: string;
}

const sendFeedback = async (message: FeedbackMessage) => {
    const response = await fetch(ServicesUrl.feedback, {
        method: 'POST',
        body: JSON.stringify(message)
    })

    const data = await response.json();

    if (data.status === "SUCCESS") {
        return {
            status: true,
            response: data.response
        }
    } else {
        return {
            status: false,
            response: data.error.error
        }
    }
}

const exportedObjects = {sendFeedback}
export default exportedObjects;