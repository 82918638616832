import React from 'react';
import {PageLayout} from "../../Layouts/PageLayout";
import {FormProfile} from "../../Components/FormProfile";
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import {FormCompany} from "../../Components/FormCompany";
import {ChangePasswordForm} from "../../Components/ChangePasswordForm";
import {
    Grid, GridItem
} from "@patternfly/react-core";

/**
 * Represents the user profile page component.
 *
 * @component
 *
 * @returns {JSX.Element} The JSX element representing the user profile page.
 */
export const UserProfilePage = () => {
    return(
        <PageLayout title="Datos de perfil y empresa"
                    description="Completa o edita tus datos."
                    breadcrumbs={ [{title:'Inicio', to: '/'}, {title:'Perfil', isActive: true}] }
        >
            <div style={{ backgroundColor: "white"}}>
                <TabPanel id="tabPanel"
                          loop={true}
                          animationEnabled={true}
                          swipeEnabled={true}
                          iconPosition="start"
                >
                    <Item title="Usuario" icon="user">
                        <FormProfile />
                    </Item>
                    <Item title="Empresa" icon="product">
                        <FormCompany />
                    </Item>
                    <Item title="Cambio de contraseña " icon="preferences">
                        <Grid hasGutter id="profile" style={{padding: "20px"}} >
                            <GridItem md={4} mdOffset={4} sm={12}>
                                <ChangePasswordForm />
                            </GridItem>
                        </Grid>
                    </Item>
                </ TabPanel>
            </div>
        </PageLayout>
    )
}