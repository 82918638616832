import React from 'react';
import {Grid, GridItem, Modal, ModalVariant} from "@patternfly/react-core";
import {LoadPanel} from "devextreme-react/load-panel";
import {
    CropperRef,
    Cropper,
    CircleStencil,
    CropperPreview,
    CropperPreviewRef,
    ImageRestriction
} from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css'
import 'react-advanced-cropper/dist/themes/compact.css';
import FileUploader from 'devextreme-react/file-uploader';
import {Button} from "devextreme-react/button";
import UsersServices from "../Services/UsersServices";
import notify from "devextreme/ui/notify";
import {useAuthDispatch, useAuthState} from "../Context/AuthProvider/AuthProvider";
import {updateUser} from "../Context/AuthProvider/AuthProviderActions";

interface UploadImageModalProps {
    isModalOpen: boolean;
    handleModalToggle: React.Dispatch<React.SetStateAction<boolean>>;
    id: number | undefined;
    image: string | undefined;
}

interface Image {
    type?: string;
    src: string;
}

export const UploadImageModal = (props: UploadImageModalProps) => {
    const cropperRef = React.useRef<CropperRef>(null);
    const previewRef = React.useRef<CropperPreviewRef>(null);
    const [loading, setLoading] = React.useState<boolean>(false)
    const [imageBlob, setImageBlob] = React.useState<Image | null>();
    const { token} = useAuthState();
    const dispatch = useAuthDispatch()

    const handleModalToggle = () => {
        setImageBlob(null);
        props.handleModalToggle(!props.isModalOpen);
    }

    const onUpdate = () => {
        previewRef.current?.refresh();
    };

    const onUpload = () => {
        setLoading(true)
        const canvas = cropperRef.current?.getCanvas({
            height: 500,
            width: 500
        });
        if (canvas) {
            canvas.toBlob((blob) => {
                if (blob) {
                    const file = new File([blob], "photo.jpg", {
                        type: 'image/jpeg',
                        lastModified: Date.now()
                    });

                    const formData = new FormData();
                    formData.append('image', file);

                    UsersServices.updateImage(formData, props.id+'', token+'')
                        .then((response) => {
                            updateUser(response.response, dispatch);
                            setLoading(false);
                            if (response.status === "SUCCESS") {
                                notify({
                                        message: 'Actualizado con éxito',
                                        height: 50,
                                        width: 150,
                                        type: "success",
                                        displayTime: 3500,
                                        animation: {
                                            show: {
                                                type: 'fade', duration: 400, from: 0, to: 1,
                                            },
                                            hide: { type: 'fade', duration: 40, to: 0 },
                                        },
                                    } ,
                                    {
                                        position : "top right",
                                        direction: "down-push"
                                    })
                                handleModalToggle();
                            } else {
                                notify({
                                        message: response.error.error,
                                        height: 50,
                                        width: 150,
                                        type: "Error",
                                        displayTime: 3500,
                                        animation: {
                                            show: {
                                                type: 'fade', duration: 400, from: 0, to: 1,
                                            },
                                            hide: { type: 'fade', duration: 40, to: 0 },
                                        },
                                    } ,
                                    {
                                        position : "top right",
                                        direction: "down-push"
                                    });
                            }
                        });
                } else {
                    setLoading(false);
                }
            }, 'image/jpeg', 1);
        } else {
            setLoading(false);
        }
    };

    /* Actuliza la imagen para recortar
     *
     * No carga la imagen al servidor sino que la carga para ser recortada.
     * @param files - objeto con archivo seleccionado.
     */
    const onLoadImage = (files) => {
        // Ensure that you have a file before attempting to read it
        if (files && files[0]) {
            // Create the blob link to the file to optimize performance:
            const blob = URL.createObjectURL(files[0]);

            // Get the image type from the extension. It's the simplest way, though be careful it can lead to an incorrect result:
            setImageBlob({
                src: blob,
                type: files[0].type
            })
        }
    };


    return (
        <>
            <Modal
                variant={ModalVariant.large}
                title={props.image ? "Editar imagen" : "Cargar imagen"}
                isOpen={props.isModalOpen}
                onClose={handleModalToggle}
                id="modal"
            >
                <Grid hasGutter>
                    <GridItem sm={12}>
                        <FileUploader
                            accept="image/*"
                            onValueChange={onLoadImage}
                            uploadMode="useForm"
                        />
                    </GridItem>
                    <GridItem sm={12} md={9} mdRowSpan={12} lg={10}>
                        <Cropper
                            ref={cropperRef}
                            src={imageBlob && imageBlob?.src}
                            className={'cropper'}
                            defaultSize={{
                                width: 150,
                                height: 150,
                            }}
                            stencilComponent={CircleStencil}
                            stencilProps={{
                                grid: true,
                                aspectRatio: 3/1,
                            }}
                            onUpdate={onUpdate}
                            imageRestriction={ImageRestriction.fitArea}
                            minHeight={50}
                            minWidth={50}
                        />
                    </GridItem>
                    <GridItem sm={12} md={3} mdRowSpan={1} mdOffset={10} lg={2} >
                        <Grid>
                                { imageBlob &&
                                    <>
                                        <GridItem sm={12} style={{display: 'flex' , justifyContent: 'center'}}>
                                                <CropperPreview
                                                    ref={previewRef}
                                                    cropper={cropperRef}
                                                    style={{
                                                        borderStyle: 'dashed',
                                                        borderColor: "#001d44",
                                                        borderRadius: '50%',
                                                        width: '165px',
                                                        height: '165px',
                                                        minWidth: '165px',
                                                        minHeight: '165px'}}
                                                />
                                        </GridItem>
                                        <GridItem sm={12} style={{display: 'flex' , justifyContent: 'center'}}>
                                                <Button
                                                    text="Recortar y guardar"
                                                    type="success"
                                                    style={{marginTop: '10px'}}
                                                    onClick={onUpload}
                                                />
                                        </GridItem>
                                    </>
                                }
                                <GridItem sm={12} style={{display: 'flex' , justifyContent: 'center'}}>

                                </GridItem>
                            </Grid>
                    </GridItem>
                </Grid>
            </Modal>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: '#modal'}}
                visible={loading}
                showIndicator={false}
                message="Cargando..."
            />
        </>
    );
}