import React, {useEffect} from 'react';
import {useParams, useNavigate} from "react-router-dom";
import {addValidationToken, CompanyDiazar, getInfoValidationToken} from "../../Services/ClientsServices";
import notify from "devextreme/ui/notify";
import {useAuthState} from "../../Context/AuthProvider/AuthProvider";
import {InfoLayout} from "../../Layouts/InfoLayout";
import {
    Bullseye,
    Button,
    EmptyState,
    EmptyStateActions,
    EmptyStateBody,
    EmptyStateFooter,
    EmptyStateHeader,
    EmptyStateIcon
} from '@patternfly/react-core';
import CubesIcon from "@patternfly/react-icons/dist/esm/icons/cubes-icon";
import moment from "moment";

export const AddDiazarClient = () => {
    const [flagHasInfo, setFlagHasInfo] = React.useState<boolean>(false);
    const [flagLoading, setFlagLoading] = React.useState<boolean>(false);

    const [newClient, setNewClient] = React.useState<CompanyDiazar>({id: 0, name: '', tradename: ''})


    const navigate = useNavigate();
    const {token} = useAuthState();
    const { TOKEN } = useParams();

    const infoToken = React.useCallback(async (): Promise<void> => {
        setFlagLoading(true);
        await getInfoValidationToken(TOKEN+'').then( (data) => {
            setFlagHasInfo(true);
            if (!data.error["has-error"]) {
                setNewClient(data.response);
            }
        } ).finally(() => {
            setFlagLoading(false);
        });
    }, [TOKEN])

    const validateToken = React.useCallback(async (): Promise<void> => {
        setFlagLoading(true);
        await addValidationToken(TOKEN+'', token+'')
            .then((data) => {
                if (data.ok) {
                    notify({
                            message: data.data,
                            height: 50,
                            width: 150,
                            type: "success",
                            displayTime: 3500,
                            animation: {
                                show: {
                                    type: 'fade', duration: 400, from: 0, to: 1,
                                },
                                hide: { type: 'fade', duration: 40, to: 0 },
                            },
                        } ,
                        {
                            position : "top right",
                            direction: "down-push"
                        });
                    navigate('/');
                } else {
                    notify({
                            message: data.data,
                            height: 50,
                            width: 150,
                            type: "error",
                            displayTime: 5000,
                            animation: {
                                show: {
                                    type: 'fade', duration: 400, from: 0, to: 1,
                                },
                                hide: { type: 'fade', duration: 40, to: 0 },
                            },
                        } ,
                        {
                            position : "top right",
                            direction: "down-push"
                        });
                    navigate('/')
                }
            }).finally(() => {
                setFlagLoading(false);
            });
    }, [TOKEN, navigate, token]);

    useEffect(()=>{
        if(!flagHasInfo) infoToken().then();
    }, [flagHasInfo, infoToken]);

    const TitlePart = React.useMemo(()=>{
        if (flagHasInfo) {
            return `${newClient.name}`
        }

        return 'Validando...'
    }, [flagHasInfo, newClient.name])

    const MessagePart = React.useMemo(()=>{
        if (flagHasInfo) {
            const companyName = (newClient.tradename !== '') ? newClient.tradename : newClient.name;

            return `La empresa ${ companyName }, desea agregarlo como proveedor. ¿Deseas agregar a ${newClient.name} a tus clientes?`
        }

        return 'Espera un momento, estamos validando la información.'
    }, [flagHasInfo, newClient.name, newClient.tradename])

    const ActionsButtons = React.useMemo(()=>{
        if (token) {
            return [
                <Button key="ok"
                        variant="primary"
                        onClick={()=>{
                            // setFlagValid(false);
                            validateToken().then();
                        }}
                        isLoading={ flagLoading }
                        isDisabled={ flagLoading }
                >
                    Aceptar
                </Button>,
                <Button key="calcel"
                        variant="danger"
                        onClick={()=>{
                            navigate('/');
                        }}
                        isLoading={ flagLoading }
                        isDisabled={ flagLoading }
                >
                    Cancelar
                </Button>
            ]
        }

        if (flagHasInfo && newClient.id > 0) {
            return [
                <Button key="login"
                        variant="secondary"
                        onClick={()=>{
                            const now = moment().format('YYYY-MM-DD HH:mm:ss');
                            localStorage.setItem('client.date', now);
                            localStorage.setItem('client.token', TOKEN+'');

                            navigate(`/login`)
                        }}
                        isLoading={ flagLoading }
                        isDisabled={ flagLoading }
                >
                    Iniciar Sesión
                </Button>,
                <Button key="enroll"
                        variant="secondary"
                        onClick={()=>{
                            const now = moment().format('YYYY-MM-DD HH:mm:ss');
                            localStorage.setItem('client.date', now);

                            localStorage.setItem('client.token', TOKEN+'');
                            navigate(`/register`)
                        }}
                        isLoading={ flagLoading }
                        isDisabled={ flagLoading }
                >
                    Registrarse
                </Button>
            ]
        }
        return null
    }, [TOKEN, flagHasInfo, flagLoading, navigate, newClient.id, token, validateToken])

    return (
        <InfoLayout>
            <Bullseye>
                <EmptyState>
                    <EmptyStateHeader titleText={TitlePart} headingLevel="h4" icon={<EmptyStateIcon icon={CubesIcon} />} />
                    <EmptyStateBody>
                        { MessagePart }
                    </EmptyStateBody>
                    <EmptyStateFooter>
                        <EmptyStateActions>
                            { ActionsButtons }
                        </EmptyStateActions>
                    </EmptyStateFooter>
                </EmptyState>
            </Bullseye>
        </InfoLayout>
    )
}
