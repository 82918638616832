import React from "react";
import { ServicesUrl } from "../../Config/UrlRuotes";

import {
    Company,
    LoginActionResponse,
    LoginDispacher,
    LoginPayloadRequest, UserApp,
} from "./AuthDomain";

export async function validateToken(token:string, dispatch:React.Dispatch<LoginDispacher>) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        dispatch({ type: 'REQUEST_LOGIN' });
        let response = await fetch(`${ ServicesUrl.login }/${ token }` , requestOptions);

        let data : LoginActionResponse = await response.json();

        if( data.status === "ERROR" && data.error["has-error"] ){
            dispatch({ type: 'LOGIN_ERROR', error: data.error.error });
            return;
        }

        if (data.response.token ) {
            dispatch({ type: 'LOGIN_SUCCESS', payload: data.response });
            localStorage.setItem('currentUser', JSON.stringify(data.response));
            return data
        }

        return ;
    }catch (error) {
        let message = "Error al momento de realizar la petición"
        dispatch({ type: 'LOGIN_ERROR', error: message});


        return ;
    }
}

export async function loginUser(loginPayload:LoginPayloadRequest, dispatch:React.Dispatch<LoginDispacher>) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(loginPayload),
    };

    try {
        dispatch({ type: 'REQUEST_LOGIN' });
        let response = await fetch(ServicesUrl.login, requestOptions);

        let data : LoginActionResponse = await response.json();

        if( data.status === "ERROR" && data.error["has-error"] ){
            dispatch({ type: 'LOGIN_ERROR', error: data.error.error });
            return data;
        }

        if (data.response.token ) {
            dispatch({ type: 'LOGIN_SUCCESS', payload: data.response });
            localStorage.setItem('currentUser', JSON.stringify(data.response));
            return data
        }

        return data;
    } catch (error) {
        let message = "Error al momento de realizar la petición"
        dispatch({ type: 'LOGIN_ERROR', error: message});

        return (message);
    }
}

export function updateToken(token: string, dispatch: React.Dispatch<LoginDispacher>) {
    // TODO Crear funcionalidad para actualizar el token en localstorage y en context
}

export function updateUser(datas: UserApp, dispatch: React.Dispatch<LoginDispacher>) {
    dispatch({type: "UPDATE_USER", payload: datas});
    let local = JSON.parse(localStorage.getItem("currentUser")+'');
    localStorage.removeItem("currentUser");
    local = {...local, usuario: datas};
    localStorage.setItem("currentUser", JSON.stringify(local))
}

export function updateCompany(datas: Company, dispatch: React.Dispatch<LoginDispacher>) {
    dispatch({type: "UPDATE_COMPANY", payload: datas});
    let local = JSON.parse(localStorage.getItem("currentUser")+'');
    localStorage.removeItem("currentUser");
    local = {...local, empresa: datas};
    localStorage.setItem("currentUser", JSON.stringify((local)));
}



export async function logout(dispatch:React.Dispatch<LoginDispacher>) {
    dispatch({ type: 'LOGOUT' });
    
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
}