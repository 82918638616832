import React from 'react'
import {
    DataGrid,
    Column, DataGridTypes, Editing, Form,
    HeaderFilter,
    MasterDetail,
    Pager,
    Paging, Popup, Search,
    SearchPanel, Selection,
    Sorting, Texts, Toolbar, Item as DGItem, RequiredRule, RangeRule
} from "devextreme-react/data-grid";
import {Item} from "devextreme-react/form";
import {SpeedDialAction} from "devextreme-react/speed-dial-action";
import CustomStore from "devextreme/data/custom_store";
import ProductsServices, {Product} from "../Services/ProductsServices";
import notify from "devextreme/ui/notify";
import {Button} from "devextreme-react/button";
import {CropperDiazar} from "./CropperDiazar";
import {CropperRef} from "react-advanced-cropper";
import {useAuthState} from "../Context/AuthProvider/AuthProvider";
import productsServices from "../Services/ProductsServices";
import {
    Bullseye,
    DataList, DataListCell,
    DataListItem, DataListItemCells,
    DataListItemRow,
    Flex,
    FlexItem,
    Grid,
    GridItem
} from "@patternfly/react-core";
import {Action} from "react-fetching-library";

interface CatProductsDataGridProps {
    isComponent?: boolean;
    selectedProduct?: React.Dispatch<React.SetStateAction<Product | undefined>>;
    reload?: number;
}

/**
 * Represents a data grid component for displaying and managing cat products.
 *
 * @function CatProductsDataGrid
 * @param {CatProductsDataGridProps} props - The props for the CatProductsDataGrid component.
 * @returns {React.Component} The rendered CatProductsDataGrid component.
 */
export const CatProductsDataGrid = (props: CatProductsDataGridProps) => {
    const gridRef = React.useRef<DataGrid>(null);
    const cropperRef = React.useRef<CropperRef>(null);
    // const [selectedRowIndex, setSelectedRowIndex] = React.useState(-1);
    const {token} = useAuthState();
    const [actions, setActions] = React.useState<string>('default');

    const reloadParent = ( props.reload ) ? props.reload : 0;

    /**
     * Represents a DataSource used for loading, inserting, updating, and removing products.
     *
     * @class
     * @constructor
     * @param {object} options - The options for the DataSource.
     * @param {string} options.key - The key used to identify the records in the DataSource.
     * @param {function} options.load - The function to load products from the server.
     * @param {function} options.insert - The function to insert a new product into the server.
     * @param {function} options.update - The function to update an existing product in the server.
     * @param {function} options.remove - The function to remove a product from the server.
     * @returns {DataSource} The DataSource object.
     */
    const DataSource = new CustomStore({
        key: 'ID',
        load: () => {
            return  ProductsServices.getAllProducts(1, token+'')
                .then((resp) => {
                    if (resp?.status && resp.response !== undefined && typeof resp.response !== 'string') {
                        return resp.response?.rows
                    } else {
                        notify({
                                message: resp.response,
                                height: 50,
                                width: 150,
                                type: "error",
                                displayTime: 3500,
                                animation: {
                                    show: {
                                        type: 'fade', duration: 400, from: 0, to: 1,
                                    },
                                    hide: {type: 'fade', duration: 40, to: 0},
                                },
                            },
                            {
                                position: "top right",
                                direction: "down-push"
                            });
                    }
                    return
                })
        },
        insert: async (values) => {
            return await ProductsServices.addProduct(values, token+'')
                .then((resp) => {
                    if (resp?.status && resp.response !== undefined && typeof resp.response !== 'string') {
                            const canvas = cropperRef.current?.getCanvas({
                                height: 500,
                                width: 500
                            });

                            if (canvas) {
                                // @ts-ignore
                                canvas.toBlob((blob) => {
                                    if (blob) {
                                        const file = new File([blob], "photo.jpg", {
                                            type: 'image/jpeg',
                                            lastModified: Date.now()
                                        });

                                        const formData = new FormData();
                                        formData.append('image', file);

                                        ProductsServices.uploadImage(formData, resp.response.ID, token+'')
                                            .then((response) => {
                                                if (response.status) {
                                                    notify({
                                                            message: "Producto agregado",
                                                            height: 50,
                                                            width: 150,
                                                            type: "success",
                                                            displayTime: 3500,
                                                            animation: {
                                                                show: {
                                                                    type: 'fade', duration: 400, from: 0, to: 1,
                                                                },
                                                                hide: {type: 'fade', duration: 40, to: 0},
                                                            },
                                                        },
                                                        {
                                                            position: "top right",
                                                            direction: "down-push"
                                                        });
                                                    gridRef.current?.instance.refresh();
                                                    return response.response;
                                                } else {
                                                    throw Error ("Algo salio mal")  ;
                                                }
                                            })
                                    } else {
                                        throw Error ("Algo salio mal")  ;
                                    }
                                }, 'image/jpeg', 1);
                            }
                        notify({
                                message: "Producto agregado",
                                height: 50,
                                width: 150,
                                type: "success",
                                displayTime: 3500,
                                animation: {
                                    show: {
                                        type: 'fade', duration: 400, from: 0, to: 1,
                                    },
                                    hide: {type: 'fade', duration: 40, to: 0},
                                },
                            },
                            {
                                position: "top right",
                                direction: "down-push"
                            });
                        return resp.response;
                    }
                    notify({
                            message: resp.response,
                            height: 50,
                            width: 150,
                            type: "error",
                            displayTime: 3500,
                            animation: {
                                show: {
                                    type: 'fade', duration: 400, from: 0, to: 1,
                                },
                                hide: {type: 'fade', duration: 40, to: 0},
                            },
                        },
                        {
                            position: "top right",
                            direction: "down-push"
                        });
                    throw Error ("Algo salio mal")  ;
                })
        },
        update: async (key, values) => {
            return await ProductsServices.updateProduct(key, values, token+'')
                .then((resp) => {
                    if (resp?.status && resp.response !== undefined && typeof resp.response !== 'string') {
                        notify({
                                message: "Producto modificado",
                                height: 50,
                                width: 150,
                                type: "success",
                                displayTime: 3500,
                                animation: {
                                    show: {
                                        type: 'fade', duration: 400, from: 0, to: 1,
                                    },
                                    hide: {type: 'fade', duration: 40, to: 0},
                                },
                            },
                            {
                                position: "top right",
                                direction: "down-push"
                            });
                        return resp.response
                    } else {
                        notify({
                                message: resp.response,
                                height: 50,
                                width: 150,
                                type: "error",
                                displayTime: 3500,
                                animation: {
                                    show: {
                                        type: 'fade', duration: 400, from: 0, to: 1,
                                    },
                                    hide: {type: 'fade', duration: 40, to: 0},
                                },
                            },
                            {
                                position: "top right",
                                direction: "down-push"
                            });
                    }
                })
        },
        remove: async (key) => {
            return await ProductsServices.deleteProduct(key, token+'')
                .then((resp) => {
                    if (resp?.status && resp.response !== undefined) {
                        return resp.response
                    } else {
                        notify({
                                message: resp.response,
                                height: 50,
                                width: 150,
                                type: "error",
                                displayTime: 3500,
                                animation: {
                                    show: {
                                        type: 'fade', duration: 400, from: 0, to: 1,
                                    },
                                    hide: {type: 'fade', duration: 40, to: 0},
                                },
                            },
                            {
                                position: "top right",
                                direction: "down-push"
                            });
                    }
                })
        }
    })

    /**
     * La función `selectedChanged` es un manejador de eventos que se invoca cuando cambia la selección de filas en el componente DataGrid.
     *
     * @param {DataGridTypes.SelectionChangedEvent} e - El objeto de evento que contiene detalles sobre la acción de cambio de selección.
     *
     * Aquí se usa para extraer la clave de la fila seleccionada y para obtener el índice de la fila seleccionada.
     * Luego, este índice se pasa a `setSelectedRowIndex`.
     */
    /*const selectedChanged = React.useCallback((e: DataGridTypes.SelectionChangedEvent) => {
        props.selectedProduct?.(e.selectedRowsData[0]);
        setSelectedRowIndex(e.component.getRowIndexByKey(e.selectedRowKeys[0]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSelectedRowIndex]);*/

    const ActionsRow = React.useCallback((action, item?) => {
        setActions(action.element.id)
        switch (action.element.id) {
            case 'SDAAdd':
                gridRef.current?.instance.addRow();
                break;
            case 'SDAEdit':
                gridRef.current?.instance.editRow(item);
                break;
            case 'SDARemove':
                gridRef.current?.instance.deleteRow(item);
                break;
            default:
                gridRef.current?.instance.addRow();
                break;
        }
        gridRef.current?.instance.deselectAll();
    }, [])

    const DetailsCell = React.useCallback( (e) => {
        const data = e.data.data;
        return (
            <Grid>
                <GridItem md={6} sm={12}>
                    <Bullseye style={{minHeight:'250px'}}>
                        {
                            data.imagen ?
                            <img alt="Imagen de producto" src={ data.imagen } width="250px"/> :
                            <div>
                                <p>No hay imagen</p>
                            </div>
                        }
                    </Bullseye>
                </GridItem>

                <GridItem md={6} sm={12}>
                    {
                        data.Detalles  ?
                            <DataList aria-label="details" isCompact>
                                {
                                    data.Detalles.map((detalle, index) => {
                                        return (
                                            <DataListItem key={index}>
                                                <DataListItemRow>
                                                    <DataListItemCells
                                                        dataListCells={[
                                                            <DataListCell width={1} key={`item_${index}_clave`}>
                                                                <strong>{detalle.clave=== 'URL' ? 'Más información' : detalle.clave}</strong>
                                                            </DataListCell>,
                                                            <DataListCell width={2} key={`item_${index}_description`}>{detalle.clave=== 'URL' ? <a href={detalle.descripcion} target="_blank">Clic aquí</a> : detalle.descripcion}</DataListCell>
                                                        ]}
                                                    />
                                                </DataListItemRow>
                                            </DataListItem>
                                        )
                                    })
                                }
                            </DataList> :
                            <p>No hay detalles</p>
                    }
                </GridItem>

            </Grid>
        )
    }, []  );

    const testDetails = React.useCallback((e) => {
        console.log(e);
    },[]);

    const titleFn = React.useCallback(() => {
        return "Agregar recurso";
    }, [])

    const ProductsDataGrid = React.useMemo(() => {
        let id
        let itemSelect = -1

        const onSaved = () => {
            const canvas = cropperRef.current?.getCanvas({
                height: 500,
                width: 500
            });
            if (canvas) {
                // @ts-ignore
                canvas.toBlob((blob) => {
                    if (blob) {
                        const file = new File([blob], "photo.jpg", {
                            type: 'image/jpeg',
                            lastModified: Date.now()
                        });

                        const formData = new FormData();
                        formData.append('image', file);
                        try {
                            productsServices.uploadImage(formData, id, token + '')
                                .then((response) => {
                                    if (response.status) {
                                        notify({
                                                message: "Imagen modificada",
                                                height: 50,
                                                width: 150,
                                                type: "success",
                                                displayTime: 3500,
                                                animation: {
                                                    show: {
                                                        type: 'fade', duration: 400, from: 0, to: 1,
                                                    },
                                                    hide: {type: 'fade', duration: 40, to: 0},
                                                },
                                            },
                                            {
                                                position: "top right",
                                                direction: "down-push"
                                            });
                                        gridRef.current?.instance.refresh();
                                    } else {
                                        throw Error("Algo salio mal")  ;
                                    }
                                });
                        } catch (error) {
                            notify({
                                    message: {error},
                                    height: 50,
                                    width: 150,
                                    type: "error",
                                    displayTime: 3500,
                                    animation: {
                                        show: {
                                            type: 'fade', duration: 400, from: 0, to: 1,
                                        },
                                        hide: {type: 'fade', duration: 40, to: 0},
                                    },
                                },
                                {
                                    position: "top right",
                                    direction: "down-push"
                                });
                        }
                    } else {
                        notify({
                                message: "No pude convertir el archivo",
                                height: 50,
                                width: 150,
                                type: "error",
                                displayTime: 3500,
                                animation: {
                                    show: {
                                        type: 'fade', duration: 400, from: 0, to: 1,
                                    },
                                    hide: {type: 'fade', duration: 40, to: 0},
                                },
                            },
                            {
                                position: "top right",
                                direction: "down-push"
                            });
                    }
                }, 'image/jpeg', 1);
            }
        };

        const selectedChangedInto = (e: DataGridTypes.SelectionChangedEvent) => {
                props.selectedProduct?.(e.selectedRowsData[0]);
                itemSelect = e.component.getRowIndexByKey(e.selectedRowKeys[0])
                // setSelectedRowIndex(e.component.getRowIndexByKey(e.selectedRowKeys[0]));
                // eslint-disable-next-line react-hooks/exhaustive-deps
        };

        return <div className="custom-datagrid">
        <DataGrid
            id="ProductsDataGrid"
            dataSource={DataSource}
            ref={gridRef}
            showBorders={false}
            onSelectionChanged={selectedChangedInto}
            rowAlternationEnabled={true}
            columnAutoWidth={true}
            showRowLines={false}
            showColumnLines={true}
            onSaved={onSaved}
            onEditingStart={ (e) => id = e.key }
        >
            <Selection mode="single"/>
            <Sorting mode="multiple"/>
            <Paging defaultPageSize={props.isComponent ? 20 : 10}/>
            <Pager
                visible={true}
                allowedPageSizes={props.isComponent ? "auto" : [10]}
                showPageSizeSelector={props.isComponent}
                showInfo={true}
                showNavigationButtons={true}
                infoText="Página {0} de {1} ({2} recursos)"/>
            <SearchPanel visible={true}/>
            <HeaderFilter visible={true}/>
            <MasterDetail
                enabled={true}
                component={DetailsCell}
            />
            <Column dataField="clave" caption="SKU/Clave">
                <HeaderFilter allowSelectAll={true}>
                    <Search enabled={true}/>
                </HeaderFilter>
                <RequiredRule type="required" message="Necesitamos identificarlo de alguna manera"/>
            </Column>
            <Column dataField="descripcion" caption="Descripción">
                <HeaderFilter allowSelectAll={true}>
                    <Search enabled={true}/>
                </HeaderFilter>
                <RequiredRule type="required" message="Debes añadir una descripción"/>
            </Column>
            <Column dataField="unidad" caption="Unidad">
                <HeaderFilter allowSelectAll={true}>
                    <Search enabled={true}/>
                </HeaderFilter>
                <RequiredRule type="required" message="Necesito saber la unidad"/>
            </Column>
            <Column dataField="precio_por_unidad" caption="Precio Unitario" format={{type: 'currency', precision: 2}}>
                <HeaderFilter allowSelectAll={true}>
                    <Search enabled={true}/>
                </HeaderFilter>
                <RequiredRule type="required" message="¿GRATIS? no lo creo, necesitas colocar un precio al recurso"/>
                <RangeRule type="range" min={1} message="¿Seguro?"/>
            </Column>
            <Selection mode="single"/>
            <Editing mode="popup">
                <Popup showTitle={true} maxWidth={750} minWidth={350} height={750} title={ titleFn() }/>
                <Texts
                    confirmDeleteMessage="¿Estas seguro que quieres eliminar este recurso?"
                />
                <Form>
                    <Item itemType="group" colCount={2} colSpan={2}>
                        <Item dataField="clave"/>
                        <Item dataField="descripcion"/>
                        <Item dataField="unidad"/>
                        <Item dataField="precio_por_unidad"/>
                        <Item colCount={2} colSpan={2}>
                            <Flex justifyContent={{default: 'justifyContentCenter'}} alignItems={{default: 'alignItemsCenter'}}>
                                <FlexItem style={{width: '80%'}} alignSelf={{default: "alignSelfCenter"}}>
                                    <CropperDiazar cropperRef={cropperRef} withPreview={false}/>
                                </FlexItem>
                            </Flex>
                        </Item>
                    </Item>
                </Form>
            </Editing>
            <Toolbar>
                <DGItem name="searchPanel" />
                <DGItem location="after">
                    <Button icon="trash" onClick={(e) => ActionsRow(e, itemSelect)} id="SDARemove" />
                </DGItem>
                <DGItem location="after">
                    <Button icon="edit" onClick={(e) => ActionsRow(e, itemSelect)} id="SDAEdit" />
                </DGItem>
                <DGItem location="after">
                    <Button text="Agregar recurso" type="default" icon="add" onClick={(e) => ActionsRow(e)} id="SDAAdd"/>
                </DGItem>
            </Toolbar>
        </DataGrid>
        </div>
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [reloadParent])

    return (
        <>
            {ProductsDataGrid}
            { /* props.isComponent &&
                <>
                    <SpeedDialAction
                        icon="add"
                        label="Agregar"
                        index={1}
                        onClick={ActionsRow}
                        id="SDAAdd"
                    />
                    <SpeedDialAction
                        icon="edit"
                        label="Modificar"
                        index={2}
                        visible={selectedRowIndex !== undefined && selectedRowIndex !== -1}
                        onClick={ActionsRow}
                        id="SDAEdit"
                    />
                    <SpeedDialAction
                        icon="trash"
                        label="Eliminar"
                        index={3}
                        visible={selectedRowIndex !== undefined && selectedRowIndex !== -1}
                        onClick={ActionsRow}
                        id="SDARemove"
                    />
                </>
            */}
        </>
    )
}
