import React from 'react';
import {
    Card,
    CardTitle,
    CardBody,
    Grid,
    GridItem,
    CardHeader,
    Skeleton
} from '@patternfly/react-core';
import { useNavigate } from "react-router-dom";
import {IQuote} from "../Services/QuotesServices";

interface ViewProps {
    datas: IQuote[]
    ignore: boolean;
    isLoading: boolean;
}

export const DiazarCardsLists : React.FunctionComponent<ViewProps> = (props) => {
    const navigate = useNavigate();

    const renderItems = () => {

        return props.datas.map((item, index) =>
            {
                if (item.recibida === props.ignore) {
                    return null
                } else {
                    return (
                        <GridItem key={index} >
                            <Card id={`card-${item.solicitud_cotizacion_id}`} key={`card-${item.solicitud_cotizacion_id}`} isCompact isClickable isExpanded={true}
                                  style={item.recibida ? {minHeight: '150px', border: '2px solid #001d44', borderRadius: '20px'}:
                                      {minHeight: '150px', border: '2px solid #ff8d0a', borderRadius: '20px'}} aria-labelledby={`card-${item.solicitud_cotizacion_id}`}
                                  onClick={ ()=>{ navigate(`/quotes/${item.solicitud_cotizacion_id}`) } } className="quote-card"
                            >
                                <CardHeader
                                    isToggleRightAligned={true}
                                    toggleButtonProps={{
                                        id: 'toggle-button1',
                                        'aria-label': 'Details',
                                        'aria-labelledby': 'expandable-card-title toggle-button1',
                                        'aria-expanded': true
                                    }}
                                >
                                    <CardTitle>Folio Cotización: {item.folio}</CardTitle>
                                </CardHeader>
                                <CardBody >
                                    <p>Proyecto: {item.proyecto} ({item.proyecto_id})</p>
                                    <p>Fecha: {new Date(item.fecha_envio).toLocaleDateString('es-MX', {
                                        day: 'numeric',
                                        month: 'short',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })} hrs</p>
                                    <p>Notas: {item.nota_de_envio}</p>
                                    <p>Observación: {item.observacion}</p>
                                </CardBody>
                            </Card>
                        </GridItem>
                    )
                }
            }
        )
    }

    const SkeletonItems = () => {
        return (
            <>
                <GridItem key="skeletonItems">
                    <Skeleton height="100px" width="100%" screenreaderText="Loading small rectangle contents" />
                </GridItem>
                <GridItem key="skeletonItems1">
                    <Skeleton height="100px" width="100%" screenreaderText="Loading small rectangle contents" />
                </GridItem>
                <GridItem key="skeletonItems2">
                    <Skeleton height="100px" width="100%" screenreaderText="Loading small rectangle contents" />
                </GridItem>
                <GridItem key="skeletonItems3">
                    <Skeleton height="100px" width="100%" screenreaderText="Loading small rectangle contents" />
                </GridItem>
                <GridItem key="skeletonItems4">
                    <Skeleton height="100px" width="100%" screenreaderText="Loading small rectangle contents" />
                </GridItem>
            </>
        )
    }

    return (
        <Grid hasGutter={true} md={4} xl={12}>
            {props.isLoading ? SkeletonItems() : renderItems()}
        </Grid>
    );
};
