import { DiazarSuiteDispacher, DiazarSuitePayload } from "./DiazarSuiteProviderDomain";

export const diazarSuiteInitialState:DiazarSuitePayload = {
    errorMessage: '',
    loading: false,
    apps: []
}
export let DiazarSuiteReducer: React.Reducer<DiazarSuitePayload, DiazarSuiteDispacher> ;

DiazarSuiteReducer = ( initialState, action ) : DiazarSuitePayload => {
    switch ( action.type ) {
        case 'REQUEST':
            return { ...initialState, loading: true }

        case 'SUCCESS':
            return { ...initialState, loading: false, errorMessage:'', apps: action.payload?.apps }

        case 'ERROR':
            return { ...initialState, loading: false, errorMessage:action.payload?.errorMessage }

        default:
            return initialState
    }
}