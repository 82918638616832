import {ServicesUrl} from "../Config/UrlRuotes";

interface Error {
    "has-error": boolean;
    error?: string;
}

interface Category {
    "ID": number;
    "CreatedAt": string;
    "UpdatedAt": string;
    "DeletedAt": string;
    "clave": string;
}

interface GACategories {
    status: "SUCCESS" | "ERROR";
    error: Error;
    response: Category[];
}

export interface GetAllCategoriesResponse {
    status: boolean,
    response: Category[] | string | undefined;
}

const getAllCategories = async (token: string): Promise<GetAllCategoriesResponse> => {
    const response = await fetch(ServicesUrl.categories, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

    const data: GACategories = await response.json();

    if (data.status === 'SUCCESS') {
        return {
            status: true,
            response: data.response
        }
    } else {
        return {
            status: false,
            response: data.error.error
        }
    }
}

const exportedObjects = {getAllCategories};
export default exportedObjects;