import React from "react";
import {PageLayout} from "../../Layouts/PageLayout";
import {CatProductsDataGrid} from "../../Components/CatProductsDataGrid";
import {Button, Flex, FlexItem} from "@patternfly/react-core";
import {FaFileExcel} from "react-icons/fa";

import ProductsServices from "../../Services/ProductsServices";

import * as XLSX from 'xlsx';
import notify from "devextreme/ui/notify";
import {useAuthState} from "../../Context/AuthProvider/AuthProvider";


/**
 * Represents the products page component.
 * @constructor
 */

const requiredColumns = ['clave', 'descripcion', 'precio_por_unidad', 'unidad'];
export const ProductsPage = () => {
    const [reload, setReload] = React.useState<number>(0);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const inputFileRef = React.useRef<HTMLInputElement>(null);
    const {token} = useAuthState();

    const getExcelHandle = React.useCallback(()=>{
        if( inputFileRef.current ){
            inputFileRef.current.click();
        }
        //alert('Cargar productos');
        // setReload(prev => {
           // return prev + 1;
        //})
    }, [inputFileRef])

    const title = React.useMemo(() => {
        return (
            <React.Fragment>
                <Flex>
                    <FlexItem>
                        Catálogo de productos
                    </FlexItem>
                    <FlexItem align={{ default: 'alignRight' }}>
                        <Button variant="primary"
                                icon={<FaFileExcel size={16} style={{marginTop:'1px'}}/>}
                                iconPosition="start"
                                style={{fontSize: '14px', paddingBottom: '7px'}}
                                onClick={() => {
                                    getExcelHandle();
                                }}
                                isLoading={isLoading}
                                isDisabled={isLoading}
                        >
                            Cargar productos
                        </Button>
                    </FlexItem>
                </Flex>

            </React.Fragment>
        )
    }, [getExcelHandle, isLoading]);

    const showErrorMessage = React.useCallback((message: string)=>{
        notify({
                message: message,
                height: 50,
                width: 150,
                type: "error",
                displayTime: 3500,
                animation: {
                    show: {
                        type: 'fade', duration: 400, from: 0, to: 1,
                    },
                    hide: {type: 'fade', duration: 40, to: 0},
                },
            },
            {
                position: "top right",
                direction: "down-push"
            });
    },[]);


    const handleFile = React.useCallback( async (e: any) => {
        console.log('reading input file:');
        const file = e.target.files[0];
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data);
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            defval: "",
            rawNumbers: true
        });

        if( jsonData[0] === undefined ){
            showErrorMessage('El archivo no tiene el formato correcto');
        }else{
            setIsLoading(true);
            // Procesamos los Heaeders
            const titles = {};
            const headers = jsonData[0] as string[];

            headers.forEach((header, index) => {
                titles[header] = index;
            });

            const noTitles: string[] = []
            requiredColumns.forEach((column) => {
                if( titles[column] === undefined ){
                    noTitles.push(column);
                }
            });

            if (noTitles.length > 0) {
                showErrorMessage(`El archivo no tiene las columnas requeridas: "${noTitles.join('", "')}"`);
            }else{
                // Procesamos los datos
                const products = jsonData.slice(1).map((row : any) => {
                    const product = {};
                    headers.forEach((header, index) => {
                        if (header === 'precio_por_unidad') {
                            product[header] = parseFloat(row[index]);
                        }else {
                            product[header] = row[index] + "";
                        }
                    });
                    return product;
                });


                ProductsServices.updateBathProduct(products, token + '').then((data) => {
                    if (data.error["has-error"]){
                        const errorMessage = (data.error.error) ? data.error.error : 'Error al cargar los productos';
                        showErrorMessage( errorMessage );
                    }else {
                        setReload(prev => {
                            return prev + 1;
                        })
                    }
                }).finally(()=>{
                    setIsLoading(false);
                });
            }
        }



        if( inputFileRef.current ){
            inputFileRef.current.value = "";
        }
    }, [showErrorMessage, token] );

    return <PageLayout title={title}
                breadcrumbs={ [{title:'Inicio', to: '/'}, {title:'Catálogos', to: '/' }, {title:'Productos', isActive: true}] }
    >
        <div style={{ backgroundColor: "white"}}>
            <div style={{display: "none"}}>
                <input type="file" onInput={handleFile} ref={inputFileRef}/>
            </div>
            <CatProductsDataGrid isComponent={true} reload={reload} />
        </div>
    </PageLayout>
}
