import React from 'react';
import {PageLayout} from "../../Layouts/PageLayout";
import {DiazarToolbar} from "../../Components/DiazarToolbar";
import {QuotesListPerClients} from "../../Components/QuotesListPerClients";
import {QuotesCardPerClients} from "../../Components/QuotesCardPerClients";

export const QuotesPagePerClient: React.FunctionComponent = () => {
    const [mode, setMode] = React.useState<'grid' | 'list'>('list');
    const description = "";

     return (
         <PageLayout title="Cotizaciones por cliente"
                     description={description}
                     breadcrumbs={ [{title:'Inicio', to: '/'}, {title:'Cotizaciones por cliente', to:'/quotesclients', isActive: true}] }
        >
             <DiazarToolbar actions={{
                 mode: setMode,
                 initialMode: 'list'
             }}/>
             {mode === 'list' ? <QuotesListPerClients /> : <QuotesCardPerClients />}
        </PageLayout>
     )
}