const ROOT_URL = '/api';
const API_URL = '/diazar_suite';

export const APP_ID = 1;
export const COOKIE_NAME = 'diazar-suite'

export const ServicesUrl = {
    api: `${ROOT_URL}`,
    login: `${ROOT_URL}/login`,
    info: `${ROOT_URL}/info`,
    users: `${ROOT_URL}/users`,
    clients: `${ROOT_URL}/clients`,
    notifications: `${ROOT_URL}/notifications`,
    products: `${ROOT_URL}/catalogs`,
    categories: `${ROOT_URL}/company/categories`,
    feedback: `${ROOT_URL}/feedback`,

    // apps: `${API_URL}/aplications/get/${ APP_ID }.json`,
    appsValidate: `${API_URL}/aplications/validate_token/${ APP_ID }.json`,
}
