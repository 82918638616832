import { createFetch } from "simple-fetch";
import { ServicesUrl } from "../Config/UrlRuotes";

interface Error {
    "has-error": boolean;
    error: string;
}

interface ResetPasswordResponse {
    status: string;
    error: Error;
    response: string;
}

export interface RegisterResponse {
    status: string;
    error: Error;
    response: string;
}

export interface User {
    "ID": number;
    "CreatedAt": string;
    "UpdatedAt": string;
    "DeletedAt": string;
    "nombres": string;
    "apellidos": string;
    "email": string;
    "password": string;
    "imagen": string;
    "empresa_id": number;
}

export interface updateUserResponse {
    status: string;
    error: Error;
    response: User;
}

const USER = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser') + "") : {};
const TOKEN = USER.token

const  {getJson, postJson} = createFetch();

const {putJson} = createFetch({
    headers: {
        Authorization: `Bearer ${TOKEN}`
    }
});

const register = async (password: string, email: string, name: string, lastname: string): Promise<RegisterResponse> => {
    return await postJson(ServicesUrl.users, {
        nombres: name,
        apellidos: lastname,
        email: email,
        password: password
    });
}

const resetPassword = async (email: string) : Promise<ResetPasswordResponse> => {
    return await postJson(`${ServicesUrl.users}/forgot_password`, {
        email: email
    })
}

const validateResetPasswordToken = async (token: string) => {
    return await getJson(`${ServicesUrl.users}/forgot_password/${token}`)
}

const resetPasswordAction = async (token, password) => {
    return await putJson(`${ServicesUrl.users}/forgot_password/${token}`, {password: password})
}

const updateUser = async (datas: object, id:string, token: string) : Promise<updateUserResponse> => {
    const response = await fetch(`${ServicesUrl.users}/${id}`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(datas)
    });

    return await response.json();
}

const updateImage = async (image, id: string, token: string) => {
    const response = await fetch(`${ServicesUrl.users}/${id}/image`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body: image
    });

    return await response.json();
}

const exportedObjects = { resetPassword, validateResetPasswordToken, updateUser, resetPasswordAction, register, updateImage }
export default exportedObjects;