import React from "react";

import {
    LoginPage,
    LoginMainFooterBandItem,
    Button
} from "@patternfly/react-core";
import { big_reverse_logo } from "../Config/Images";
import {ListItemsInfoLinks} from "../Components/ListItemsInfoLinks";
import {DiazarAlerts} from "../Components/DiazarAlerts";
import {DiazarBackgroundImage} from "../Components/DiazarBackgroundImage";
import { Link, useLocation } from "react-router-dom";
import { SpeedDialAction } from 'devextreme-react/speed-dial-action';
import { Modal, ModalVariant } from '@patternfly/react-core';
import {FeedbackForm} from "../Components/FeedbackForm";
import {LoadPanel} from "devextreme-react/load-panel";


interface ViewProps{
    children?: React.ReactNode;
    textContent?: string;
    loginTitle: string;
    loginSubtitle?: string;
}

export const LoginLayout: React.FunctionComponent<ViewProps> = (props) => {
    const [isOpenModal, setIsOpenModal] = React.useState(false)
    const [sendingFeedback, setSendingFeedback] = React.useState<boolean>(false);
    const location = useLocation();


    /*const signUpForAccountMessage = React.useMemo( () => (
        <LoginMainFooterBandItem>
            ¿Necesitas <Link to="/login">Ayuda?</Link>.
        </LoginMainFooterBandItem>
    ), []);*/

    const login = (
        <LoginMainFooterBandItem>
            Si tienes una cuenta, <Link to="/login">accede al portal</Link>
        </LoginMainFooterBandItem>
    );

    const forgotCredentials = (
        <LoginMainFooterBandItem>
            <Button variant="link" isInline>
                <Link to="/reset-password">
                    ¿Olvidaste tu contraseña?
                </Link>
            </Button>
        </LoginMainFooterBandItem>
    );

    /*const socialMediaLoginContent = (
        <React.Fragment>
            <LoginMainFooterLinksItem href="https://www.facebook.com/DiazarControl/"
                                      linkComponentProps={{ 'aria-label': 'Facebook' }}
                                      target="_blank"
            >
                <Icon size="lg">
                    <FacebookFIcon />
                </Icon>
            </LoginMainFooterLinksItem>
            <LoginMainFooterLinksItem href="https://twitter.com/diazarcontrol"
                                      linkComponentProps={{ 'aria-label': 'Twitter' }}
                                      target="_blank"
            >
                <Icon size="lg">
                    <TwitterIcon />
                </Icon>
            </LoginMainFooterLinksItem>
            <LoginMainFooterLinksItem href="https://www.youtube.com/user/DiazarNoticias"
                                      linkComponentProps={{ 'aria-label': 'Youtube' }}
                                      target="_blank"
            >
                <Icon size="lg">
                    <YoutubeSquareIcon />
                </Icon>
            </LoginMainFooterLinksItem>
            <LoginMainFooterLinksItem href="https://www.linkedin.com/in/diazar-control-5068323b"
                                      linkComponentProps={{ 'aria-label': 'Linkedin' }}
                                      target="_blank"
            >
                <Icon size="lg">
                    <LinkedinIcon/>
                </Icon>
            </LoginMainFooterLinksItem>
        </React.Fragment>
    );*/

    const handleModalToggle = () => {
        setIsOpenModal(false);
    }

    return (
        <React.Fragment>
            <DiazarBackgroundImage />
            <LoginPage
                brandImgAlt="Diazar Logo"
                brandImgSrc={ big_reverse_logo }
                
                footerListItems={ <ListItemsInfoLinks /> }
                textContent={ props.textContent }
                loginTitle={ props.loginTitle }
                loginSubtitle={ props.loginSubtitle }
                //forgotCredentials={location.pathname === '/reset-password' ? login : forgotCredentials}
            >
                <DiazarAlerts />
                { props.children }
                {/*<SpeedDialAction
                    icon="tips"
                    label="Feedback"
                    index={1}
                    onClick={() => setIsOpenModal(true)} />*/}
            </LoginPage>
            <Modal
                variant={ModalVariant.small}
                title="¡Nos encantaría escuchar tu idea!"
                description="Por favor llena el siguiente formulario y platicanos tu idea."
                isOpen={isOpenModal}
                onClose={handleModalToggle}
                id="feedbackLogin"
            >
                <FeedbackForm sendingAction={setSendingFeedback} isOpenModalAction={setIsOpenModal}/>
            </Modal>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: '#feedbackLogin'}}
                visible={sendingFeedback}
                showIndicator={false}
                message="Enviando..."
            />
        </React.Fragment>
    );
}