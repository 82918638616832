import React from 'react';
import {
    Divider,
    Grid, GridItem
} from "@patternfly/react-core";
// import TextBox from "devextreme-react/text-box";
// import {Button} from "devextreme-react/button";
import { LoadPanel } from 'devextreme-react/load-panel';
// import Validator, {RequiredRule} from "devextreme-react/validator";
import CompanyServices from '../Services/CompanyServices';
import {updateCompany} from "../Context/AuthProvider/AuthProviderActions";
import notify from "devextreme/ui/notify";
// import ValidationGroup from 'devextreme-react/cjs/validation-group';
import { useAuthDispatch, useAuthState } from "../Context/AuthProvider/AuthProvider";
// import { TagBox } from 'devextreme-react/tag-box';
//import CustomStore from "devextreme/data/custom_store";
//import CategoryServices from "../Services/CategoryServices";
import {FormHelperText, Form, Button, ActionGroup} from "@patternfly/react-core";
import {ExclamationCircleIcon} from "@patternfly/react-icons";
import {DiazarFormInput} from "./Form/DiazarFormInput";
//import { LabelGroup, Label, Menu, MenuContent, MenuList, MenuItem, Popper } from '@patternfly/react-core';
import {DiazarBusinessCategoriesTags} from "./DiazarBusinessCategoriesTags";

export const FormCompany = () => {
    const { usuario } = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser') + "") : {usuario: ''};
    //const [status, setStatus] = React.useState<boolean>(true);
    //const [textButton, setTextButton] = React.useState<string>('Editar datos');
    //const [iconButton, setIconButton] = React.useState<string>('edit');
    //const [Stateloading, setStateLoading] = React.useState<boolean>(false);
    const [name, setName] = React.useState<string>( '');
    const [isValidName, setIsValidName] = React.useState<boolean>(false);
    const [businessName, setBusinessName] = React.useState<string>('');
    const [isValidBusinessName, setIsValidBusinessName] = React.useState<boolean>(false);
    const [RFC, setRFC] = React.useState<string>('');
    const [isValidRFC, setIsValidRFC] = React.useState<boolean>(false);
    const dispatch = useAuthDispatch()
    const {token, empresa} = useAuthState();
    const { loading, errorMessage } = useAuthState();
    const [categories, setCategories] = React.useState<any[]>([]);
    const dataName = empresa?.nombre;
    const dataBusinessName = empresa?.razon_social;
    const dataRFC = empresa?.rfc

    /*const DataSource = new CustomStore ({
        key: 'ID',
        byKey: (key) => {
                return categories.find((elem) => elem.ID === key);
        },
        load: () => {
            return CategoryServices.getAllCategories(token+'')
                .then((resp) => {
                    if (resp.status) {
                        return resp.response
                    } else {
                        return
                    }
                })
        }
    })*/

    const getCompany = async () => {
        try {
            if (empresa && empresa.ID) {
                CompanyServices.getCompany(empresa.ID+'', token + '')
                    .then((resp) => {
                        if (resp.status) {
                            updateCompany(resp.response, dispatch);
                            setName(resp.response.nombre);
                            setRFC(resp.response.rfc);
                            setBusinessName(resp.response.razon_social);
                            setCategories(resp.response.categorias);
                        }
                    })
            }
        } catch (error) {
            notify({
                    message: 'No pude cargar los datos de la empresa',
                    height: 50,
                    width: 150,
                    type: "error",
                    displayTime: 3500,
                    animation: {
                        show: {
                            type: 'fade', duration: 400, from: 0, to: 1,
                        },
                        hide: {type: 'fade', duration: 40, to: 0},
                    },
                },
                {
                    position: "top right",
                    direction: "down-push"
                })
        }
    }

    const handleEdit = (e) => {
        //const rules = e.validationGroup.validate();

        /*if (status) {
            setStatus(false);
            setTextButton('Guardar cambios');
            setIconButton('save');
        } else {
            setStateLoading(true);
            if (rules.isValid) {*/
                const id = usuario?.ID+''
                try {
                    CompanyServices.updateCompany({nombre: name, razon_social: businessName, rfc: RFC, categorias: categories}, id, token+'')
                        .then((resp) => {
                            if (resp.response.status) {
                                updateCompany(resp.response.response, dispatch);
                                //setStatus(true);
                                //setTextButton('Editar datos');
                                //setIconButton('edit');
                                notify({
                                        message: 'Actualizado con éxito',
                                        height: 50,
                                        width: 150,
                                        type: "success",
                                        displayTime: 3500,
                                        animation: {
                                            show: {
                                                type: 'fade', duration: 400, from: 0, to: 1,
                                            },
                                            hide: {type: 'fade', duration: 40, to: 0},
                                        },
                                    },
                                    {
                                        position: "top right",
                                        direction: "down-push"
                                    })
                            } else {
                                notify({
                                        message: 'resp.error.error',
                                        height: 50,
                                        width: 150,
                                        type: "error",
                                        displayTime: 3500,
                                        animation: {
                                            show: {
                                                type: 'fade', duration: 400, from: 0, to: 1,
                                            },
                                            hide: {type: 'fade', duration: 40, to: 0},
                                        },
                                    },
                                    {
                                        position: "top right",
                                        direction: "down-push"
                                    })
                            }
                            //setStateLoading(false);
                        })
                } catch {
                    notify({
                            message: 'Algo salio mal con la operación.',
                            height: 50,
                            width: 150,
                            type: "error",
                            displayTime: 3500,
                            animation: {
                                show: {
                                    type: 'fade', duration: 400, from: 0, to: 1,
                                },
                                hide: { type: 'fade', duration: 40, to: 0 },
                            },
                        } ,
                        {
                            position : "top right",
                            direction: "down-push"
                        })
                    //setStateLoading(false);
                }
            /*} else {
                notify({
                        message: 'Por favor, revisa tus datos.',
                        height: 50,
                        width: 150,
                        type: "warning",
                        displayTime: 3500,
                        animation: {
                            show: {
                                type: 'fade', duration: 400, from: 0, to: 1,
                            },
                            hide: { type: 'fade', duration: 40, to: 0 },
                        },
                    } ,
                    {
                        position : "top right",
                        direction: "down-push"
                    })
                setStateLoading(false);
            }*/
        //}
    }

    /*const valueChanged = (e) => {
        switch (e.element.id) {
            case "name":
                setName(e.value);
                break;
            case "businessName":
                setBusinessName(e.value)
                break;
            case "RFC":
                setRFC(e.value);
                break;
        }
    }*/

    const dateFormat = (date: Date) => date.toLocaleDateString('es-MX', { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit'}).replace(/\//g, '-');

    /*const onValueChangedTag = (e) => {
        setCategories(e.value);
    };*/

    const isValidForm = React.useMemo<boolean>( () => {
        const isLoading = ( loading !== undefined ) ? loading : false
        if (!isLoading && isValidName && isValidBusinessName && isValidRFC && categories.length !== empresa?.categorias?.length ) {
            return name !== dataName || businessName !== dataBusinessName || RFC !== dataRFC || categories !== empresa?.categorias;
        } else {
            return false
        }

    }, [categories, loading, isValidName, isValidBusinessName, isValidRFC, empresa?.categorias, name, dataName, businessName, dataBusinessName, RFC, dataRFC] )

    const helperText = React.useMemo( () => {
        if ( errorMessage === undefined ){
            return null
        }else{
            return (
                <FormHelperText  >
                    <ExclamationCircleIcon />
                    &nbsp;{errorMessage}.
                </FormHelperText>
            )
        }
    }, [errorMessage] )

    React.useEffect(() => {
        if (RFC.length === 0) {
            getCompany();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Form>
                {helperText}
                <Grid hasGutter id="company" style={{padding: "20px"}} >
                    <GridItem md={4} mdOffset={4} sm={12}>
                        <DiazarFormInput id="name" label="Nombre de la empresa" isRequired
                                         type="text"
                                         placeholder="Ingresa el nombre de la empresa"
                                         getState={(isValid, value) => {
                                             setIsValidName(isValid)
                                             setName(value)
                                         }}
                                         min={3}
                                         initialValue={dataName && dataName}
                        />
                    </GridItem>
                    <GridItem md={4} mdOffset={4} sm={12}>
                        <DiazarFormInput id="businessName" label="Razón social" isRequired
                                         type="text"
                                         placeholder="Ingresa la razón social de la empresa"
                                         getState={(isValid, value) => {
                                             setIsValidBusinessName(isValid)
                                             setBusinessName(value)
                                         }}
                                         min={10}
                                         initialValue={dataBusinessName && dataBusinessName}
                        />
                    </GridItem>
                    <GridItem md={4} mdOffset={4} sm={12}>
                        <DiazarFormInput id="rfc" label="Registro Federal de Contribuyentes" isRequired
                                         type="text"
                                         placeholder="Ingresa el RFC de la empresa"
                                         getState={(isValid, value) => {
                                             setIsValidRFC(isValid)
                                             setRFC(value)
                                         }}
                                         min={10}
                                         initialValue={dataRFC && dataRFC }
                        />
                    </GridItem>
                    <GridItem md={4} mdOffset={4} sm={12}>
                        <DiazarBusinessCategoriesTags actionCategories={setCategories} />
                    </GridItem>
                    <GridItem md={4} mdOffset={4} sm={12}>
                        <ActionGroup>
                            <Button type="submit"
                                    onClick={ e => { e.preventDefault(); handleEdit(e) }}
                                    isBlock
                                    isDisabled={ !isValidForm }
                                    isLoading={ ( loading !== undefined ) ? loading : false }
                            >
                                Guardar
                            </Button>
                        </ActionGroup>
                    </GridItem>
                    <Divider />
                    <GridItem span={12} style={{textAlign: "right", fontSize: '12px'}}>
                        <p>creado: {dateFormat( new Date(empresa?.CreatedAt || ''))}</p>
                        <p>actualizado: {dateFormat( new Date(empresa?.UpdatedAt || ''))}</p>
                    </GridItem>
                </Grid>
            </Form>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: '#company'}}
                visible={loading}
                showIndicator={false}
                message="Guardado..."
            />
        </>
    )
}