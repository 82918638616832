import React from "react";
import {
    DescriptionList,
    DescriptionListTerm,
    DescriptionListGroup,
    DescriptionListDescription
} from '@patternfly/react-core';
import TextArea, {TextAreaTypes} from "devextreme-react/text-area";
import QuotesServices from "../Services/QuotesServices";
import notify from "devextreme/ui/notify";
import {useAuthState} from "../Context/AuthProvider/AuthProvider";

interface QuoteFooterProps {
    quoteId: string | undefined;
    nota: string;
    condPago: string;
    editable: boolean;
}

export const QuoteFooter = (props: QuoteFooterProps) => {
    const ID  = props.quoteId === undefined ? '': props.quoteId
    const [notasProv, setNotasProv] = React.useState<string>(props.nota);
    const [condPago, setCondPago] = React.useState<string>(props.condPago);
    const {token} = useAuthState();

    /**
     * Executes when the value of the text area changes.
     * @param {TextAreaTypes.ValueChangedEvent} e - The value changed event.
     */
    const onTextAreaValueChanged = React.useCallback((e: TextAreaTypes.ValueChangedEvent) => {
        if (props.editable) {
            let valueOld =''
            if (e.element.id === 'notasProv') {
                valueOld = notasProv;
                setNotasProv(e.value);
                try {
                    QuotesServices.putQuoteDataByIdRequest(ID, "nota_de_respuesta", e.value, token + '')
                        .then((resp) => {
                            !resp.status && setNotasProv(valueOld);
                            !resp.status && notify({
                                    message: resp.response,
                                    height: 50,
                                    width: 150,
                                    type: "error",
                                    displayTime: 5000,
                                    animation: {
                                        show: {
                                            type: 'fade', duration: 400, from: 0, to: 1,
                                        },
                                        hide: {type: 'fade', duration: 40, to: 0},
                                    },
                                },
                                {
                                    position: "top right",
                                    direction: "down-push"
                                });
                        })
                } catch (error) {
                    notify({
                            message: 'No se pudo actualizar la información.',
                            height: 50,
                            width: 150,
                            type: "error",
                            displayTime: 5000,
                            animation: {
                                show: {
                                    type: 'fade', duration: 400, from: 0, to: 1,
                                },
                                hide: {type: 'fade', duration: 40, to: 0},
                            },
                        },
                        {
                            position: "top right",
                            direction: "down-push"
                        })
                    setNotasProv(valueOld);
                }
            } else {
                valueOld = condPago;
                setCondPago(e.value);
                try {
                    QuotesServices.putQuoteDataByIdRequest(ID, "condiciones_de_pago", e.value, token + '')
                        .then((resp) => {
                            !resp.status && setCondPago(valueOld);
                            !resp.status && notify({
                                    message: resp.response,
                                    height: 50,
                                    width: 150,
                                    type: "error",
                                    displayTime: 5000,
                                    animation: {
                                        show: {
                                            type: 'fade', duration: 400, from: 0, to: 1,
                                        },
                                        hide: {type: 'fade', duration: 40, to: 0},
                                    },
                                },
                                {
                                    position: "top right",
                                    direction: "down-push"
                                });
                        })
                } catch (error) {
                    notify({
                            message: 'No se pudo actualizar la información.',
                            height: 50,
                            width: 150,
                            type: "error",
                            displayTime: 5000,
                            animation: {
                                show: {
                                    type: 'fade', duration: 400, from: 0, to: 1,
                                },
                                hide: {type: 'fade', duration: 40, to: 0},
                            },
                        },
                        {
                            position: "top right",
                            direction: "down-push"
                        });
                    setCondPago(valueOld);
                }
            }
        }
    }, [ID, condPago, notasProv, token]);

    React.useEffect(() => {
        setNotasProv(props.nota);
        setCondPago(props.condPago);
    }, [props.nota, props.condPago]);

    return (
        <div style={{backgroundColor: "white", padding: "20px", marginBottom: '20px'}}>
            <DescriptionList
                columnModifier={{
                    sm: '1Col',
                    md: '2Col',
                }}
            >
                <DescriptionListGroup>
                    <DescriptionListTerm>Notas:</DescriptionListTerm>
                    <DescriptionListDescription>
                        <TextArea
                            id="notasProv"
                            height={70}
                            value={notasProv}
                            placeholder={props.editable ? "¿Algún comentario que quieras notificar al cliente?" : "No hubieron notas"}
                            inputAttr={{ 'aria-label': 'Notes del Proveedor' }}
                            valueChangeEvent="focusout"
                            onValueChanged={onTextAreaValueChanged}
                            readOnly={!props.editable}
                        />
                    </DescriptionListDescription>
                </DescriptionListGroup>
                <DescriptionListGroup>
                    <DescriptionListTerm>Condiciones de pago:</DescriptionListTerm>
                    <DescriptionListDescription>
                        <TextArea
                            id="condPago"
                            height={70}
                            value={condPago}
                            placeholder={ props.editable ? "Ingresa las condiciones de pago que aplican al cliente" : "No hubieron condiciones de pago" }
                            inputAttr={{ 'aria-label': 'Condiciones de Pago' }}
                            valueChangeEvent="focusout"
                            onValueChanged={onTextAreaValueChanged}
                            readOnly={!props.editable}
                        />
                    </DescriptionListDescription>
                </DescriptionListGroup>
            </DescriptionList>
        </div>
    )
}