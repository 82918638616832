import React from 'react';
import {TextBox, TextBoxTypes} from 'devextreme-react/text-box';
import Validator, {
    RequiredRule,
} from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';
//import { Button } from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import {ValidationSummary} from "devextreme-react";
import UsersServices from "../Services/UsersServices";
import {FormHelperText, Form, Button, ActionGroup} from "@patternfly/react-core";
import {ExclamationCircleIcon} from "@patternfly/react-icons";
import {useAuthState} from "../Context/AuthProvider/AuthProvider";
import {DiazarFormInput} from "./Form/DiazarFormInput";
import {useNavigate} from "react-router-dom";

interface ResetPasswordFormProps {
    sendEmail: React.Dispatch<React.SetStateAction<boolean>>
    email: React.Dispatch<React.SetStateAction<string>>
}

export const ResetPasswordForm = (props: ResetPasswordFormProps) => {
    const [email, setEmail] = React.useState<string>('')
    const [isValidEmail, setIsValidEmail] = React.useState<boolean>(false);
    const { loading, errorMessage } = useAuthState();
    const navigate = useNavigate();

    const valueChanged = (e : TextBoxTypes.ValueChangedEvent) => {
        setEmail(e.value);
    }

    const resetPassword = () => {
        UsersServices.resetPassword(email)
            .then((resp) => {
                if (resp.status === "SUCCESS") {
                    props.sendEmail(true);
                    props.email(email);
                } else {
                    notify({
                            message: resp.error.error,
                            height: 50,
                            width: 150,
                            type: "error",
                            displayTime: 3500,
                            animation: {
                                show: {
                                    type: 'fade', duration: 400, from: 0, to: 1,
                                },
                                hide: { type: 'fade', duration: 40, to: 0 },
                            },
                        } ,
                        {
                            position : "top right",
                            direction: "down-push"
                        });
                }
            })
    }

    const isValidForm = React.useMemo<boolean>( () => {
        const isLoading = ( loading !== undefined ) ? loading : false
        return !isLoading && isValidEmail

    }, [loading, isValidEmail] )

    const helperText = React.useMemo( () => {
        if ( errorMessage === undefined ){
            return null
        }else{
            return (
                <FormHelperText  >
                    <ExclamationCircleIcon />
                    &nbsp;{errorMessage}.
                </FormHelperText>
            )
        }
    }, [errorMessage] )

    return (
        <div className="auth-wrapper">
            <div className="auth-inner">
                <Form>
                    {helperText}
                    <DiazarFormInput id="email" label="Correo electrónico" isRequired
                                     type="text"
                                     placeholder="Ingresa tu correo electrónico registrado"
                                     getState={ (isValid, value) => {
                                         setIsValidEmail(isValid)
                                         setEmail(value)
                                     } }
                    />
                    <ActionGroup>
                        <Button type="submit"
                                onClick={ e => { e.preventDefault(); resetPassword() }}
                                isBlock
                                isDisabled={ !isValidForm }
                                isLoading={ ( loading !== undefined ) ? loading : false }
                        >
                            ¡Ayudame con mi contraseña!
                        </Button>
                        <div style={{width: '100%', textAlign:'center', marginBottom: '15px'}}>
                            <span style={{backgroundColor:'white', padding:'0 15px'}}>Si ya recordaste tus credenciales</span>
                            <hr style={{marginTop:'-10px'}}/>
                        </div>
                        <Button type="submit"
                                onClick={ e => { e.preventDefault(); navigate("/login") }}
                                isBlock
                                variant="secondary"
                        >
                            Inicia sesión
                        </Button>
                    </ActionGroup>
                </Form>
                {/*<ValidationGroup>
                    <TextBox
                        inputAttr={{ 'aria-label': 'Email' }}
                        name="email"
                        label="Correo electrónico registrado"
                        value={email}
                        showClearButton={true}
                        mode="email"
                        valueChangeEvent='input'
                        onValueChanged={valueChanged}
                    >
                        <Validator>
                            <RequiredRule message="Necesito tu correo electrónico para ayudarte." />
                            <RequiredRule message="Necesito un correo electrónico valido" type="email"/>
                        </Validator>
                    </TextBox>
                    <ValidationSummary />
                    <br />
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <Button
                            text="Recuperar contraseña"
                            onClick={resetPassword}
                            type="default"
                            stylingMode="contained"
                            icon="send"
                        />
                    </div>
                </ValidationGroup>*/}
            </div>
        </div>
    )
}