import React from "react";
import {
    Button,
    EmptyState,
    EmptyStateVariant,
    EmptyStateIcon,
    EmptyStateBody,
    Bullseye, EmptyStateActions, EmptyStateHeader, EmptyStateFooter
} from '@patternfly/react-core';
import CubesIcon from '@patternfly/react-icons/dist/esm/icons/cubes-icon';
import {useNavigate} from "react-router-dom";
import {ListItemsInfoLinks} from "../../Components/ListItemsInfoLinks";

export const NotFound: React.FunctionComponent = (_props) => {
    let navigate = useNavigate();

    return (
        <Bullseye>
            <EmptyState variant={EmptyStateVariant.xl}>
                <EmptyStateHeader titleText="Página No Encontrada" icon={<EmptyStateIcon icon={CubesIcon} />} headingLevel="h5" />
                <EmptyStateBody>
                    Esto es realmente embarazoso. El recurso que usted intenta acceder no está disponible en este
                    momento. Quizá lo estuvo en el pasado y ha sido borrado o quizá has escrito la dirección web mal.
                    Si recibes este error, comprueba que la dirección que has introducido es correcta y no le falta o
                    sobra nada.
                </EmptyStateBody><EmptyStateFooter>
                <Button variant="primary" onClick={ ()=>{
                    navigate("/")
                } }>Ir al Inicio</Button>
                <br />
                <EmptyStateActions>
                    <ListItemsInfoLinks />
                </EmptyStateActions>
            </EmptyStateFooter></EmptyState>
        </Bullseye>
    )
}