import {ServicesUrl} from "../Config/UrlRuotes";
import {LoginActionResponse, LoginDispacher} from "../Context/AuthProvider/AuthDomain";

interface Error {
    "has-error": boolean;
    "error"?: string;
}

interface ResponseStandard {
    "status": "SUCCESS" | "ERROR";
    "error": Error;
    "response": any;
}

export interface Client {
    ID: number;
    CreatedAt: string;
    UpdateAt: string;
    cliente: string;
    cliente_nombre_comercial: string;
    cotizaciones_pendientes: number;
    cotizaciones_sin_leer: number;
    notificaciones?: Notifications[];
    nombre_en_diazar?: string;
}

export interface Notifications {
    ID: number;
    CreatedAt: string;
    UpdateAt: string;
    esta_leida: true;
    contexto_id: number;
    mensaje: string;
    contexto: string;
}

interface ResponseGetAllClient extends ResponseStandard {
    response: Client[];
}

export interface BasicPromiseResponse {
    ok: boolean;
    data:  Client[] | string
}
export interface Response {
    ok: boolean;
    data: {
        limit: number;
        page: number;
        rows: Client[];
        total: number;
    } | string
}

export interface CompanyDiazar {
    id: number;
    name: string;
    tradename: string;
}
export interface ResponseCompanyDiazar extends ResponseStandard {
    response: CompanyDiazar;
}

interface ResponseAddValidationToken extends ResponseStandard {
    response: string;
}

// TODO: Revisar si tengo que pasar limit y page
export const getAllClients = async (token: string, dispatch: React.Dispatch<LoginDispacher>): Promise<BasicPromiseResponse> => {
    const response = await fetch(ServicesUrl.notifications, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });
    const data: ResponseGetAllClient = await response.json();

    if (data.status === "SUCCESS" ) {
        let local = JSON.parse(localStorage.getItem("currentUser")+'');
        localStorage.removeItem("currentUser");

        let clients : Client[] = data.response;
        const totalClients = clients.length;
        let totalNotifications = 0;
        let totalUnreadNotifications = 0;

        clients.forEach((client: Client) => {
            totalNotifications += client.cotizaciones_pendientes;
            totalUnreadNotifications += client.cotizaciones_sin_leer;
        })

        local.clientes = clients;
        local.totalClientes = totalClients;
        local.totalNotificaciones = totalNotifications;
        local.totalNotificacionesSinLeer = totalUnreadNotifications;

        localStorage.setItem("currentUser", JSON.stringify(local))
        dispatch({type: 'UPDATE_CLIENTS', payload: local});

        return {
            ok: true,
            data: data.response
        }
    }



    return {
        ok: false,
        data: data.error.error ? data.error.error : ''
    }

}

export const addValidationToken = async (token: string, userToken: string): Promise<Response> => {
    const response = await fetch(`${ServicesUrl.clients}/add/${token}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
        }
    });

    const data: ResponseAddValidationToken = await response.json();

    if (data.status === "SUCCESS") {
        return {
            ok: true,
            data: data.response
        }
    } else {
        return {
            ok: false,
            data: data.error.error ? data.error.error : ''
        }
    }

}

export const appUseClient = async (idClient: string, dispatch: React.Dispatch<LoginDispacher>, token: string) => {
    const response = await fetch(`${ServicesUrl.api}/use-client/${idClient}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    });

    const data: LoginActionResponse = await response.json();

    if (data.status === "SUCCESS") {
        localStorage.removeItem('currentUser');
        dispatch({type: 'LOGIN_SUCCESS', payload: data.response});
        localStorage.setItem('currentUser', JSON.stringify(data.response));
    }

    return data
}

export const getInfoValidationToken = async (token: string): Promise<ResponseCompanyDiazar> => {
    const response = await fetch(`${ServicesUrl.clients}/info/${token}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return await response.json()

}
