import React, {Key} from "react";
import {
    Avatar,
    Brand,
    Button,
    ButtonVariant,
    Divider,
    Flex,
    FlexItem,
    Masthead,
    MastheadBrand,
    MastheadContent,
    MastheadMain,
    MastheadToggle,
    NotificationBadge,
    NotificationBadgeVariant,
    PageToggleButton,
    Toolbar,
    ToolbarContent,
    ToolbarGroup,
    ToolbarItem
} from '@patternfly/react-core';
import {
    Dropdown,
    DropdownGroup,
    DropdownItem,
    DropdownSeparator,
    DropdownToggle,
    KebabToggle,
} from '@patternfly/react-core/deprecated';
import {ExternalLinkAltIcon} from '@patternfly/react-icons';
import BellIcon from '@patternfly/react-icons/dist/esm/icons/bell-icon';
import imgAvatar from '@patternfly/react-styles/css/assets/images/img_avatar.svg';
import {big_logo, DiazarBadge, DiazarSecure} from '../../Config/Images';
import styles from './PageHeader.module.css'
import {useAuthDispatch, useAuthState} from "../../Context/AuthProvider/AuthProvider";
import {useNavigate, useLocation, useParams} from "react-router-dom";
import {logout} from "../../Context/AuthProvider/AuthProviderActions";
import {useAppsDispatch, useAppsState} from "../../Context/DiazarSuiteProvider/DiazarSuiteProvider";
import {DiazarSuitePopover} from "../DiazarSuitePopover";
import {APP_ID, COOKIE_NAME} from "../../Config/UrlRuotes";
import {useCookies} from "react-cookie";
import BarsIcon from '@patternfly/react-icons/dist/js/icons/bars-icon';
import {ClientsLookup} from "../ClientsLookup";

const keyDropDown = 'group-dropdown'

interface NavItemData {
    key?: Key | null | undefined;
    label: string;
    onClick?: () => void;
    icon?: React.ReactNode;
    isDisabled?: boolean;
}

interface viewsProps {
    notification: boolean;
    actionDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DiazarPageHeader: React.FunctionComponent<viewsProps> = (props) => {
    const [cookies, setCookie] = useCookies([COOKIE_NAME]);
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);
    const dispatch = useAuthDispatch()
    const userDetails = useAuthState();
    const {cliente_seleccionado} = useAuthState();
    let navigate = useNavigate();
    let location = useLocation();
    let parts = location.pathname.split("/");

    const switeData = useAppsState()
    const appsDispatch = useAppsDispatch()

    /* --> Elementos del Dropdown usuarios <-- */
    const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false)
    const onDropdownToggle = React.useCallback( (_event:any, isDropdownOpen:boolean)=>{
        setIsDropdownOpen( isDropdownOpen )
    }, [] )
    const onDropdownSelect = React.useCallback( ()=>{
        setIsDropdownOpen( prev => !prev )
    }, [] )

    const removeCoockie = React.useCallback( ()=>{
        let cookieObj = ( cookies[COOKIE_NAME] !== undefined) ? cookies[COOKIE_NAME] : []
        cookieObj = cookieObj.filter( val => val.id !== APP_ID )

        setCookie( COOKIE_NAME, cookieObj, { path:'/', domain:'diazar.com' } )
    }, [cookies, setCookie] )

    const onSidebarToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleLogout = React.useCallback( ()=>{
        logout(dispatch);
        removeCoockie()
        navigate('/login')
    }, [navigate, dispatch, removeCoockie] )

    const infoData: NavItemData[]  = React.useMemo( () => {
        return [
            /*{
                key:"privacy-policies",
                label:"Politicas de Privacidad",
                icon:<DiazarBadge />,
                onClick: () => { navigate('/privacy-policies') }
            },
            {
                key:"terms-of-use",
                label:"Terminos de Uso",
                icon:<DiazarSecure />,
                onClick: () => { navigate('/terms-of-use') }
            },*/
        ]
    }, [navigate])

    const userData = React.useMemo< NavItemData[] >( ()=>{
        if ( !Boolean( userDetails.token ) ){
            return []
        }

        let data:NavItemData[] = []

        if( userDetails.usuario ){
            if ( userDetails.usuario.nombres &&  userDetails.usuario.apellidos){
                data.push( {
                    key: "user-key",
                    label:  "Perfil",
                    onClick: () => { navigate('/profile') }
                })
            }

            data.push({
                key: "user-logout",
                label: "Cerrar sesión",
                onClick: handleLogout,
            })
        }

        return data
    }, [userDetails.token, userDetails.usuario, handleLogout, navigate] )

    const getDropDownGroup = React.useCallback( (keyStr:string)=>{
        return [
            <DropdownGroup key={ keyStr }>
                { userData.map( data => <DropdownItem { ...data }> { data.label }</DropdownItem> ) }
            </DropdownGroup>
        ];
    }, [userData])

    const userDropdownItems = React.useMemo( () => {
        return getDropDownGroup( keyDropDown + '-1' )
    }, [getDropDownGroup])

    /* --> Elementos del Kebab <-- */
    const [isKebabDropdownOpen, setIsKebabDropdownOpen] = React.useState<boolean>(false)
    const [isFullKebabDropdownOpen, setIsFullKebabDropdownOpen] = React.useState<boolean>(false)

    const onKebabDropdownToggle = React.useCallback( (_event: any, isKebabDropdownOpen:boolean)=>{
        setIsKebabDropdownOpen( isKebabDropdownOpen )
    }, [] )
    const onKebabDropdownSelect = React.useCallback( ()=>{
        setIsKebabDropdownOpen( prev => !prev )
    }, [] )

    const kebabDropdownItems = React.useMemo( () => {
        const el = infoData.map( data => <DropdownItem { ...data }> { data.label }</DropdownItem> )

        if( switeData.apps !== undefined && switeData.apps.length > 0 ){
            el.push( <DropdownSeparator key="separator" /> )

            switeData.apps.map( app => {
                el.push( (
                    <DropdownItem key={ `app-diazar-suite-${ app.id }` }
                                  icon={ (<img src={ app.logo_url } alt={app.name} /> ) }
                                  href={ app.app_url }
                                  target="_blank"
                    >
                        {app.name}
                    </DropdownItem>
                ) )
                return true
            } )
        }


        return el;
    } , [infoData, switeData.apps] )


    const onFullKebabToggle = React.useCallback( (_event: any, isFullKebabDropdownOpen:boolean)=>{
        setIsFullKebabDropdownOpen( isFullKebabDropdownOpen )
    }, [] )
    const onFullKebabSelect = React.useCallback( ()=>{
        setIsFullKebabDropdownOpen( prev => !prev )
    }, [] )
    const fullKebabItems = React.useMemo( () => {
        let  elements = getDropDownGroup( keyDropDown + '-2' );
        elements.push( <Divider key="divider" /> )

        for( let i=0; i < kebabDropdownItems.length; i++ ){
            elements.push( kebabDropdownItems[i] )
        }

        return elements
    }, [kebabDropdownItems, getDropDownGroup] )

    const tollbarIcons  = React.useMemo( ()=>{
        return (
            <ToolbarGroup variant="icon-button-group" visibility={{ default: 'hidden', lg: 'visible' }}>
                {
                    infoData.map( data => (
                        <ToolbarItem key={ data.key }>
                            <Button aria-label={ data.label  }
                                    variant={ButtonVariant.plain}
                                    onClick={ data.onClick }
                            >
                                {  data.icon }
                            </Button>
                        </ToolbarItem>
                    ) )
                }
                <DiazarSuitePopover />
            </ToolbarGroup>
        )
    }, [infoData] );

    const onCloseNotificationDrawer = (_event: any) => props.actionDrawer((prevState) => !prevState);

    const headerTools = React.useMemo( ()=>{
        return (
            <Toolbar id="nav-main-toolbar" isFullHeight isStatic>
                <ToolbarContent>
                    <ToolbarGroup
                        variant="icon-button-group"
                        align={{ default: 'alignRight' }}
                        spacer={{ default: 'spacerNone', md: 'spacerMd' }}
                    >
                        { tollbarIcons }
                        <ToolbarItem visibility={{ default: 'visible' }} selected={props.notification}>
                            <NotificationBadge
                                variant={userDetails.totalNotificacionesSinLeer === 0 ? NotificationBadgeVariant.read : NotificationBadgeVariant.unread}
                                onClick={(event: any) => onCloseNotificationDrawer(event)}
                                aria-label="Notifications"
                                isExpanded={props.notification}
                                count={userDetails.totalNotificacionesSinLeer}
                                style={{color: '#3c3f42'}}
                            >
                                <BellIcon />
                            </NotificationBadge>
                        </ToolbarItem>
                        <ToolbarItem visibility={{ default: 'hidden', md: 'visible', lg: 'hidden' }}>
                            <Dropdown
                                isPlain
                                position="right"
                                onSelect={ onKebabDropdownSelect }
                                toggle={<KebabToggle onToggle={ onKebabDropdownToggle } />}
                                isOpen={isKebabDropdownOpen}
                                dropdownItems={kebabDropdownItems}
                            />
                        </ToolbarItem>
                        <ToolbarItem visibility={{ default: 'visible', md: 'hidden', lg: 'hidden', xl: 'hidden', '2xl': 'hidden' }}>
                            <Dropdown
                                isPlain
                                position="right"
                                onSelect={ onFullKebabSelect }
                                toggle={<KebabToggle onToggle={ onFullKebabToggle } />}
                                isOpen={isFullKebabDropdownOpen}
                                dropdownItems={fullKebabItems}
                            />
                        </ToolbarItem>
                    </ToolbarGroup>
                    <ToolbarItem visibility={{ default: 'hidden', md: 'visible' }} >
                        <Dropdown
                            position="right"
                            onSelect={ onDropdownSelect }
                            isOpen={isDropdownOpen}
                            toggle={
                                <DropdownToggle
                                    icon={<Avatar src={userDetails.usuario?.imagen ? userDetails.usuario?.imagen : imgAvatar} alt="Avatar" />}
                                    onToggle={ onDropdownToggle }
                                    toggleVariant="default"
                                >
                                    { userDetails.usuario?.nombres }
                                </DropdownToggle>
                            }
                            dropdownItems={userDropdownItems}
                        />
                    </ToolbarItem>
                </ToolbarContent>
            </Toolbar>
        );
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
        fullKebabItems, isDropdownOpen, isFullKebabDropdownOpen, isKebabDropdownOpen, kebabDropdownItems, userDropdownItems,
        onDropdownSelect, onDropdownToggle, onFullKebabSelect, onFullKebabToggle, onKebabDropdownSelect, onKebabDropdownToggle,
        tollbarIcons, userDetails.usuario, userDetails.totalNotificacionesSinLeer, props.notification
    ]);

    React.useEffect( ()=>{
        if( switeData.apps === undefined || switeData.apps.length === 0 ){
        }
    }, [ switeData.apps, userDetails, appsDispatch ] )

    return (
        <Masthead id="icon-router-link" backgroundColor="light">
            <MastheadToggle>
                <PageToggleButton variant="link" isSidebarOpen={isSidebarOpen} onSidebarToggle={onSidebarToggle} aria-label="Global navigation" className="diazar-cotizaciones">
                    <BarsIcon />
                </PageToggleButton>
            </MastheadToggle>
            <MastheadMain>
                <MastheadBrand href="https://www.diazar.com" target="_blank" >
                    <Brand src={big_logo} alt="PatternFly" className={ styles.PageHeaderBrandNav } />
                </MastheadBrand>
            </MastheadMain>
            <MastheadContent>
                {cliente_seleccionado?.ID && parts[1] === "quotes" ? <Flex>
                    <FlexItem>
                        <ClientsLookup />
                    </FlexItem>
                </Flex> : null}
                {headerTools}
            </MastheadContent>
        </Masthead>
    )
}
