import React from 'react';
// import {TextBox} from "devextreme-react/text-box";
// import Validator, {RequiredRule, CompareRule, PatternRule} from "devextreme-react/validator";
import {useNavigate} from "react-router-dom";
import notify from "devextreme/ui/notify";
import UsersServices, {RegisterResponse} from "../../Services/UsersServices";
import {FormHelperText, Form, Button, ActionGroup} from "@patternfly/react-core";
// import ValidationGroup from "devextreme-react/cjs/validation-group";
// import ValidationSummary from "devextreme-react/validation-summary";
import {Grid, GridItem} from "@patternfly/react-core";
import {ExclamationCircleIcon} from "@patternfly/react-icons";
import {useAuthState} from "../../Context/AuthProvider/AuthProvider";
import {DiazarFormInput} from "../Form/DiazarFormInput";


export const RegisterForm = () => {
    const [name, setName] = React.useState<string>('');
    const [isValidName, setIsValidName] = React.useState<boolean>(false);
    const [lastname, setLastName] = React.useState<string>('');
    const [isValidLastName, setIsValidLastName] = React.useState<boolean>(false);
    const [email, setEmail] = React.useState<string>('');
    const [isValidEmail, setIsValidEmail] = React.useState<boolean>(false);
    const [password, setPassword] = React.useState<string>('');
    const [isValidPassword, setIsValidPassword] = React.useState<boolean>(false);
    const [cpassword, setCPassword] = React.useState<string>('');
    const [isValidCPassword, setIsValidCPassword] = React.useState<boolean>(false);
    const [userOnValidation, setUserOnValidation] = React.useState<boolean>(false);
    const { loading, errorMessage } = useAuthState();

    const navigate = useNavigate();

    /*const passwordComparison = React.useCallback<any>(() => password, [password]);*/

    const register = async () => {
        const response: RegisterResponse = await UsersServices.register(password, email, name, lastname);

        if (response.status === "SUCCESS" ) {
            setUserOnValidation(true);
            notify({
                    message: '¡Excelente!, solo un paso más',
                    height: 50,
                    width: 150,
                    type: "success",
                    displayTime: 3500,
                    animation: {
                        show: {
                            type: 'fade', duration: 400, from: 0, to: 1,
                        },
                        hide: { type: 'fade', duration: 40, to: 0 },
                    },
                } ,
                {
                    position : "top right",
                    direction: "down-push"
                })
            notify({
                    message: 'Por favor revisar tu correo electrónico y sigue la instrucciones.',
                    height: 50,
                    width: 150,
                    type: "success",
                    displayTime: 10000,
                    animation: {
                        show: {
                            type: 'fade', duration: 400, from: 0, to: 1,
                        },
                        hide: { type: 'fade', duration: 40, to: 0 },
                    },
                } ,
                {
                    position : "top right",
                    direction: "down-push"
                })
            navigate('/login')
        } else {
            notify({
                    message: response.error.error,
                    height: 50,
                    width: 150,
                    type: "error",
                    displayTime: 3500,
                    animation: {
                        show: {
                            type: 'fade', duration: 400, from: 0, to: 1,
                        },
                        hide: { type: 'fade', duration: 40, to: 0 },
                    },
                } ,
                {
                    position : "top right",
                    direction: "down-push"
                })
        }
    }

    /*const valueChanged = (e) => {
        switch (e.element.id) {
            case "name":
                setName(e.value);
                break;
            case "lastname":
                setLastName(e.value)
                break;
            case "email":
                setEmail(e.value);
                break;
            case "password":
                setPassword(e.value);
                break;
            case "cpassword":
                setCPassword(e.value);
                break;
        }
    }*/

    /*const submitForm = (e) => {
        const rules = e.validationGroup.validate();

        if (rules.isValid) {
            register();
        }
    }*/

    const isValidForm = React.useMemo<boolean>( () => {
        const isLoading = ( loading !== undefined ) ? loading : false
        return !isLoading && isValidName && isValidPassword && isValidLastName && isValidEmail && isValidCPassword

    }, [ isValidName, isValidPassword, loading, isValidLastName, isValidEmail, isValidCPassword] )

    const helperText = React.useMemo( () => {
        if ( errorMessage === undefined ){
            return null
        }else{
            return (
                <FormHelperText  >
                    <ExclamationCircleIcon />
                    &nbsp;{errorMessage}.
                </FormHelperText>
            )
        }
    }, [errorMessage] )

    return (
        <div className="auth-wrapper">
            <div className="auth-inner">
                {userOnValidation ?
                    <h3>Por favor revisar tu correo electrónico y sigue la instrucciones.</h3> :
                    <Form>
                        {helperText}
                        <DiazarFormInput id="name" label="Nombre" isRequired
                                         type="text"
                                         placeholder="Ingresa tu nombre"
                                         min={4}
                                         getState={ (isValid, value) => {
                                             setIsValidName(isValid)
                                             setName(value)
                                         } }
                        />
                        <DiazarFormInput id="lastname" label="Apellido" isRequired
                                         type="text"
                                         placeholder="Ingresa tu apellido"
                                         min={2}
                                         getState={ (isValid, value) => {
                                             setIsValidLastName(isValid)
                                             setLastName(value)
                                         } }
                        />
                        <DiazarFormInput id="email" label="Correo Electrónico" isRequired
                                         type="email"
                                         placeholder="Ingresa tu correo electrónico"
                                         getState={ (isValid, value) => {
                                             setIsValidEmail(isValid)
                                             setEmail(value)
                                         } }
                        />
                        <DiazarFormInput id="password" label="Contraseña" isRequired
                                         type="password"
                                         placeholder="Crea tu contraseña"
                                         register={true}
                                         min={8}
                                         getState={ (isValid, value) => {
                                             setIsValidPassword(isValid)
                                             setPassword(value)
                                         } }
                        />
                        <DiazarFormInput id="confirm-password" label="Confirma tu contraseña" isRequired
                                         type="password"
                                         placeholder="Confirma tu contraseña"
                                         register={true}
                                         min={8}
                                         isEqualTo={password}
                                         getState={ (isValid, value) => {
                                             setIsValidCPassword(isValid)
                                             setCPassword(value)
                                         } }
                        />
                        <ActionGroup>
                            <Button type="submit"
                                    onClick={ e => { e.preventDefault(); register() }}
                                    isBlock
                                    isDisabled={ !isValidForm }
                                    isLoading={ ( loading !== undefined ) ? loading : false }
                            >
                                Regístrarme
                            </Button>
                            <div style={{width: '100%', textAlign:'center', marginBottom: '15px'}}>
                                <span style={{backgroundColor:'white', padding:'0 15px'}}>Si ya tienes usuario</span>
                                <hr style={{marginTop:'-10px'}}/>
                            </div>
                            <Button type="submit"
                                    onClick={ e => { e.preventDefault(); navigate("/login") }}
                                    isBlock
                                    variant="secondary"
                            >
                                Inicia sesión
                            </Button>
                        </ActionGroup>
                    </Form>
                    /*<ValidationGroup>
                        <Grid>
                            <GridItem md={6} mdOffset={3} sm={12}>
                                <TextBox
                                    inputAttr={{'aria-label': 'Name'}}
                                    id="name"
                                    name="name"
                                    label="Nombre"
                                    labelMode="floating"
                                    value={name}
                                    mode="text"
                                    valueChangeEvent='input'
                                    onValueChanged={valueChanged}
                                    style={{marginBottom: '20px'}}
                                >
                                    <Validator name="name">
                                        <RequiredRule message="Necesito saber tu nombre"/>
                                    </Validator>
                                </TextBox>
                            </GridItem>
                            <GridItem md={6} mdOffset={3} sm={12}>
                                <TextBox
                                    inputAttr={{'aria-label': 'Last Name'}}
                                    id="lastname"
                                    name="lastname"
                                    label="Apellido"
                                    labelMode="floating"
                                    value={lastname}
                                    mode="text"
                                    valueChangeEvent='input'
                                    onValueChanged={valueChanged}
                                    style={{marginBottom: '20px'}}
                                >
                                    <Validator name="lastname">
                                        <RequiredRule message="Necesito saber tu apellido"/>
                                    </Validator>
                                </TextBox>
                            </GridItem>
                            <GridItem md={6} mdOffset={3} sm={12}>
                                <TextBox
                                    inputAttr={{'aria-label': 'Email'}}
                                    id="email"
                                    name="email"
                                    label="Correo Electrónico"
                                    labelMode="floating"
                                    value={email}
                                    mode="text"
                                    valueChangeEvent='input'
                                    onValueChanged={valueChanged}
                                    style={{marginBottom: '20px'}}
                                >
                                    <Validator name="email">
                                        <RequiredRule message="Ingresa un correo electrónico valido" type="email"/>
                                        <RequiredRule message="Necesito tu correo electrónico"/>
                                    </Validator>
                                </TextBox>
                            </GridItem>
                            <GridItem md={6} mdOffset={3} sm={12}>
                                <TextBox
                                    inputAttr={{'aria-label': 'Password'}}
                                    id="password"
                                    name="password"
                                    label="Contraseña"
                                    labelMode="floating"
                                    value={password}
                                    mode="password"
                                    valueChangeEvent='input'
                                    onValueChanged={valueChanged}
                                    style={{marginBottom: '20px'}}
                                >
                                    <Validator name="password">
                                        <RequiredRule message="Necesito que crees una contraseña"/>
                                        <PatternRule
                                            message="Debe tener al menos 8 caracteres."
                                            pattern="^.{8,}$"
                                        />
                                        <PatternRule
                                            message="Debe tener un número, una letra minúscula y una letra mayúscula."
                                            pattern="^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$"
                                        />
                                        <PatternRule
                                            message="Debe tener sólo letras y números en español."
                                            pattern="^[A-Za-z0-9]*$"
                                        />
                                    </Validator>
                                </TextBox>
                            </GridItem>
                            <GridItem md={6} mdOffset={3} sm={12}>
                                <TextBox
                                    inputAttr={{'aria-label': 'CPassword'}}
                                    id="cpassword"
                                    name="cpassword"
                                    label="Confirma tu contraseña"
                                    labelMode="floating"
                                    value={cpassword}
                                    mode="password"
                                    valueChangeEvent='input'
                                    onValueChanged={valueChanged}
                                    style={{marginBottom: '20px'}}
                                >
                                    <Validator name="cpassword">
                                        <RequiredRule message="Necesito que confirmes tu contraseña"/>
                                        <CompareRule
                                            message="Tu contraseña no es igual a esta confirmacion, verificala por favor."
                                            comparisonTarget={passwordComparison}
                                        />
                                    </Validator>
                                </TextBox>
                            </GridItem>
                            <GridItem sm={12}>
                                <ValidationSummary style={{marginBottom: '20px'}}/>
                            </GridItem>
                            <GridItem sm={12}>
                                <div style={{display: "flex", justifyContent: "center", marginBottom: '20px'}}>
                                    <Button
                                        text="Registrame"
                                        type="default"
                                        icon="accountbox"
                                        onClick={submitForm}
                                    />
                                </div>
                            </GridItem>
                            <GridItem sm={12}>
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <Button
                                        text="Iniciar sesión"
                                        type="default"
                                        icon="key"
                                        stylingMode="outlined"
                                        onClick={() => navigate("/login")}
                                    />
                                </div>
                            </GridItem>
                        </Grid>
                    </ValidationGroup>*/
                }
            </div>
        </div>
    );
}