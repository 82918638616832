import React, {useState} from 'react';
// import Validator, {CompareRule, PatternRule, RequiredRule} from "devextreme-react/validator";
// import TextBox from "devextreme-react/text-box";
import {Grid, GridItem} from "@patternfly/react-core";
// import ValidationGroup from 'devextreme-react/cjs/validation-group';
// import {Button} from "devextreme-react/button";
import UsersServices from "../Services/UsersServices";
// import ValidationSummary from 'devextreme-react/validation-summary';
import {FormHelperText, Form, Button, ActionGroup} from "@patternfly/react-core";
import notify from "devextreme/ui/notify";
import {useLocation, useNavigate} from "react-router-dom";
import {ExclamationCircleIcon} from "@patternfly/react-icons";
import {useAuthState} from "../Context/AuthProvider/AuthProvider";
import {DiazarFormInput} from "./Form/DiazarFormInput";

interface ChangePasswordFormProps {
    id?: number | undefined;
    handleModalToggle?: React.Dispatch<React.SetStateAction<boolean>>;
    loading?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ChangePasswordForm = (props: ChangePasswordFormProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [password, setPassword] = React.useState<string>('');
    const [isValidPassword, setIsValidPassword] = React.useState<boolean>(false)
    const [cpassword, setCPassword] = React.useState<string>('')
    const [isValidCPassword, setIsValidCPassword] = React.useState<boolean>(false)
    // const validateGroup = React.useRef(null);
    const [resetPassword, setResetPassword] = React.useState<boolean>(false);
    // const [alignButton, setAlignButton] = React.useState('right')
    const [token, setToken] = React.useState('');
    const { loading, errorMessage } = useAuthState();
    const TOKEN = useState();

    const handleModalToggle = () => props.handleModalToggle && props.handleModalToggle(false);

    const passwordComparison = React.useCallback<any>(() => password, [password]);

    const valueChanged = (e) => {
        if (e.element.id === 'password') {
            setPassword(e.value);
        } else {
            setCPassword(e.value);
        }
    }

    const changePassword = (e) => {
        // const rules = e.validationGroup.validate();

        // if (rules.isValid) {
            props.loading && props.loading(true);
            if (resetPassword) {
                UsersServices.resetPasswordAction(token, password)
                    .then((response) => {
                       if (response.status === "SUCCESS") {
                           notify({
                                   message: 'Constraseña actualizada correctamente',
                                   height: 50,
                                   width: 150,
                                   type: "success",
                                   displayTime: 7000,
                                   animation: {
                                       show: {
                                           type: 'fade', duration: 400, from: 0, to: 1,
                                       },
                                       hide: {type: 'fade', duration: 40, to: 0},
                                   },
                               },
                               {
                                   position: "top right",
                                   direction: "down-push"
                               })
                           navigate('/');
                       } else {
                           notify({
                                   message: response.error.error,
                                   height: 50,
                                   width: 150,
                                   type: "error",
                                   displayTime: 7000,
                                   animation: {
                                       show: {
                                           type: 'fade', duration: 400, from: 0, to: 1,
                                       },
                                       hide: {type: 'fade', duration: 40, to: 0},
                                   },
                               },
                               {
                                   position: "top right",
                                   direction: "down-push"
                               });
                           navigate('/');
                       }
                    });
            } else {
                UsersServices.updateUser({password: password}, props.id + '', TOKEN+'')
                    .then((resp) => {
                        if (resp.status === "SUCCESS") {
                            notify({
                                    message: 'Se cambio tu contraseña',
                                    height: 50,
                                    width: 150,
                                    type: "success",
                                    displayTime: 7000,
                                    animation: {
                                        show: {
                                            type: 'fade', duration: 400, from: 0, to: 1,
                                        },
                                        hide: {type: 'fade', duration: 40, to: 0},
                                    },
                                },
                                {
                                    position: "top right",
                                    direction: "down-push"
                                })
                            props.loading && props.loading(false);
                            props.handleModalToggle &&  props.handleModalToggle(false);
                        } else {
                            notify({
                                    message: resp.error.error,
                                    height: 50,
                                    width: 150,
                                    type: "danger",
                                    displayTime: 7000,
                                    animation: {
                                        show: {
                                            type: 'fade', duration: 400, from: 0, to: 1,
                                        },
                                        hide: {type: 'fade', duration: 40, to: 0},
                                    },
                                },
                                {
                                    position: "top right",
                                    direction: "down-push"
                                })
                        }
                    });
            }
        // }
    }

    const helperText = React.useMemo( () => {
        if ( errorMessage === undefined ){
            return null
        }else{
            return (
                <FormHelperText  >
                    <ExclamationCircleIcon />
                    &nbsp;{errorMessage}.
                </FormHelperText>
            )
        }
    }, [errorMessage] )

    const isValidForm = React.useMemo<boolean>( () => {
        const isLoading = ( loading !== undefined ) ? loading : false
        return !isLoading && isValidPassword && isValidCPassword

    }, [ isValidPassword, isValidCPassword, loading] )

    React.useEffect(() => {
        const url = location.pathname;
        const parts = url.split('/')
        if (parts[1] === 'reset-password') {
            setResetPassword(true);
            // setAlignButton('center')
            setToken(parts[2]);
        }
    }, [location])

    return (
        <div>
            <Form>
                {helperText}
                <DiazarFormInput id="password" label="Nueva contraseña" isRequired
                                 type="password"
                                 placeholder="Ingresa la nueva contraseña"
                                 getState={(isValid, value) => {
                                     setIsValidPassword(isValid)
                                     setPassword(value)
                                 }}
                                 min={8}
                                 register={true}
                />
                <DiazarFormInput id="cpassword" label="Repite tu nueva contraseña" isRequired
                                 type="password"
                                 placeholder="Vuelve a ingresar la nueva contraseña"
                                 getState={(isValid, value) => {
                                     setIsValidCPassword(isValid)
                                     setCPassword(value)
                                 }}
                                 min={8}
                                 register={true}
                                 isEqualTo={password}
                />
                <ActionGroup style={{display:"flex", justifyContent:"center"}}>
                    <Button variant="primary" onClick={changePassword} isDisabled={!isValidForm}>
                        Cambiar Contraseña
                    </Button>
                </ActionGroup>
            </Form>
            {/*<ValidationGroup ref={validateGroup}>
                <Grid hasGutter>
                    <GridItem md={6} mdOffset={3} sm={12}>
                        <TextBox
                            id="password"
                            label="Nueva Contraseña"
                            labelMode="floating"
                            mode="password"
                            value={password}
                            valueChangeEvent='input'
                            onValueChanged={valueChanged}
                        >
                            <Validator name="password">
                                <RequiredRule message="Necesito que crees una contraseña"/>
                                <PatternRule
                                    message="Debe tener al menos 8 caracteres."
                                    pattern="^.{8,}$"
                                />
                                <PatternRule
                                    message="Debe tener un número, una letra minúscula y una letra mayúscula."
                                    pattern="^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$"
                                />
                                <PatternRule
                                    message="Debe tener sólo letras y números en español."
                                    pattern="^[A-Za-z0-9]*$"
                                />
                            </Validator>
                        </TextBox>
                    </GridItem>
                    <GridItem sm={12}>
                        <ValidationSummary />
                    </GridItem>
                    <GridItem md={6} mdOffset={3} sm={12}>
                        <TextBox
                            inputAttr={{'aria-label': 'CPassword'}}
                            id="cpassword"
                            name="cpassword"
                            label="Confirma tu nueva contraseña"
                            labelMode="floating"
                            value={cpassword}
                            mode="password"
                            valueChangeEvent='input'
                            onValueChanged={valueChanged}
                            style={{margin: '0 0 25px 0'}}
                        >
                            <Validator name="cpassword">
                                <RequiredRule message="Necesito que confirmes tu contraseña"/>
                                <CompareRule
                                    message="Tu contraseña no es igual a esta confirmacion, verificala por favor."
                                    comparisonTarget={passwordComparison}
                                />
                            </Validator>
                        </TextBox>
                    </GridItem>
                    <GridItem style={{display:"flex", justifyContent:alignButton}}>
                        <Button key="confirm" onClick={changePassword} text="Cambiar" type="success" stylingMode="contained" style={{margin: '0 5px 0 0'}}/>
                        {!resetPassword && <Button key="cancel" onClick={handleModalToggle} text="Cancelar" type="default" stylingMode="text" style={{margin: '0 0 0 5px'}}/>}
                    </GridItem>
                </Grid>
            </ValidationGroup>*/}
        </div>
    );
}