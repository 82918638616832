import React from 'react';
import DataGrid, {
    Column,
    SearchPanel,
    Editing,
    Scrolling,
    Paging,
    Pager,
    Sorting,
    HeaderFilter,
    Search,
    Selection,
    Summary,
    TotalItem,
    ColumnFixing,
    Item,
    Toolbar,
    ColumnChooser,
    DataGridTypes,
} from 'devextreme-react/data-grid';
import DateBox from 'devextreme-react/date-box';
import {Button} from 'devextreme-react/button';
import {Badge, Tooltip} from '@patternfly/react-core';
import QuotesServices, {ItemQuote} from "../Services/QuotesServices";
import {useNavigate} from "react-router-dom";
import {CatProductsModal} from "./CatProductsModal";
import notify from "devextreme/ui/notify";
import {useAuthState} from "../Context/AuthProvider/AuthProvider";
import CustomStore from "devextreme/data/custom_store";

interface QuoteDataGridProps {
    RequestDeliveryDate: string;
    deliveryDate: string;
    deliveryDateAction: React.Dispatch<React.SetStateAction<string>>;
    quoteID: string | undefined;
    editable: boolean;
    descDefault: number;
}

export const QuoteDataGrid = (props: QuoteDataGridProps) => {
    const {
        RequestDeliveryDate, deliveryDate, deliveryDateAction,
        quoteID, editable,    descDefault
    } = props;

    const dataGrid = React.useRef<DataGrid | null>(null);
    const [modalCatRec, setModalCatRec] = React.useState<boolean>(false);
    const [selectedRow, setSelectedRow] = React.useState<ItemQuote | undefined>(undefined);
    const navigate = useNavigate();
    const {token} = useAuthState();

    const DataSource = new CustomStore({
        key: 'item_sol_cotizacion_id',
        load: async () => {
            return await QuotesServices.getQuoteByIdSolicitud(props.quoteID+'', token+'')
                .then((resp) => {
                    return resp.items;
                });
        },
        // TODO Realizar la petición para agregar un elemento a la cotización.
        update: async (key, values) => {
            return await QuotesServices.putItemByIdRequest(props.quoteID+'', key, values, token+'');
        }
    })

    const selectedChanged = React.useCallback((e: DataGridTypes.SelectionChangedEvent) => {
        setSelectedRow(e.selectedRowsData[0]);
    }, [setSelectedRow]);

    const ColumnCatRec = React.useCallback( () =>
            <Tooltip content={<div>Vincular a un recurso del catálogo</div>} >
                <Button icon="link" type="default" onClick={() => setModalCatRec(true)}/>
            </Tooltip>
        , [] );

    const markAsSent = React.useCallback( () => {
        if (quoteID) {
            QuotesServices.putQuoteDataByIdRequest(quoteID, "recibida", true, token+'')
                .then((resp) => {
                    if (resp.status) {
                        notify({
                                message: "La cotización fue actualizada con éxito.",
                                height: 50,
                                width: 150,
                                type: "success",
                                displayTime: 10000,
                                animation: {
                                    show: {
                                        type: 'fade', duration: 400, from: 0, to: 1,
                                    },
                                    hide: {type: 'fade', duration: 40, to: 0},
                                },
                            },
                            {
                                position: "top right",
                                direction: "down-push"
                            });
                        navigate('/quotes')
                    } else {
                        notify({
                            message: resp.response,
                            height: 50,
                            width: 150,
                            type: "error",
                            displayTime: 5000,
                            animation: {
                                show: {
                                    type: 'fade', duration: 400, from: 0, to: 1,
                                },
                                hide: {type: 'fade', duration: 40, to: 0},
                            },
                        },
                        {
                            position: "top right",
                            direction: "down-push"
                        });
                    }
                });
        }
    }, [navigate, quoteID, token]);

    const RefreshDataGrid = React.useCallback(() => {
        if (!modalCatRec && dataGrid?.current?.instance) {
            dataGrid.current.instance.refresh();
        }
    }, [modalCatRec]);

    /*const cellPrepared = React.useCallback((e) => {
        console.log(e)
        if (e.rowType === "data") {
            if (e.column.dataField !== 'pu_prov' && e.column.dataField !== 'descuento' && e.column.dataField !== 'cantidad_disponible' && e.column.dataField !== 'fecha_de_entrega_proveedor' && e.column.dataField !== 'observaciones_proveedor') {
                e.cellElement.style.cssText = "color: #A1A1A1FF";
            }
            if (e.column.dataField === 'pu_prov' || e.column.dataField === 'descuento' || e.column.dataField === 'cantidad_disponible' || e.column.dataField === 'fecha_de_entrega_proveedor' || e.column.dataField === 'observaciones_proveedor') {
                e.cellElement.style.cssText = "font-weight: bold";
                e.column.alignment = "center";
            }
        }
    }, [])*/

    const ShowColumnChosser = React.useCallback(() => {
        if (dataGrid?.current?.instance) {
            dataGrid.current?.instance.showColumnChooser();
        }
    }, [dataGrid])

    /*const test = React.useCallback((cellData: DataGridTypes.ColumnCellTemplateData) => {
        return (<div style={{ width: '100px' }}>
            <p style={{ wordWrap: 'break-word'}}>{cellData.value}</p>
        </div>)
    }, [])*/

    const dataGridCoti = React.useMemo(() => {
        return (
            <>

                <DataGrid
                    id="gridQuoteItems"
                    ref={dataGrid}
                    dataSource={DataSource}
                    columnAutoWidth={true}
                    allowColumnResizing={true}
                    showRowLines={true}
                    showColumnLines={false}
                    showBorders={false}
                    focusedRowEnabled={true}
                    //rowAlternationEnabled={true}
                    onSelectionChanged={selectedChanged}
                    columnResizingMode="widget"
                    style={{backgroundColor: 'white'}}
                    /*onCellPrepared={cellPrepared}*/
                    className="custom-datagrid2"
                >
                    <Selection mode="single"/>
                    <Sorting mode="multiple"/>
                    <ColumnFixing enabled={true}/>
                    <Scrolling rowRenderingMode='virtual'></Scrolling>
                    <Paging defaultPageSize={50}/>
                    <Pager
                        visible={true}
                        allowedPageSizes="auto"
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true}
                        infoText="Página {0} de {1} ({2} recursos)"/>
                    <SearchPanel visible={true}/>
                    <HeaderFilter visible={true}/>
                    <Editing
                        mode="batch"
                        allowUpdating={true}
                    />
                    <ColumnChooser enabled={true} mode="dragAndDrop" title="Selector de columnas"/>
                    <Column
                        key="itemActions"
                        allowEditing={false}
                        visible={true}
                        fixed={true}
                        cellRender={ColumnCatRec}
                    >
                    </Column>
                    <Column
                        key="recursoClave"
                        dataField="recurso_clave"
                        caption="Clave cliente"
                        dataType="string"
                        fixed={true}
                        allowEditing={false}
                        visible={true}
                        cssClass="no-editable"
                    >
                        <HeaderFilter allowSelectAll={true}>
                            <Search enabled={true}/>
                        </HeaderFilter>
                    </Column>
                    <Column
                        key="description"
                        dataField="recurso_descripcion"
                        caption="Descripción"
                        dataType="string"
                        allowEditing={false}
                        visible={true}
                        width={200}
                        cssClass="no-editable"
                    >
                        <HeaderFilter allowSelectAll={true}>
                            <Search enabled={true}/>
                        </HeaderFilter>
                    </Column>
                    <Column
                        key="unidad"
                        dataField="unidad"
                        caption="Unidad"
                        dataType="string"
                        allowEditing={false}
                        cssClass="no-editable"
                        alignment="center"
                    >
                        <HeaderFilter allowSelectAll={true}>
                            <Search enabled={true}/>
                        </HeaderFilter>
                    </Column>
                    <Column
                        key="cantSol"
                        dataField="cantidad"
                        caption="Cant. Sol."
                        dataType="number"
                        alignment="right"
                        allowEditing={false}
                        cssClass="no-editable"
                        visible={true}
                        minWidth={75}
                        format={(value) => Number.parseFloat(value).toFixed(4)}
                    >
                        <HeaderFilter allowSelectAll={true}>
                            <Search enabled={true}/>
                        </HeaderFilter>
                    </Column>
                    <Column
                        key="cantDisp"
                        dataField="cantidad_disponible"
                        caption="Cant. Disp."
                        dataType="number"
                        allowEditing={true}
                        cssClass="editable"
                        visible={true}
                        setCellValue={(newData, value) =>
                            value < 0
                                ? newData['cantidad_disponible'] = 0
                                : newData['cantidad_disponible'] = value
                        }
                    >
                        <HeaderFilter allowSelectAll={true}>
                            <Search enabled={true}/>
                        </HeaderFilter>
                    </Column>
                    <Column
                        key="precio_sugerido"
                        dataField="precio_sugerido"
                        caption="PU Sug."
                        dataType="number"
                        allowEditing={false}
                        cssClass="no-editable"
                        visible={true}
                        format={{type: 'currency', precision: 2}}
                    >
                        <HeaderFilter allowSelectAll={true}>
                            <Search enabled={true}/>
                        </HeaderFilter>
                    </Column>
                    <Column
                        key="precioUnitario"
                        dataField="pu_prov"
                        caption="Precio unitario"
                        dataType="number"
                        allowEditing={true}
                        cssClass="editable"
                        visible={true}
                        format={{type: 'currency', precision: 2}}
                        setCellValue={(newData: any, value: any, currentRowData) => {
                            if (currentRowData['precio_sugerido'] > 0 && currentRowData['pu_prov'] === 0) {
                                newData['pu_prov'] = currentRowData['precio_sugerido'];
                            }
                            if (currentRowData['cantidad_disponible'] === 0) {
                                newData['cantidad_disponible'] = currentRowData['cantidad'];
                            }
                            if (currentRowData['fecha_de_entrega_proveedor'] === "") {
                                newData['fecha_de_entrega_proveedor'] = deliveryDate !== '' ? deliveryDate : RequestDeliveryDate;
                            }
                            if (currentRowData['descuento'] === 0) {
                                newData['descuento'] = descDefault / 100
                            }
                            if (value < 0) {
                                newData['pu_prov'] = 0;
                            } else {
                                newData['pu_prov'] = value;
                            }
                        }
                        }
                    >
                        <HeaderFilter allowSelectAll={true}>
                            <Search enabled={true}/>
                        </HeaderFilter>
                    </Column>
                    <Column
                        key="descItem"
                        dataField="descuento"
                        caption="Desc."
                        dataType="number"
                        allowEditing={true}
                        cssClass="editable"
                        visible={true}
                        setCellValue={(newData, value) => {
                            if (value > 100 || value < 0) {
                                newData['descuento'] = 0
                            } else {
                                newData['descuento'] = value / 100
                            }
                        }}
                        calculateCellValue={(rowData) => {
                            if (rowData['descuento'] === 0) {
                                rowData['descuentoAux'] = descDefault / 100
                            }
                        }}
                        calculateDisplayValue={(rowData) => {
                            let str = `${rowData['descuento'] * 100}%`
                            if (rowData['descuento'] === 0 && rowData['descuentoAux'] > 0) {
                                str = `${rowData['descuentoAux'] * 100}%`
                            }

                            return str
                        }}
                    />
                    <Column
                        key="precioConDescuento"
                        dataField="precio_descuento"
                        caption="Precio con desc."
                        dataType="number"
                        allowEditing={false}
                        cssClass="no-editable"
                        visible={true}
                        format={{type: 'currency', precision: 2}}
                        calculateCellValue={(rowData: any) =>
                            rowData['descuento'] === 0
                                ? rowData['pu_prov']
                                : rowData['pu_prov'] - (rowData['pu_prov'] * rowData['descuento'])
                        }
                    />
                    <Column
                        key="importe"
                        dataField="importe"
                        caption="Importe"
                        dataType="number"
                        allowEditing={false}
                        cssClass="no-editable"
                        visible={true}
                        format={{type: 'currency', precision: 2}}
                        calculateCellValue={(rowData: any) =>
                            rowData['descuento'] === 0
                                ? rowData['pu_prov'] * rowData['cantidad_disponible']
                                : (rowData['pu_prov'] - (rowData['pu_prov'] * rowData['descuento'])) * rowData['cantidad_disponible']
                        }
                    />
                    <Column
                        key="fechaEntregaSolicitada"
                        dataField="fecha_de_entrega"
                        caption="Fecha de entrega deseada"
                        dataType="datetime"
                        allowEditing={false}
                        cssClass="no-editable"
                        visible={true}
                    >
                        <HeaderFilter allowSelectAll={true}>
                            <Search enabled={true}/>
                        </HeaderFilter>
                    </Column>
                    <Column
                        key="fechaEntrega"
                        dataField="fecha_de_entrega_proveedor"
                        caption="Fecha de entrega"
                        dataType="datetime"
                        allowEditing={true}
                        cssClass="editable"
                        visible={true}
                    >
                        <HeaderFilter allowSelectAll={true}>
                            <Search enabled={true}/>
                        </HeaderFilter>
                    </Column>
                    <Column
                        key="observaciones"
                        dataField="observaciones_proveedor"
                        caption="Observaciones"
                        dataType="string"
                        allowEditing={true}
                        cssClass="editable"
                        visible={true}
                    >
                        <HeaderFilter allowSelectAll={true}>
                            <Search enabled={true}/>
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField="cuenta"
                        caption="Partida"
                        groupIndex={0}
                    />
                    <Summary>
                        <TotalItem
                            column="precio_descuento"
                            summaryType="sum"
                            displayFormat="Total sin impuestos*:"
                        />
                        <TotalItem
                            column="importe"
                            summaryType="sum"
                            displayFormat="{0}"
                            valueFormat={{type: 'currency', precision: 2}}
                        />
                    </Summary>
                    <Toolbar>
                        <Item location="before">
                            <div style={{textAlign: "right"}}>
                                <p>Selecciona la fecha </p><p>entrega estimada: </p>
                            </div>
                        </Item>
                        <Item location="before">
                            <Tooltip
                                content={
                                    <div>
                                        Establece una fecha estimada general, también puedes ajustarla según el
                                        producto, dentro de la columna "Fecha de entrega".
                                    </div>
                                }
                            >
                                <DateBox disabled={!editable} type="datetime" placeholder="03/09/87, 19:05 p.m."
                                         value={deliveryDate.length !== 0 ? deliveryDate : undefined}
                                         onValueChanged={(e) => {
                                             deliveryDateAction(e.value?.toLocaleString());
                                         }}/>
                            </Tooltip>
                        </Item>
                        <Item name="saveButton" />
                        <Item name="revertButton" />
                        <Item>
                            <Tooltip
                                content={
                                    <div>
                                        Selecciona para visualizar las columnas.
                                    </div>
                                }
                            >
                                <Button
                                    icon="columnproperties"
                                    onClick={ShowColumnChosser}
                                />
                            </Tooltip>
                        </Item>
                        <Item name="searchPanel"/>
                        <Item location="after">
                            <Button
                                icon="message"
                                text="Enviar cotización actualizada"
                                type="success"
                                disabled={!editable}
                                onClick={markAsSent}
                            />
                        </Item>
                    </Toolbar>
                </DataGrid>
            </>
        );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ColumnCatRec, RequestDeliveryDate, deliveryDate, deliveryDateAction, descDefault, modalCatRec]);

    React.useEffect(() => {
        RefreshDataGrid();
    }, [RefreshDataGrid]);

    return (
        <>
            <div className="info-impuestos">
            <span>*Todos los precios son sin impuestos</span>
            </div>
            {dataGridCoti}
            <CatProductsModal isModalOpen={modalCatRec} handleModalToggle={setModalCatRec} selectedItem={selectedRow}/>
        </>
    )
}
