import React from 'react';
import {useLocation, useParams} from "react-router-dom";
import {LoginLayout} from "../../Layouts/LoginLayout";
import LoginServices from "../../Services/LoginServices";
import notify from "devextreme/ui/notify";
import {useNavigate} from "react-router-dom";

export const ValidateEmail = () => {
    const query = new URLSearchParams(useLocation().search);
    const paramEMail = query.get('email');
    const {TOKEN} = useParams();
    const navigate = useNavigate();

    const resendEmail = async () => {
        await LoginServices.resendEmail(paramEMail+'')
            .then((response) => {
                if (response.status === "SUCCESS") {
                    notify({
                            message: 'Se ha enviado un mensaje a tu correo electrónico con las instrucciones para validarlo.',
                            height: 50,
                            width: 150,
                            type: "success",
                            displayTime: 3500,
                            animation: {
                                show: {
                                    type: 'fade', duration: 400, from: 0, to: 1,
                                },
                                hide: { type: 'fade', duration: 40, to: 0 },
                            },
                        } ,
                        {
                            position : "top right",
                            direction: "down-push"
                        })
                    navigate('/');
                } else {
                    notify({
                            message: response.error.error,
                            height: 50,
                            width: 150,
                            type: "error",
                            displayTime: 3500,
                            animation: {
                                show: {
                                    type: 'fade', duration: 400, from: 0, to: 1,
                                },
                                hide: { type: 'fade', duration: 40, to: 0 },
                            },
                        } ,
                        {
                            position : "top right",
                            direction: "down-push"
                        });
                    navigate('/');
                }
            })
    }

    const validateToken = async () => {
        const response = await LoginServices.validateEmail(TOKEN)

        if (response.status === "SUCCESS") {
            notify({
                    message: '¡Perfecto!, ahora puedes acceder.',
                    height: 50,
                    width: 150,
                    type: "success",
                    displayTime: 10000,
                    animation: {
                        show: {
                            type: 'fade', duration: 400, from: 0, to: 1,
                        },
                        hide: { type: 'fade', duration: 40, to: 0 },
                    },
                } ,
                {
                    position : "top right",
                    direction: "down-push"
                })
            navigate('/')
        } else {
            notify({
                    message: response.error.error,
                    height: 50,
                    width: 150,
                    type: "error",
                    displayTime: 3500,
                    animation: {
                        show: {
                            type: 'fade', duration: 400, from: 0, to: 1,
                        },
                        hide: { type: 'fade', duration: 40, to: 0 },
                    },
                } ,
                {
                    position : "top right",
                    direction: "down-push"
                })
            navigate('/')
        }
    }

    React.useEffect(() => {
        if (TOKEN) {
            validateToken();
        } else if (paramEMail) {
            resendEmail();
        } else {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [TOKEN])

    return (
        <LoginLayout loginTitle=''
                     loginSubtitle=''
                     textContent="textContent"
        >
            <div className="auth-wrapper">
                <div className="auth-inner">
                    { !paramEMail ?
                        <p>Validando Token...</p>
                        :
                        <p>Reenviando mensaje...</p>
                    }
                </div>
            </div>
        </LoginLayout>
    );
}